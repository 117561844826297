<template>
    <svg version="1.1" class="oplus_chat" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px" y="0px" viewBox="0 0 19 20" width="19px" height="20px" style="enable-background:new 0 0 19 20;"
        xml:space="preserve">
        <path fill="currentColor" d="M14.24,4.71c-0.46,0-0.91,0.08-1.31,0.25c-0.07,0.02-0.13,0.05-0.2,0.08l-0.02,0.01
                    c-0.02,0.01-0.04,0.02-0.06,0.03c-0.07,0.03-0.1,0.05-0.13,0.06c-0.09,0.05-0.18,0.1-0.26,0.16c-0.3,0.21-0.56,0.43-0.78,0.68
                    c-0.03,0.04-0.06,0.07-0.08,0.11c-0.04,0.05-0.08,0.09-0.1,0.13l-0.27,0.46l-0.02,0.03c-0.08,0.17-0.16,0.33-0.21,0.51
                    c-0.04,0.17-0.07,0.28-0.11,0.39c-0.2,0.58-0.57,1.21-1.11,1.91C9.47,9.67,9.36,9.79,9.26,9.9c0,0,0,0,0.01,0
                    c0.18-0.07,0.31-0.13,0.55-0.19c0.24-0.05,0.9-0.18,1.31-0.18c0,0,0,0,0.01-0.01c0.08-0.12,0.17-0.26,0.24-0.39
                    c0.02-0.02,0.03-0.04,0.04-0.06c0.14-0.24,0.26-0.52,0.35-0.77c0.05-0.14,0.1-0.29,0.14-0.43c0-0.01,0-0.02,0.01-0.04
                    c0-0.04,0.02-0.1,0.04-0.18C11.98,7.63,11.99,7.6,12,7.57c0.03-0.08,0.06-0.17,0.1-0.25c0.02-0.05,0.04-0.09,0.07-0.13
                    c0.02-0.06,0.06-0.11,0.1-0.17c0.04-0.06,0.08-0.11,0.11-0.16c0.04-0.04,0.08-0.09,0.11-0.12c0.03-0.04,0.06-0.07,0.09-0.1
                    c0.03-0.04,0.06-0.07,0.09-0.09c0.41-0.36,0.96-0.58,1.55-0.58c1.3,0,2.37,1.07,2.37,2.38c0,1.03-0.66,1.92-1.6,2.24
                    c0.28,0.28,0.53,0.6,0.74,0.96l0.05,0.05c1.25-0.59,2.06-1.85,2.06-3.26C17.86,6.34,16.24,4.71,14.24,4.71z M15.43,12.09
                    l-0.06-0.11c-0.01-0.02-0.03-0.05-0.04-0.07l-0.05-0.09c-0.22-0.34-0.47-0.66-0.76-0.94c-0.05-0.05-0.07-0.07-0.09-0.08
                    c-0.06-0.06-0.08-0.08-0.11-0.1c-0.09-0.08-0.19-0.16-0.29-0.23l-0.29-0.2c-0.01-0.01-0.03-0.02-0.04-0.03c0,0-0.2-0.12-0.24-0.14
                    c-0.52-0.26-0.97-0.79-1.15-1.31c-0.02-0.08-0.04-0.17-0.06-0.25c-0.05,0.16-0.11,0.31-0.2,0.47c-0.06,0.12-0.12,0.24-0.19,0.36
                    c-0.04,0.08-0.08,0.16-0.13,0.23c-0.04,0.08-0.1,0.17-0.16,0.24c0.02,0.04,0.05,0.08,0.07,0.12c0.03,0.05,0.33,0.48,0.42,0.58
                    c0.2,0.23,0.43,0.43,0.69,0.59c0.1,0.07,0.31,0.21,0.33,0.22c0.09,0.05,0.18,0.11,0.26,0.19c0.11,0.08,0.21,0.18,0.3,0.26
                    c0.03,0.02,0.05,0.05,0.08,0.08c0.04,0.04,0.08,0.08,0.11,0.12c0.01,0.02,0.03,0.04,0.04,0.06c0.54,0.63,0.83,1.43,0.82,2.27
                    c-0.01,0.94-0.38,1.82-1.04,2.47c-0.67,0.66-1.57,1-2.49,1.01c-0.94,0-1.81-0.37-2.47-1.04c-0.66-0.67-1.02-1.56-1.01-2.49
                    c0.01-0.5,0.11-0.98,0.32-1.43c0-0.01,0.01-0.02,0.01-0.03c-0.38,0.08-0.79,0.12-1.19,0.13h-0.2c0,0.01-0.01,0.02-0.01,0.03
                    c-0.11,0.41-0.18,0.84-0.19,1.28c-0.02,2.63,2.1,4.78,4.73,4.8h0.04c2.61,0,4.74-2.11,4.76-4.73v-0.06
                    C15.96,13.49,15.77,12.75,15.43,12.09z M12.31,4.67c-0.01,0.01-0.03,0.01-0.04,0.02c-0.1,0.06-0.2,0.12-0.3,0.19
                    c-0.27,0.19-0.52,0.39-0.73,0.61c-0.48-2.09-2.33-3.6-4.53-3.6c-2.57,0-4.66,2.09-4.66,4.66c0,2.39,1.81,4.37,4.13,4.62
                    c0.18,0.02,0.35,0.03,0.53,0.03c0.19,0,0.38-0.01,0.57-0.04c0.12-0.01,0.25-0.03,0.36-0.06c0.03,0,0.06-0.01,0.08-0.02
                    c0.07-0.02,0.19-0.04,0.31-0.08c0.07-0.01,0.14-0.04,0.22-0.07c0.06-0.02,0.14-0.04,0.22-0.07c0.01,0,0.02-0.01,0.02-0.01
                    c0.17-0.06,0.34-0.14,0.53-0.24c0.04-0.02,0.28-0.13,0.35-0.17c0.07-0.03,0.14-0.06,0.21-0.08c0.07-0.03,0.13-0.05,0.21-0.07
                    c0.23-0.07,0.56-0.13,0.78-0.16c0.29-0.04,0.57-0.06,0.57-0.06c0.13,0.37,0.64,0.93,0.85,1.09c-0.29-0.04-0.52-0.02-0.8,0
                    c-0.65,0.06-1.18,0.32-1.67,0.58c-0.16,0.08-0.32,0.17-0.49,0.25H9.01l-0.55,0.18c-0.05,0.01-0.1,0.03-0.16,0.04l-0.19,0.04
                    c-0.41,0.11-0.8,0.16-1.17,0.18c-0.37,0.02-0.69,0.01-0.69,0.01C3.17,12.2,0.8,9.66,0.8,6.54c0-3.26,2.65-5.91,5.91-5.91
                    C9.24,0.64,11.5,2.29,12.31,4.67z" />
    </svg>
</template>
