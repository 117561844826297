// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.dashboard-tab {
    overflow-y: hidden;
    overflow-x: auto;
    flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    padding-left: 5px;
    box-shadow: inset -4px -5px 4px -5px #8a8a8a94;
}
.tab .tab-item a {
    border-bottom: 0px;
}
.tab .tab-item a,
  .tab .tab-item a.active {
    padding-bottom: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/vue/components/DashboardContentTab.vue"],"names":[],"mappings":";AAgCE;IACE,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,qBAAqB;IACrB,iBAAiB;IACjB,8CAA8C;AAChD;AACA;IACE,kBAAkB;AACpB;AAEA;;IAEE,mBAAmB;AACrB","sourcesContent":["<script setup lang=\"ts\">\nimport { PropType } from 'vue'\n\n  const props = defineProps({\n    tabItemTexts: {\n      type: Array as PropType<string[]>,\n      required: true,\n    },\n    activeTabIndex: {\n      type: Number,\n      default: 0,\n      required: true,\n    }\n  })\n  const emit = defineEmits([\"emit-tab-index\"]);\n  const activeClass = (index: number) => {\n    return index === props.activeTabIndex ? \"active\" : \"\";\n  }\n\n  function handleTabClick(index: number) {\n    emit(\"emit-tab-index\", index);\n  }\n</script>\n\n<template>\n  <ul class=\"tab dashboard-tab\">\n    <li v-for=\"text, index in tabItemTexts\" :key=\"`${index}-${text}`\" class=\"tab-item\" @click=\"handleTabClick(index)\">\n      <a :class=\"activeClass(index)\">{{ text }}</a>\n    </li>\n  </ul>\n</template>\n<style>\n  .dashboard-tab {\n    overflow-y: hidden;\n    overflow-x: auto;\n    flex-wrap: nowrap;\n    -ms-flex-wrap: nowrap;\n    padding-left: 5px;\n    box-shadow: inset -4px -5px 4px -5px #8a8a8a94;\n  }\n  .tab .tab-item a {\n    border-bottom: 0px;\n  }\n\n  .tab .tab-item a,\n  .tab .tab-item a.active {\n    padding-bottom: 4px;\n  }\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
