"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PtoConfig = void 0;
const ptoConfigNum_1 = require("../enums/ptoConfigNum");
const PtoConfigDays_1 = require("./PtoConfigDays");
class PtoConfig {
    groupId;
    // Number of work days per week
    oneDay = new PtoConfigDays_1.PtoConfigDays({ numIndicator: ptoConfigNum_1.ptoConfigNum.onePerWeek });
    twoDays = new PtoConfigDays_1.PtoConfigDays({ numIndicator: ptoConfigNum_1.ptoConfigNum.twoPerWeek });
    threeDays = new PtoConfigDays_1.PtoConfigDays({ numIndicator: ptoConfigNum_1.ptoConfigNum.threePerWeek });
    fourDays = new PtoConfigDays_1.PtoConfigDays({ numIndicator: ptoConfigNum_1.ptoConfigNum.fourPerWeek });
    fiveDays = new PtoConfigDays_1.PtoConfigDays({ numIndicator: ptoConfigNum_1.ptoConfigNum.fivePerWeek }); // Five or more
    grantDay;
    constructor(required, options) {
        this.groupId = required.groupId;
        if (options?.oneDay) {
            this.oneDay = options.oneDay;
        }
        if (options?.twoDays) {
            this.twoDays = options.twoDays;
        }
        if (options?.threeDays) {
            this.threeDays = options.threeDays;
        }
        if (options?.fourDays) {
            this.fourDays = options.fourDays;
        }
        if (options?.fiveDays) {
            this.fiveDays = options.fiveDays;
        }
        if (options?.grantDay) {
            this.grantDay = options.grantDay;
        }
    }
    getGroupId() {
        return this.groupId.toString();
    }
    getOriginalGroupId() {
        return this.groupId;
    }
    getConfigDays() {
        return [this.fiveDays, this.fourDays, this.threeDays, this.twoDays, this.oneDay];
    }
    getConfigDaysByInt(int) {
        if (this.fiveDays.getNumIndicator() === int) {
            return this.fiveDays;
        }
        else if (this.fourDays.getNumIndicator() === int) {
            return this.fourDays;
        }
        else if (this.threeDays.getNumIndicator() === int) {
            return this.threeDays;
        }
        else if (this.twoDays.getNumIndicator() === int) {
            return this.twoDays;
        }
        else if (this.oneDay.getNumIndicator() === int) {
            return this.oneDay;
        }
        else {
            throw new Error("Num indicator doesn't exist");
        }
    }
    getOneDayConfig() {
        return this.oneDay;
    }
    getTwoDaysConfig() {
        return this.twoDays;
    }
    getThreeDaysConfig() {
        return this.threeDays;
    }
    getFourDaysConfig() {
        return this.fourDays;
    }
    getFiveDaysConfig() {
        return this.fiveDays;
    }
    getGrantDay() {
        return this.grantDay;
    }
    setGrantDay(date) {
        this.grantDay = date;
    }
    setDefault() {
        this.oneDay.setConfigDays({
            halfYear: 1,
            oneAndHalfYear: 2,
            twoAndHalfYears: 2,
            threeAndHalfYears: 2,
            fourAndHalfYears: 3,
            fiveAndHalfYears: 3,
            sixAndHalfYears: 3,
        });
        this.twoDays.setConfigDays({
            halfYear: 3,
            oneAndHalfYear: 4,
            twoAndHalfYears: 4,
            threeAndHalfYears: 5,
            fourAndHalfYears: 6,
            fiveAndHalfYears: 6,
            sixAndHalfYears: 7,
        });
        this.threeDays.setConfigDays({
            halfYear: 5,
            oneAndHalfYear: 6,
            twoAndHalfYears: 6,
            threeAndHalfYears: 8,
            fourAndHalfYears: 9,
            fiveAndHalfYears: 10,
            sixAndHalfYears: 11,
        });
        this.fourDays.setConfigDays({
            halfYear: 7,
            oneAndHalfYear: 8,
            twoAndHalfYears: 9,
            threeAndHalfYears: 10,
            fourAndHalfYears: 12,
            fiveAndHalfYears: 13,
            sixAndHalfYears: 15,
        });
        this.fiveDays.setConfigDays({
            halfYear: 10,
            oneAndHalfYear: 11,
            twoAndHalfYears: 12,
            threeAndHalfYears: 14,
            fourAndHalfYears: 16,
            fiveAndHalfYears: 18,
            sixAndHalfYears: 20,
        });
    }
    reset() {
        this.oneDay.resetConfigDays();
        this.twoDays.resetConfigDays();
        this.threeDays.resetConfigDays();
        this.fourDays.resetConfigDays();
        this.fiveDays.resetConfigDays();
    }
}
exports.PtoConfig = PtoConfig;
