<template>
  <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="30px" viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;"
    xml:space="preserve">
    <path fill="currentColor" d="M0.933,5.668c-0.252,0-0.458-0.205-0.458-0.46V3.186c0-1.265,1.026-2.291,2.29-2.291h2.071
    c0.253,0,0.458,0.205,0.458,0.458c0,0.254-0.205,0.458-0.458,0.458H2.765c-0.757,0-1.374,0.617-1.374,1.375v2.022
    C1.392,5.463,1.187,5.668,0.933,5.668z M5.295,18.081c0-0.254-0.205-0.458-0.458-0.458H2.765c-0.757,0-1.374-0.618-1.374-1.375
    v-2.033c0-0.254-0.205-0.458-0.458-0.458c-0.252,0-0.458,0.204-0.458,0.458v2.033c0,1.264,1.026,2.291,2.29,2.291h2.071
    C5.09,18.539,5.295,18.334,5.295,18.081z M18.203,5.208V3.186c0-1.265-1.027-2.291-2.291-2.291h-2.07
    c-0.252,0-0.459,0.205-0.459,0.458c0,0.254,0.207,0.458,0.459,0.458h2.07c0.758,0,1.374,0.617,1.374,1.375v2.022
    c0,0.255,0.205,0.46,0.458,0.46S18.203,5.463,18.203,5.208z M18.203,16.248v-2.033c0-0.254-0.205-0.458-0.458-0.458
    s-0.458,0.204-0.458,0.458v2.033c0,0.757-0.615,1.375-1.374,1.375h-2.07c-0.252,0-0.459,0.204-0.459,0.458
    c0,0.253,0.207,0.458,0.459,0.458h2.07C17.176,18.539,18.203,17.512,18.203,16.248z M6.705,6.688V3.98
    c0-0.216-0.174-0.391-0.39-0.391H3.607c-0.216,0-0.39,0.175-0.39,0.391v2.708c0,0.215,0.173,0.389,0.39,0.389h2.708
    C6.53,7.077,6.705,6.903,6.705,6.688z M3.996,4.369h1.928v1.928H3.996V4.369z M6.705,15.479v-2.708c0-0.217-0.174-0.39-0.39-0.39
    H3.607c-0.216,0-0.39,0.173-0.39,0.39v2.708c0,0.215,0.173,0.389,0.39,0.389h2.708C6.53,15.868,6.705,15.695,6.705,15.479z
    M3.996,13.163h1.928v1.928H3.996V13.163z M15.497,6.688V3.98c0-0.216-0.175-0.391-0.389-0.391h-2.709
    c-0.215,0-0.389,0.175-0.389,0.391v2.708c0,0.215,0.173,0.389,0.389,0.389h2.709C15.322,7.077,15.497,6.903,15.497,6.688z
    M12.789,4.369h1.929v1.928h-1.929V4.369z M10.659,6.688 M11.049,5.763V3.98c0-0.216-0.175-0.391-0.39-0.391H7.951
    c-0.214,0-0.39,0.175-0.39,0.391c0,0.214,0.176,0.389,0.39,0.389h2.318v1.394c0,0.215,0.176,0.39,0.39,0.39
    C10.874,6.152,11.049,5.978,11.049,5.763z M9.319,5.838c0-0.215-0.175-0.39-0.389-0.39H8.034c-0.214,0-0.39,0.175-0.39,0.39
    c0,0.214,0.176,0.389,0.39,0.389H8.93C9.145,6.227,9.319,6.052,9.319,5.838z M8.451,11.105c0-0.217-0.174-0.391-0.39-0.391H7.166
    c-0.215,0-0.389,0.174-0.389,0.391c0,0.214,0.173,0.389,0.389,0.389h0.896C8.277,11.494,8.451,11.319,8.451,11.105z M10.796,8.979
    H10.19V7.944h0.55c0.22,0,0.394-0.174,0.394-0.395c0-0.211-0.174-0.385-0.394-0.385H7.938c-0.22,0-0.395,0.174-0.395,0.385
    c0,0.221,0.175,0.395,0.395,0.395h1.474v1.034H8.854c-0.22,0-0.394,0.175-0.394,0.386c0,0.22,0.174,0.393,0.394,0.393h1.943
    c0.22,0,0.393-0.173,0.393-0.393C11.189,9.153,11.016,8.979,10.796,8.979z M4.004,9.314V8.47c0-0.215-0.177-0.39-0.393-0.39
    c-0.213,0-0.388,0.175-0.388,0.39v0.844c0,0.215,0.175,0.391,0.388,0.391C3.827,9.706,4.004,9.53,4.004,9.314z M5.843,11.105
    c0-0.217-0.175-0.391-0.388-0.391H3.588c-0.216,0-0.39,0.174-0.39,0.391c0,0.214,0.174,0.389,0.39,0.389h1.867
    C5.668,11.494,5.843,11.319,5.843,11.105z M7.606,9.34c0-0.216-0.174-0.39-0.389-0.39H6.635V8.42c0-0.215-0.173-0.389-0.389-0.389
    H5.352c-0.214,0-0.39,0.174-0.39,0.389c0,0.215,0.176,0.39,0.39,0.39h0.503V9.34c0,0.215,0.175,0.389,0.391,0.389h0.971
    C7.433,9.729,7.606,9.556,7.606,9.34z M11.058,12.841v-1.736c0-0.217-0.174-0.391-0.39-0.391H9.721
    c-0.214,0-0.389,0.174-0.389,0.391c0,0.214,0.175,0.389,0.389,0.389h0.557v0.958H8.034c-0.214,0-0.39,0.174-0.39,0.39
    c0,0.215,0.176,0.39,0.39,0.39h2.634C10.883,13.231,11.058,13.057,11.058,12.841z M8.424,15.577v-0.582h1.375
    c0.215,0,0.389-0.175,0.389-0.39c0-0.214-0.174-0.389-0.389-0.389H8.034c-0.214,0-0.39,0.175-0.39,0.389v0.972
    c0,0.216,0.176,0.39,0.39,0.39C8.25,15.966,8.424,15.793,8.424,15.577z M15.137,14.091c-0.209,0-0.384,0.173-0.384,0.383v0.66
    h-1.118v-0.631c0-0.211-0.173-0.386-0.384-0.386c-0.22,0-0.394,0.175-0.394,0.386v0.631h-0.953v-0.604
    c0-0.22-0.174-0.394-0.395-0.394c-0.209,0-0.385,0.174-0.385,0.394v0.997c0,0.212,0.176,0.387,0.385,0.387h3.627
    c0.221,0,0.394-0.175,0.394-0.387v-1.053C15.531,14.265,15.358,14.091,15.137,14.091z M14.586,12.868v-0.557h0.607
    c0.215,0,0.389-0.174,0.389-0.389c0-0.215-0.173-0.39-0.389-0.39h-0.997c-0.215,0-0.39,0.175-0.39,0.39v0.556h-0.985v-0.531
    c0-0.214-0.175-0.39-0.39-0.39c-0.215,0-0.39,0.176-0.39,0.39v0.921c0,0.214,0.175,0.39,0.39,0.39h1.765
    C14.411,13.257,14.586,13.082,14.586,12.868z M12.797,10.209v-1.84c0-0.215-0.176-0.39-0.391-0.39c-0.214,0-0.389,0.175-0.389,0.39
    v1.84c0,0.215,0.175,0.389,0.389,0.389C12.621,10.598,12.797,10.424,12.797,10.209z M15.037,8.007c-0.21,0-0.384,0.173-0.384,0.387
    v0.53h-0.503c-0.221,0-0.395,0.174-0.395,0.393c0,0.21,0.173,0.386,0.395,0.386h0.503v0.532c0,0.22,0.174,0.393,0.384,0.393
    c0.221,0,0.395-0.172,0.395-0.393V8.394C15.431,8.181,15.258,8.007,15.037,8.007z"/>
  </svg>

</template>
