"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingObserverImpl = void 0;
class ChatSettingObserverImpl {
    subject;
    data = [];
    constructor(subject) {
        this.subject = subject;
    }
    getState() {
        return this.data;
    }
    getEventListener() {
        return this.subject.getEventListener();
    }
    update() {
        this.data = this.subject.getState();
    }
}
exports.ChatSettingObserverImpl = ChatSettingObserverImpl;
