<template>
<svg version="1.1" class="collab-kot" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 182 34" style="enable-background:new 0 0 182 34;" xml:space="preserve">
  <path fill="black" d="M56.187,8.13h-1.985L50.636,19.24L47.194,8.13h-1.999l-2.298,15.588h2.179l1.505-9.088l3.48,9.169h1.097l3.58-9.154
		l1.348,9.074h2.165L56.187,8.13z M68.684,15.423c-0.983-0.969-2.184-1.453-3.599-1.453c-1.41,0-2.607,0.48-3.585,1.443
		c-0.978,0.959-1.472,2.122-1.472,3.495c0,1.453,0.48,2.659,1.443,3.623c0.959,0.973,2.151,1.458,3.571,1.458
		c1.439,0,2.649-0.475,3.632-1.429c0.983-0.964,1.472-2.151,1.472-3.575C70.146,17.579,69.662,16.387,68.684,15.423z M67.136,21.206
		c-0.513,0.551-1.197,0.826-2.051,0.826c-0.85,0-1.538-0.28-2.056-0.836c-0.508-0.556-0.76-1.301-0.76-2.251
		c0-0.883,0.261-1.605,0.779-2.16c0.518-0.56,1.197-0.84,2.037-0.84c0.85,0,1.534,0.28,2.051,0.84
		c0.513,0.551,0.769,1.287,0.769,2.208C67.905,19.915,67.649,20.655,67.136,21.206z M79.296,15.01
		c-0.617-0.693-1.477-1.04-2.578-1.04c-0.964,0-1.823,0.38-2.592,1.14v-0.878h-2.198v9.487h2.198v-4.577
		c0-1.182,0.152-2.008,0.451-2.483c0.304-0.48,0.821-0.722,1.548-0.722c0.665,0,1.13,0.185,1.382,0.546
		c0.261,0.356,0.389,1.002,0.389,1.937v5.299h2.198v-5.812C80.094,16.567,79.828,15.603,79.296,15.01z M89.196,20.204
		c-0.446,0.698-0.845,1.178-1.197,1.439c-0.356,0.261-0.807,0.389-1.353,0.389c-0.703,0-1.277-0.228-1.724-0.679
		c-0.446-0.465-0.703-1.083-0.76-1.861h6.795l0.01-0.518c0-1.562-0.399-2.787-1.206-3.67c-0.812-0.888-1.914-1.334-3.309-1.334
		c-1.382,0-2.493,0.456-3.333,1.372c-0.831,0.931-1.244,2.141-1.244,3.642c0,1.515,0.427,2.725,1.287,3.642
		c0.855,0.912,1.989,1.363,3.404,1.363c0.494,0,0.954-0.052,1.377-0.157c0.418-0.1,0.812-0.266,1.178-0.489
		c0.361-0.223,0.703-0.508,1.021-0.859c0.318-0.347,0.622-0.764,0.907-1.249L89.196,20.204z M84.619,16.971
		c0.138-0.214,0.299-0.399,0.494-0.551c0.19-0.152,0.404-0.271,0.636-0.351c0.233-0.081,0.484-0.123,0.75-0.123
		c1.168,0,1.904,0.584,2.213,1.752H84.31C84.381,17.427,84.486,17.184,84.619,16.971z M99.352,14.231l-2.711,5.456l-2.901-5.456
		H91.2l4.264,7.725l-3.699,6.923h2.502l7.554-14.648H99.352z"/>
	<path fill="#E27726" d="M32.688,17.389c0,8.656-7.013,15.669-15.669,15.669S1.35,26.045,1.35,17.389
		C1.35,8.733,8.363,1.72,17.019,1.72c4.777,0,9.05,2.137,11.927,5.503l-4.492,7.155l-3.053-4.159
		c-0.551-0.755-1.477-1.135-2.393-0.992c-0.921,0.147-1.686,0.793-1.98,1.676l-1.98,5.93l-1.885-2.07
		c-0.594-0.655-1.491-0.945-2.355-0.764c-0.869,0.185-1.572,0.812-1.852,1.652l-2.611,7.834c-0.437,1.306,0.271,2.716,1.576,3.153
		c0.261,0.085,0.527,0.128,0.788,0.128c1.045,0,2.018-0.66,2.365-1.705l1.268-3.794l1.885,2.07c0.594,0.655,1.491,0.945,2.355,0.764
		c0.869-0.185,1.572-0.812,1.852-1.652l1.8-5.399l2.374,3.233c0.484,0.67,1.258,1.049,2.099,1.021
		c0.826-0.033,1.581-0.47,2.023-1.168l5.047-8.034C32.365,13.756,32.688,15.532,32.688,17.389z M33.4,1.482
		c-1.836,0-3.324,1.488-3.324,3.324S31.565,8.13,33.4,8.13c1.836,0,3.324-1.488,3.324-3.324S35.236,1.482,33.4,1.482z
		 M115.769,8.683h-7.508v15.035h2.269V16.56h5.054v-2.133h-5.054v-3.613h5.239V8.683z M117.309,22.529
		c0.961,0.974,2.152,1.461,3.574,1.461c1.435,0,2.645-0.477,3.632-1.431c0.98-0.961,1.47-2.152,1.47-3.574
		c0-1.409-0.487-2.597-1.461-3.564c-0.987-0.967-2.188-1.451-3.603-1.451c-1.409,0-2.603,0.48-3.583,1.441
		c-0.98,0.961-1.47,2.126-1.47,3.496C115.868,20.361,116.348,21.569,117.309,22.529z M118.886,16.784
		c0.519-0.558,1.198-0.837,2.035-0.837c0.85,0,1.535,0.279,2.055,0.837c0.513,0.552,0.769,1.289,0.769,2.21s-0.256,1.659-0.769,2.21
		s-1.198,0.828-2.055,0.828c-0.85,0-1.535-0.279-2.055-0.837c-0.506-0.552-0.76-1.302-0.76-2.249
		C118.107,18.063,118.367,17.343,118.886,16.784z M127.764,23.717h2.191V18.8c0-1.805,0.597-2.707,1.792-2.707
		c0.396,0,0.802,0.149,1.217,0.448l1.003-2.006c-0.63-0.377-1.233-0.565-1.811-0.565c-0.435,0-0.811,0.081-1.13,0.243
		c-0.312,0.156-0.669,0.445-1.071,0.867v-0.847h-2.191V23.717z M135.274,14.233l4.956,10.195l2.668-6.105l2.639,6.105
		c0,0,4.414-9.397,6.093-11.075c2.374-2.374,4.273-2.374,4.273-2.374V8.605c0,0-2.849,0-6.173,3.799
		c-1.327,1.516-4.135,7.244-4.135,7.244l-2.697-6.281l-2.678,6.281l-2.483-5.414H135.274z M158.59,15.333
		c-0.876-0.909-1.866-1.363-2.97-1.363c-1.279,0-2.343,0.474-3.194,1.422s-1.276,2.133-1.276,3.554c0,1.448,0.425,2.655,1.276,3.622
		c0.857,0.948,1.941,1.422,3.252,1.422c1.039,0,2.009-0.422,2.911-1.266v0.993h2.201v-9.484h-2.201V15.333z M154.14,16.804
		c0.506-0.571,1.136-0.857,1.889-0.857c0.798,0,1.448,0.282,1.947,0.847c0.5,0.578,0.75,1.311,0.75,2.201
		c0,0.889-0.25,1.623-0.75,2.201c-0.5,0.558-1.156,0.837-1.967,0.837c-0.76,0-1.389-0.289-1.889-0.867
		c-0.487-0.571-0.73-1.311-0.73-2.22C153.39,18.096,153.64,17.381,154.14,16.804z M163.492,23.717h2.191V18.8
		c0-1.805,0.597-2.707,1.792-2.707c0.396,0,0.802,0.149,1.217,0.448l1.003-2.006c-0.63-0.377-1.233-0.565-1.811-0.565
		c-0.435,0-0.811,0.081-1.13,0.243c-0.312,0.156-0.669,0.445-1.071,0.867v-0.847h-2.191V23.717z M177.743,15.333
		c-0.876-0.909-1.87-1.363-2.98-1.363c-1.279,0-2.34,0.477-3.184,1.431c-0.85,0.948-1.276,2.129-1.276,3.544
		c0,1.448,0.425,2.655,1.276,3.622c0.857,0.948,1.935,1.422,3.233,1.422c1.091,0,2.068-0.422,2.931-1.266v0.993h2.201V7.271h-2.201
		V15.333z M173.293,16.804c0.506-0.571,1.136-0.857,1.889-0.857c0.798,0,1.448,0.282,1.947,0.847c0.5,0.578,0.75,1.311,0.75,2.201
		c0,0.889-0.25,1.623-0.75,2.201c-0.5,0.558-1.156,0.837-1.967,0.837c-0.76,0-1.389-0.289-1.889-0.867
		c-0.487-0.571-0.73-1.311-0.73-2.22C172.543,18.096,172.793,17.381,173.293,16.804z"/>
  </svg>
</template>
