"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingsSubjectImpl = void 0;
const chatSettings_1 = require("./chatSettings");
const emitter_1 = require("./emitter");
class ChatSettingsSubjectImpl {
    data = {};
    emitter;
    observers = [];
    constructor() {
        this.emitter = new emitter_1.Emitter();
    }
    attach(observer) {
        this.observers.push(observer);
    }
    notify() {
        this.observers.forEach(o => o.update());
    }
    set(setting) {
        let prev;
        if (this.data[setting.id]) {
            prev = { ...this.data[setting.id] };
        }
        this.data[setting.id] = setting;
        this.notify();
        if (prev) {
            const e = {
                type: chatSettings_1.ChatSettingsEventTypes.UPDATE,
                value: setting,
                oldValue: prev,
            };
            const event = new CustomEvent(e.type, { detail: e });
            this.emitter.dispatchEvent(event);
        }
        else {
            const e = {
                type: chatSettings_1.ChatSettingsEventTypes.ADD,
                value: setting,
            };
            const event = new CustomEvent(e.type, { detail: e });
            this.emitter.dispatchEvent(event);
        }
    }
    getEventListener() {
        return this.emitter;
    }
    getState() {
        return Object.values(this.data);
    }
}
exports.ChatSettingsSubjectImpl = ChatSettingsSubjectImpl;
