import { createApp, reactive, computed } from "vue";
import Cell from "../vue/Cell.vue";
import AutoModal from "../vue/AutoModal.vue";
import { MODAL_ID } from "./enums/modal_types";
import { addDaysToDate, followingMidnightUTC, goDateString, dateFromSecondsTimestamp, createStartAndEndDatesFromArray, roundTwoDecimals, roundNum, timestampSecsFromDate, timestampFromDate } from './common';
import { dateFromString, fromOplusTime, yearMonthDateString } from "../common/common";
import { mountTagRoleFilterModal } from "./modal";
import { transformCompany, transformUser } from "./transformers";
import { slotPostRequest, slotPutRequest, slotDeleteRequest, getPageSlotURL } from "./slot_crud_requests"
import { DELETE_SLOT_PARAM_STR, SLOT_SUBMIT_BUTTON_ID, handleCreateSuccess, handleUpdateSuccess, handleDeleteSuccess, handleTemplateCreateSuccess, DELETE_REPEAT_INPUT_VALUE } from "./slot_crud_common"
import { ACTIONS_TYPE, undoActionRequest, addToUndoStack, getLastUndoStack, removeDeletedSlotInStack, disableUndoLoading, removeLastActionFromUndoStack, enableUndoLoading, disableSaveUndo } from "./slot_undo";
import { getUrlParam } from "../common/common";
import { RoleStore } from "../store/v1/roleStore";
import { ShiftStore } from "../store/v1/shiftStore";
import periodTypes from "../table/v1/periodTypes";
import * as shiftParser from '../legacy/parser/shiftParser';
import { EmploymentShiftTableStats } from "../employmentShiftTable/employmentShiftTableStats";
import { MeStore } from "../store/v1/meStore";
import { repeatShiftsFromTemplate, mergeLinkedShifts, initPlanFunctions, makeComputedValuesMap } from "../employmentShiftTable/employmentShiftTable";

import { toOplusTime } from "../common/common";
import { BranchStore } from "../store/v1/branchStore";
import * as branchParser from '../legacy/parser/branchParser';
import * as branchSettingsParser from '../legacy/parser/branchSettingsParser';
import * as roleParser from '../legacy/parser/roleParser';
import * as employmentParser from '../legacy/parser/employmentParser';
import { store as roleStoreOld } from "./stores/role_store"
import * as ccbiz from "./ccbiz";
import { EmploymentStore } from "../store/v1/employmentStore";
import { calcEndDate, calcStartDate, endDateForPeriod, makeTableDates, truncate } from "../table/v1/table";
import viewTypes from "../table/v1/viewTypes";
import { DateTime } from "luxon";

import { User } from "../model/v1/user";
import { PlanFunctionStore } from "../store/v1/planFunction";
import { PlanValueStore } from "../store/v2/planValue";
import { evaluatePlanFunction } from "../plan/v1/plan";
import { PlanVariableStore } from "../store/v1/planVariable";
import { initLegacyFunctions } from "../legacy/plan/legacyPlan";

import * as kot from "./kot";
import { getAndUpdateRolesList, initSlotModalRoleListComponent } from "../slotModal/roleList";
import { cellEditMode, initCellEdit, openCellEditMenuOnBtn, resetSelectedCell } from "../summaryTable/tableEditMode";
import { reactiveSlotLogMap, initSlotLogMap } from "./slotLog";
import CellEditMenuWrapper from "../summaryTable/components/CellEditMenuWrapper.vue";
import { getShiftBordersSetting } from "./settings";

let activeCom = null;
let activeUser;
let isUsingRequestSlots = false;

// Global stores and refs to support render / accept shift data from template
// Should be in a table component in the future
let tableDates;
let roleStore;
let meStore;
let shiftStore;
let employmentStore;
let branchStore;
let summaryStats;

export function getMeStore() {
  return meStore;
}

export function getEmploymentStore() {
  return employmentStore
}

export function buildCellsMap(slotMap, viewTo, lockedOnly, splitNightShift, tz) {
  Object.keys(slotMap).forEach(id => {
    const slot = slotMap[id];
    if (slot.SectionParent || slot.IsTemplate) {
      return;
    } else if (slot.Repeat) {
      return renderRepeatSlots(slot, viewTo, lockedOnly, splitNightShift, tz);
    }
    return addSlotToCellsMap(slot, splitNightShift, tz);
  });
}

function renderRepeatSlots(slot, viewToStr, lockedOnly, splitNightShift, tz) {
  const repeatUntil = new Date(slot.RepeatUntil);
  const viewTo = new Date(viewToStr);
  viewTo.setUTCHours(23, 59, 59, 999);
  const curFrom = new Date(slot.From);
  curFrom.setUTCHours(curFrom.getUTCHours(), curFrom.getUTCMinutes(), curFrom.getUTCSeconds(), curFrom.getUTCMilliseconds());
  const repeatExcludeMap = {};
  const repeatLockedMap = {};

  slot.RepeatExclude?.forEach(d => {
    repeatExcludeMap[timestampFromDate(d)] = true;
  });
  slot.RepeatLocked?.forEach(d => {
    repeatLockedMap[timestampFromDate(d)] = true;
  });

  do {
    const d = timestampFromDate(curFrom)
    if (!repeatExcludeMap[d]) {
      if (lockedOnly) {
        // if locked only, check repeatLockedMap
        if (repeatLockedMap[d]) {
          addSlotToCellsMap(slot, splitNightShift, tz, new Date(d));
        }
      } else {
        // if not locked only, just add
        addSlotToCellsMap(slot, splitNightShift, tz, new Date(d));
      }
    }
    curFrom.setUTCDate(curFrom.getUTCDate() + 7)
  } while (curFrom <= viewTo && curFrom <= repeatUntil)
}

export const deleteMode = reactive({
  isOn: false,
  selectedSlots: []
});

export function handleInitSlotLogMap(newSlotLogMap) {
  initSlotLogMap(newSlotLogMap);
}

function mountAutoModal(props) {
  const app = createApp(AutoModal, {
    roles: props.roles,
    timePeriodType: props.timePeriodType,
    tags: props.tags,
    activeDate: props.activeDate
  });
  window[MODAL_ID.AUTO_MODAL] = app.mount(MODAL_ID.AUTO_MODAL)
  $(`${MODAL_ID.AUTO_MODAL}-btn`).on("click", () => {
    window[MODAL_ID.AUTO_MODAL].openModal();
  })
}

function mountVueComponent(mountPoint, props, component) {
  const app = createApp(component, props);
  app.config.unwrapInjectedRef = true;
  const useShiftBorders = getShiftBordersSetting();

  app.provide('deleteMode', computed(() => deleteMode));
  app.provide('cellsMap', cellsMap);
  app.provide('cellEditMode', computed(() => cellEditMode))
  app.provide('slotLogMap', computed(() => reactiveSlotLogMap));
  app.provide('useShiftBorders', useShiftBorders);
  window[mountPoint] = app.mount(mountPoint);
}

export function addSlotToCellsMap(s, splitNightShift, tz, repeatDate) {
  const dateStr = repeatDate ? goDateString(repeatDate) : s.Date;
  if (!cellsMap[s.UserID]) {
    cellsMap[s.UserID] = {};
    cellsMap[s.UserID][dateStr] = {slots: {}};
  } else if (!cellsMap[s.UserID][dateStr]) {
    cellsMap[s.UserID][dateStr] = {slots: {}};
  }

  cellsMap[s.UserID][dateStr].slots[s.ID] = {};

  if (!splitNightShift) {
    return;
  }

  // Add it to subsequent days as well, if it crosses midnight
  const dateStrings = getDateStringsForSlot(s, tz, repeatDate);
  dateStrings.forEach((dateString) => {
    if (!cellsMap[s.UserID][dateString]) {
      cellsMap[s.UserID][dateString] = {slots: {}};
    }
    cellsMap[s.UserID][dateString].slots[s.ID] = {};
  });
}

// Returns the equivalent of `slot.Date`, but for each date it crosses instead of just the first.
export function getDateStringsForSlot(slot, tz, repeatDate = null) {
  const fromDate = fromOplusTime(dateFromSecondsTimestamp(slot.FromUnix));
  const toDate = fromOplusTime(dateFromSecondsTimestamp(slot.ToUnix));
  let fromDateTime = DateTime.fromJSDate(fromDate, {zone: tz});
  let toDateTime = DateTime.fromJSDate(toDate, {zone: tz});
  if (repeatDate) {
    const repeatFromDateTime = DateTime.fromJSDate(new Date(repeatDate), {zone: tz});
    let repeatToDateTime = repeatFromDateTime;

    // For Overnight repeat slots, we need to check repeat date to slot's end date if it crosses midnight
    if (!fromDateTime.hasSame(toDateTime, 'day')) {
      repeatToDateTime = repeatToDateTime.plus({days: 1})
    }

    fromDateTime = DateTime.fromObject({
      year: repeatFromDateTime.year,
      month: repeatFromDateTime.month,
      day: repeatFromDateTime.day,
      hour: fromDateTime.hour,
      minute: fromDateTime.minute,
      second: fromDateTime.second,
      millisecond: fromDateTime.millisecond
    }, {
      zone: tz
    });
    toDateTime = DateTime.fromObject({
      year: repeatToDateTime.year,
      month: repeatToDateTime.month,
      day: repeatToDateTime.day,
      hour: toDateTime.hour,
      minute: toDateTime.minute,
      second: toDateTime.second,
      millisecond: toDateTime.millisecond
    }, {
      zone: tz
    });
  }
  // Set slot's To value to next day if its a type 1 ~ 7 or HideTo is true, since its To value is the same as From
  // `slot.LinkNext && fromDateTime.day == toDateTime.day` part is for LinkNext, is to make sure LinkNext from and to have different date (seems an issue in the backend)
  if (slot.Type > 0 || slot.HideTo || (slot.LinkNext && fromDateTime.day == toDateTime.day)) {
    toDateTime = toDateTime.plus({days: 1})
  }

  // support of LinkNext, for older slots that has LinkNext, we only need the first date
  if (slot.LinkNext) {
    toDateTime = fromDateTime.endOf('day')
  }
  const dates = [];

  let midnight = fromDateTime.startOf('day');

  while (midnight < toDateTime) {
    let newDateStr = midnight.toFormat('yyyy-MM-dd');
    dates.push(newDateStr);
    midnight = midnight.plus({days: 1});
  }

  return dates;
}

function renderVueCells(cellsMap, canEdit, dates, branchSettings, slotMap) {
  dates.forEach((date, dateIdx) => {
    const ds = date.String;
    // TODO: userMap includes all users regardless of what users appear in table. Vue rendering should take this into account in the future.
    Object.keys(App.userMap).forEach((uid, userIdx) => {
      const sel = `#date-${uid}_${ds}`;
      // Confirm that the user has the cell in the template before attempting to mount.
      if (!$(sel).length) {
        return;
      }
      const props = {
        date: ds,
        userId: parseInt(uid),
        slotProps: cellsMap[uid]?.[ds]?.slots,
        branchSettings,
        slotMap,
        meStore,
        isUsedInShiftPage: false,
        prevUserId: parseInt(Object.keys(App.userMap)[userIdx - 1] || 0),
        nextUserId: parseInt(Object.keys(App.userMap)[userIdx + 1] || 0),
        prevDate: dates[dateIdx - 1]?.String || "",
        nextDate: dates[dateIdx + 1]?.String || "",
      };
      mountVueComponent(sel, props, Cell);
    })
  })
}

export function updateSelectedSlotsCount() {
  const text = document.getElementById("delete-slot-count");
  const count = deleteMode.selectedSlots.length;
  return text.innerText = count;
}

export function resetSelectedSlots() {
  deleteMode.selectedSlots = [];
  updateSelectedSlotsCount();
}

//findRoleById will use .find() on the dash base's App.roles data array with optional chaining. Returns a role object or undefined.
export function findRoleById(roleId) {
  return App?.roles?.find(r => r.ID == roleId);
}

//findUserById will use .find() on the dash base's App.userMap object with optional chaining. Returns an object with a subset of fields from a BranchUser or undefined.
export function findUserById(userId) {
  return App?.userMap?.[userId];
}

//getRoleCountByIdAndDate will return the role count settings, including required count, for the given role on the given date. Returns an object or undefined.
export function getRoleCountByIdAndDate(roleId, dateString) {
  return App?.rolesCount?.[roleId]?.dates?.[dateString];
}

//setRoleCountTextColor will add a spectre text color class to the role count text for the given role and date based on the role's required count and the current count.
export function setRoleCountTextColor(count, roleId, dateString, selector, requiredCount = null) {
  const outer = $(selector);
  outer.removeClass();
  const roleCountForDate = getRoleCountByIdAndDate(roleId, dateString);
  let required = roleCountForDate?.Required;
  // Override required count if `requiredCount` param exists, this for roleSection
  if (requiredCount) {
    required = requiredCount
  }
  if (roleCountForDate !== undefined) {
    if (required === 0 && count === 0) {
      outer.addClass('text-gray');
    } else if (count === required) {
      outer.addClass('text-success');
    } else if (count > required) {
      outer.addClass('text-warning');
    } else if (count < required) {
      outer.addClass('text-error');
    }
  }
}

// Construct data object from Modal
function constructDataFromSlotModal(extract) {
  $("#slot-modal-from").removeClass("is-error");
  $("#slot-modal-to").removeClass("is-error");
  $("#slot-modal-purpose").removeClass("is-error");

  const fromInput = $("#slot-modal-from").val().trim();
  const toInput = $("#slot-modal-to").val().trim();
  const rawFrom = fromInput.length === 3 ? `0${fromInput}` : fromInput;
  const rawTo = toInput.length === 3 ? `0${toInput}` : toInput;
  const purpose = $("#slot-modal-purpose").val().trim();

  const parsedFrom = ExtractTimeAndMin(rawFrom, true);
  const parsedTo = ExtractTimeAndMin(rawTo, true);

  if (rawFrom !== "" && !parsedFrom.isValid) {
    $("#slot-modal-from").addClass("is-error");
    return;
  }
  if (rawTo !== "" && !parsedTo.isValid) {
    $("#slot-modal-to").addClass("is-error");
    return;
  }

  if (SlotModal.type == 0 && !SlotModal.roleID && !SlotModal.patternID) {
    if (rawFrom === "" && rawTo === "" && purpose === "") {
      $("#slot-modal-from").addClass("is-error");
      $("#slot-modal-to").addClass("is-error");
      $("#slot-modal-purpose").addClass("is-error");
      return null;
    }
  }

  const data = {
    "from": parsedFrom.toString(),
    "to": parsedTo.toString(),
    "comID": activeCom.ID,
    "date": SlotModal.slotDate,
    "type": SlotModal.type,
    "purpose": $("#slot-modal-purpose").val(),
    "locked": $("#slot-modal-lock-checkbox").prop("checked"),
    "rest": $("#slot-modal-rest").val(),
    "sendEmail": $("#slot-modal-send-email-checkbox").prop("checked"),
    "help": $("#slot-modal-help").val(),
    "isMadeFromTemplate": true,
  };
  if (!extract) {
    const repeat = $("#slot-modal-repeat-checkbox").prop("checked");
    if (repeat) {
      data["repeat"] = true;
    }
  } else {
    data["extractID"] = SlotModal.slotID;
  }

  // SlotModal.edited is a boolean flag to signify if a shift leader or owner is performing the slot creation or edit. This includes comments.
  if (SlotModal.edited) {
    data["edited"] = true;
  }

  if (SlotModal.userID) {
    data["userID"] = SlotModal.userID;
  }
  if (SlotModal.roleID) {
    data["roleID"] = SlotModal.roleID;
  }
  if (SlotModal.patternID) {
    data["patternID"] = SlotModal.patternID;
  }
  if (SlotModal.askStatus > 0) {
    data["askStatus"] = SlotModal.askStatus;
  }
  if (SlotModal.data["special"]){
    data["special"] = SlotModal.data["special"];
    addFilteredUsersToData(data);
  }

  const selectedColor = getSelectedColorBox();
  if (selectedColor) {
    data["color"] = selectedColor;
  }

  // for Edit
  if (!extract && SlotModal.slot) {
    return data
  }

  // for Create
  data["comment"] = $("#slot-modal-comment").val();
  return data;
}

// addFilteredUsersToData adds a string of visible user ids to post data if we have filters
// Note: this is copied from slot_crud file
export function addFilteredUsersToData(data) {
  const filterModal = window[MODAL_ID.TAG_ROLE_FILTER];
  if (data["special"] != "all" || !filterModal?.hasAnyActiveFilters) {
    return;
  }

  const visibleUsers = [];
  // For now, go to the DOM to get visible users and their ids. In the future, this will be a prop or data value.
  $('.user-row').each(function(_, element) {
    visibleUsers.push($(element).data("id"));
  })

  data["users"] = visibleUsers.join(",");

}

// Construct data object from selected slot template
function constructDataFromSlotTemplate(dateUnix, userID, special) {
  if (!selectedTemplate) {
    return;
  }
  const slot = SlotMap[selectedTemplate.id];

  if (dashboard.alertStaffIfVacationMaxedOut(slot)) {
    alertModal.alert("月休み希望数を超えています。", false);
    return;
  }

  const date = dateFromSecondsTimestamp(dateUnix);
  const dateString = goDateString(date); // YYYY-MM-DD date string is used for data purposes.

  let from = "", to = "", type, patternID, purpose, rest, locked, color, roleID;

  if (selectedTemplate.type === "pattern") {
    type = 0;
    patternID = selectedTemplate.id;
  } else if (selectedTemplate.type === "slot" && slot) {
    if (!slot.HideFrom) {
      from = slot.FromLabel;
    }
    if (!slot.HideTo) {
      to = slot.ToLabel;
    }
    type = slot.Type;
    purpose = slot.Purpose;
    rest = slot.Rest;
    locked = slot.Locked;
    color = slot.Color;
    roleID = slot.RoleID;
  } else if (selectedTemplate.type === "role") {
    roleID = selectedTemplate.id;
    type = selectedTemplate.slotType;
  } else {
    return;
  }

  const data = {
    "from": from,
    "to": to,
    "comID": activeCom.ID,
    "date": dateString,
    "type": type,
    "purpose": purpose,
    "rest": rest,
    "locked": locked,
    "edited": activeUser.canEdit,
    "userID": userID,
    "color": color,
    "patternID": patternID,
    "roleID": roleID,
    "special": special,
    "isMadeFromTemplate": true,
  };

  addFilteredUsersToData(data);

  return data;
}

export async function putSlotAfterDrag(slotID, data, oldSlot, event) {
  try {
    const response = await slotPutRequest(slotID, data);
    if (isUsingRequestSlots) {
      handleDragRequestSlotDOM(response, event, oldSlot);
    }
    const slot = getFirstSlotFromResponse(response);
    addToUndoStack(slot, structuredClone(oldSlot), false, true);
    handleDragSlotSuccess(response);
  }
  catch (err) {
    alertModal.alert(errors.SLOT.UPDATE);
    return;
  }
}

export function handleDragSlotSuccess(response) {
  let slot;
  if (!response.success) {
    alertModal.alert(errors.SLOT.UPDATE, true);
    return
  }
  // update labels if type is 0
  if (!response.addList || response.addList.length == 0) {
    slot = response.slot
    response.addList = [ response.slot ];
  }

  if (response.addList && response.addList.length > 0) {
    for (let i = 0; i < response.addList.length; i++) {
      slot = response.addList[i];
      SlotModal.onDelete(slot.ID, false);
    }

    for (let i = 0; i < response.addList.length; i++) {
      slot = response.addList[i];
      SlotMap[slot.ID] = slot;
      SlotModal.onSubmit(slot, true);
    }

    // Stats requires special handling for linked slots.
    const isLinkedSlotsDrag = response.addList[0].LinkNext || response.addList[0].LinkPrev;
    if (isLinkedSlotsDrag) {
      handleStatsLinked(response.addList);
    } else {
      response.addList.forEach(s => {
        handleRawSlotChange(s);
      })
    }
  }

  if (response.deleteList) {
    for (let i = 0; i < response.deleteList.length; i++) {
      SlotModal.onDelete(response.deleteList[i]);
    }
  }
}

// DOM manipulation when Requested Slot settings is ON
function handleDragRequestSlotDOM(response, event, oldSlot) {
  // data response changes when Request slot setting is ON,
  // When we drag a slot created by staff, we receive 2 slots data, (response.slot and response.addList)
  // this is to make all these slots are on the same array (addList) in order to render them
  if (response.slot && response.addList.length == 1) {
    response.addList.push(response.slot);
  }
  // Send the dragged slot to its original cell when its a slot created by Staff
  $(`#date-${oldSlot.UserID}_${oldSlot.Date} .vue-cell-dropzone`).append(event.originalSource);
  $(event.originalSource).removeClass("disabled")
}

// Submit function for creating/editing slot
let isSubmitting = false;
async function summarySlotSubmit(extract) {
  try {
    if (isSubmitting) {
      return;
    }
    if (!extract && $("#slot-submit").is(":hidden")) {
      return;
    }
    $(SLOT_SUBMIT_BUTTON_ID.SUBMIT).addClass("loading");
    isSubmitting = true;

    const data = constructDataFromSlotModal(extract);
    if (!data) {
      return;
    }
    let response;
    if (!extract && SlotModal?.slot) {
      response = await slotPutRequest(SlotModal.slot.ID, data);
      const slot = getFirstSlotFromResponse(response);
      addToUndoStack(slot, structuredClone(SlotMap[slot.ID]));
      handleUpdateSuccess(response, true);
    } else {
      response = await slotPostRequest(data);
      const slot = getFirstSlotFromResponse(response);
      addToUndoStack(slot);
      handleCreateSuccess(response, data);
    }
  }
  catch (err) {
    alertModal.alert(errors.SLOT.CREATE, true);
  } finally {
    isSubmitting = false;
    $(SLOT_SUBMIT_BUTTON_ID.SUBMIT).removeClass("loading");
  }

  SlotModal.close();
}

function handleSlotSubmit() {
  return summarySlotSubmit(false);
}

function handleSlotExtract() {
  // Bandaid fix when submitting repeated slot, until we find better solution,
  // We ask users if they want to edit and remove this slot from the repeated slots and create a new one instead.
  if (!confirm("このリピートシフトは解除され新規のシフトに変更されますが、よろしいですか？")) {
    return;
  }
  return summarySlotSubmit(true);
}

function handleSlotCommentExtract() {
  if (!confirm("コメント追加により、このリピートシフトは解除され新規のシフトに変更されますが、よろしいですか？")) {
    return;
  }
  return summarySlotSubmit(true);
}

// Submit function for deleting slot
function summarySlotDelete() {
  if (!SlotModal.slotID) {
    return;
  }
  if (SlotModal.slot.Repeat) {
    showDashModal(true, DELETE_SLOT_PARAM_STR.DELETE_REPEAT);
    return;
  }
  handleSummarySlotDelete();
  return;
}

async function handleSummarySlotDelete() {
  $(SLOT_SUBMIT_BUTTON_ID.DELETE).addClass("loading");
  try {
    const response = await slotDeleteRequest(SlotModal.slotID);
    // Since we currently use Modal to delete slots, we grab the ID from the SlotModal object
    // We currently do not support undo when doing bulk delete, because we also don't have bulk create for slots.
    addToUndoStack(structuredClone(SlotMap[SlotModal.slotID]), null, true);
    handleDeleteSuccess(response);
  }
  catch (err) {
    return alertModal.alert(errors.SLOT.DELETE);
  }
  finally {
    SlotModal.close();
  }
}

async function addSummarySlotTemplate(dateUnix, userID, special) {
  const data = constructDataFromSlotTemplate(dateUnix, userID, special);

  try {
    const response = await slotPostRequest(data);
    const slot = getFirstSlotFromResponse(response);
    addToUndoStack(slot);
    return handleTemplateCreateSuccess(response);
  }
  catch (err) {
    return alertModal.alert(errors.SLOT.CREATE);
  }
}

function undoActionCallback(undoActionType, response, isDrag) {
  switch (undoActionType) {
    case ACTIONS_TYPE.CREATE:
      handleCreateSuccess(response);
      break;
    case ACTIONS_TYPE.DELETE:
      handleDeleteSuccess(response);
      break;
    case ACTIONS_TYPE.UPDATE:
      if (isDrag) {
        handleDragSlotSuccess(response);
      } else {
        handleUpdateSuccess(response, true);
      }
      break;
  }
}

export async function undoHandler() {
  const lastAction = getLastUndoStack();

  if (!lastAction) {
    return;
  }

  enableUndoLoading();
  const { undoActionType, slot, newSlot, isDrag } = lastAction;

  try {
    const response = await undoActionRequest(undoActionType, slot, newSlot);
    undoActionCallback(undoActionType, response, isDrag);
    removeLastActionFromUndoStack();
    removeDeletedSlotInStack(undoActionType, slot.ID);
  } catch (err) {
    alertModal.alert("unable to undo:", err);
  } finally {
    disableUndoLoading();
  }
}

// Override handleAddButtonClick function from dashboard_base file
export function handleSummaryAddButtonClick(dateUnix, userID, slotID, formValues = {}) {

  if (!selectedTemplate) {
    openSlotModal(dateUnix, userID, slotID, formValues);
    return;
  }

  addSummarySlotTemplate(dateUnix, userID, formValues.special);
  return;
}

async function summaryDeleteRepeatSlot() {
  const choiceValue = document.querySelector('.delete-repeat-choice:checked')?.value;
  if (choiceValue) {
    const params = `?repeat=1&type=${DELETE_REPEAT_INPUT_VALUE[choiceValue]}&date=${SlotModal.slotDate}`
    $(SLOT_SUBMIT_BUTTON_ID.DELETE_REPEAT).addClass("loading");

    try {
      const response = await slotDeleteRequest(SlotModal.slotID, params)
      handleDeleteSuccess(response);
    }
    catch (err) {
      return alertModal.alert(errors.SLOT.DELETE);
    }
    finally {
      SlotModal.close();
    }
  }
}

// Helper function to get first slot (in case of split) from response, specifically or currently used for undo feature
function getFirstSlotFromResponse(response) {
  let slot = response.slot;
  if (response.addList && response.addList.length) {
    slot = response.addList[0];
  }

  return slot;
}

function initStats(roleStore, employmentStore, shiftStore, meStore, dates, period, settings, timeZone) {
  const options = {
    onlyCountFirstPeriod: !settings.splitNightShift,
    onlyCountFirstRole: true,
    onlyCountFirstTransport: true,
    ignoreHideToLaborHours: true,
  };
  const summaryStats = new EmploymentShiftTableStats(roleStore, employmentStore, shiftStore, meStore, dates, period, timeZone, options);

  return summaryStats;
}

// For interfacing with template
export function handleRawSlotChange(rawSlot) {
  if (!rawSlot || rawSlot.LinkNext || rawSlot.LinkPrev) {
    return;
  }
  let prev = shiftStore.byId(rawSlot.ID.toString());
  if (prev && prev.isLinkedShift()) {
    let night, morning;
    if (prev.isMorningShift()) {
      morning = prev;
      night = shiftStore.byId(prev.getLinkedId());
    } else {
      night = prev;
      morning = shiftStore.byId(prev.getLinkedId());
    }
    if (morning && night) {
      prev = mergeLinkedShifts(morning, night);
    }
  }
  const options = {
    prevShift: prev,
  }

  handleSlotChange(rawSlot, branchStore.byId(meStore.getActiveBranchId())?.timeZone);

  const shift = shiftStore.byId(rawSlot.ID.toString());
  // For repeat change, query and do a handleShiftChange for all
  if (rawSlot.Repeat) {
    const shifts = shiftStore.query({repeatTemplateId: rawSlot.ID});
    shifts.forEach(s => {
      summaryStats.handleShiftChange(s, options);
    })

    renderAllStatsColumns();
    renderAllStatsRows();
  }

  if (!shift) {
    return;
  }
  summaryStats.handleShiftChange(shift, options);
  renderAllStatsColumns();
  renderAllStatsRows();
}

// Interface for template
export function handleStatsLinked(rawSlots) {
  if (!rawSlots && !rawSlots.length) {
    return;
  }

  let prev = shiftStore.byId(rawSlots[0].ID.toString());
  if (!prev && rawSlots[1]) {
    prev = shiftStore.byId(rawSlots[1].ID.toString())
  }
  if (prev && prev.isLinkedShift()) {
    let night, morning;
    if (prev.isMorningShift()) {
      morning = prev;
      night = shiftStore.byId(prev.getLinkedId());
    } else {
      night = prev;
      morning = shiftStore.byId(prev.getLinkedId());
    }
    if (morning && night) {
      prev = mergeLinkedShifts(morning, night);
    }
  }

  // Add to store
  rawSlots.forEach(s => {
    handleSlotChange(s, branchStore.byId(meStore.getActiveBranchId())?.timeZone);
  })

  const options = {
    prevShift: prev,
  }

  // Special treatment for linked and calc stats
  rawSlots.forEach(s => {
    let shift = shiftStore.byId(s.ID.toString());
    if (!shift) {
      return;
    }
    if (shift.isLinkedShift()) {
      let night, morning;
      if (shift.isMorningShift()) {
        morning = shift;
        night = shiftStore.byId(shift.getLinkedId());
      } else {
        night = shift;
        morning = shiftStore.byId(shift.getLinkedId());
      }
      if (morning && night) {
        // Only calc stats for merged shift once, if both shift are in view.
        if (morning.getId() == shift.getId()) {
          return;
        }
        shift = mergeLinkedShifts(morning, night);
        options.countAllPeriods = true;
        options.applyRestFieldToFirstPeriodOnly = true;
      }
    }
    summaryStats.handleShiftChange(shift, options)
  })

  // Render
  renderAllStatsColumns();
  renderAllStatsRows();
}

// handle help select input change on slot modal
export function handleHelpBranchChange(branchId) {
  let selectedBranchId = branchId
  // if no branchId value, we fetch the active branch instead
  if (!branchId) {
    selectedBranchId = activeCom.ID;
  }
  getAndUpdateRolesList(selectedBranchId);
  return;
}

export function setRoleIdToSlotModal(roleId) {
  SlotModal.setRole(roleId);
  return;
}

function handleSlotChange(rawSlot, repeatTimeZone) {
  if (rawSlot.SectionParent) {
    return;
  }

  let shift;

  if (rawSlot.Repeat) {
    shift = shiftParser.parseRepeatShiftTemplateOplusTimeRaw(rawSlot, repeatTimeZone);

    const repeatShifts = repeatShiftsFromTemplate(tableDates[0].getDate(), tableDates[tableDates.length - 1].getEnd(), shift, "Asia/Tokyo", "Asia/Tokyo");
    // TODO: remove previous repeat shifts
    const prev = shiftStore.query({repeatTemplateId: rawSlot.ID});
    prev.forEach(s => {
      shiftStore.delete(s);
    })
    repeatShifts.forEach(r => {
      shiftStore.add(r);
      summaryStats.handleShiftChange(r)
    })
  } else if (rawSlot.LinkPrev) {
    shift = shiftParser.parseMorningShiftOplusTimeRaw(rawSlot)
  } else if (rawSlot.LinkNext) {
    shift = shiftParser.parseNightShiftOplusTimeRaw(rawSlot)
  } else if (rawSlot.IsTemplate) {
    // not implemented
    return;
  } else {
    shift = shiftParser.parseOplusTimeRaw(rawSlot);
  }

  if (shift.isClone(meStore.getActiveBranchId()) || shift.isShiftTemplate() || shift.isRepeatShiftTemplate()) {
    return;
  }

  shiftStore.add(shift);
}

export function handleSlotOvernightChange(rawSlot, prevRawSlot) {
  const dateStr = getDateStringsForSlot(rawSlot, 'Asia/Tokyo', null);
  const prevDateStr = getDateStringsForSlot(prevRawSlot, 'Asia/Tokyo', null);

  // No change in overnight status
  if (dateStr.length === prevDateStr.length) {
    return;
  }

  if (dateStr.length > prevDateStr.length) {
    // Slot became overnight
    SlotModal.onSubmit(rawSlot, true);
    return;
  }

  // Slot reverted to non-overnight time
  removeOvernightSlot(prevRawSlot, prevDateStr, dateStr);
}

function removeOvernightSlot(prevRawSlot, prevDateStr, dateStr) {
  const toRemoveDateStr = prevDateStr.filter(d => !dateStr.includes(d));
  const userCells = cellsMap[prevRawSlot.UserID];

  if (!userCells) {
    return;
  }

  toRemoveDateStr.forEach(dateString => {
    const cell = userCells[dateString];

    if (!cell || !cell.slots) {
      return;
    }

    delete cell.slots[prevRawSlot.ID];

    const cellApp = window[`#date-${prevRawSlot.UserID}_${dateString}`];

    if (cellApp) {
      cellApp.updateSlots(cell.slots);
    }
  });
}


function renderAllStatsRows() {
  const employments = employmentStore.query({branchId: meStore.getActiveBranchId()});
  employments.forEach(e => {
    const row = summaryStats.getRowStats(e.index()).getData();
    renderStatsRow(e.getUserId(), row);
  })
}

function renderStatsRow(userId, row) {
  renderStats(`#work-count-${userId}`, row.numOfWorkers);
  renderStats(`#labor-hours-${userId}`, roundTwoDecimals(row.laborHours));

  Object.keys(row.offCounts).forEach(offType => {
    renderStats(`#${offType}-count-${userId}`, row.offCounts[offType])
  })

  Object.keys(row.roleCounts).forEach(roleId => {
    const role = roleStore.byId(roleId);
    if (!role || role.isOrRole() || role.isMainRole()) {
      return;
    }
    const countText = row.roleCounts[roleId] || "-";
    renderStats(`#role-count-${roleId}-${userId}-count`, countText);
  })

  roleStore.query({isMainRole: true, filterMainRole: true}).forEach(r => {
    const roleId = r.getId();
    const onlyRequiresOr = true;
    if (onlyRequiresOr) {
      const countText = row.orRoleCounts[roleId] || "-";
      renderStats(`#role-count-${roleId}-${userId}-count`, countText);
      return;
    }
  })
}

function renderAllStatsColumns() {
  tableDates.forEach(d => {
    const column = summaryStats.getColumnStats(d.index()).getData();
    const date = d.getDate();
    renderStatsColumn(toOplusTime(date), column);
  })
}

function renderStatsColumn(date, column) {
  //Element IDs currently use the timestamps from the Go template, which are in seconds so we convert JS-standard ms to seconds.
  const selectorTimestamp = yearMonthDateString(date, "UTC"); // To work with oplus time DOM, hardcode UTC timezone.
  renderStats(`#num-of-workers-${selectorTimestamp}`, column.numOfWorkers);
  renderStats(`#labor-hours-${selectorTimestamp}`, roundTwoDecimals(column.laborHours, ","));
  renderStats(`#labor-cost-${selectorTimestamp}`, `${roundNum(column.laborCost, ",")}円`)
  renderStats(`#transport-cost-${selectorTimestamp}`, `${roundNum(column.transportCost, ",")}円`)
  renderStats(`#total-cost-${selectorTimestamp}`, `${roundNum(column.transportCost + column.laborCost, ",")}円`)
  Object.keys(column.roleCounts).forEach(roleId => {
    const role = roleStore.byId(roleId);
    if (!role || role.isOrRole() || role.isMainRole()) {
      return;
    }

    let countText = column.roleCounts[roleId];
    const roleCountRequired = getRoleCountByIdAndDate(roleId, selectorTimestamp)?.Required;
    if (countText === 0 && !roleCountRequired) {
        //If the count is zero and there is no required count set, then we'll set the text back to dash mark instead of zero.
        countText = "-";
    };

    renderStats(`#role-count-${roleId}-${selectorTimestamp}-count`, countText);
    setRoleCountTextColor(column.roleCounts[roleId], roleId, selectorTimestamp, `#role-count-${roleId}-${selectorTimestamp}-text`);
    //TODO: refactor setRoleCountTextColor to not remove all classes, then remove this class add
    $(`#role-count-${roleId}-${selectorTimestamp}-text`).addClass('stats-cell-text');
  })

  roleStore.query({isMainRole: true, filterMainRole: true}).forEach(r => {
    const roleId = r.getId();
    const onlyRequiresOr = true;
    if (onlyRequiresOr) {
      const currentCount = column.orRoleCounts[roleId] || 0;
      let countText = currentCount.toString();
      const roleCountRequired = getRoleCountByIdAndDate(roleId, selectorTimestamp)?.Required;
      if (currentCount === 0 && !roleCountRequired) {
          //If the count is zero and there is no required count set, then we'll set the text back to dash mark instead of zero.
          countText = "-";
      };

      renderStats(`#role-count-${roleId}-${selectorTimestamp}-count`, countText);
      setRoleCountTextColor(currentCount, roleId, selectorTimestamp, `#role-count-${roleId}-${selectorTimestamp}-text`);
      //TODO: refactor setRoleCountTextColor to not remove all classes, then remove this class add
      $(`#role-count-${roleId}-${selectorTimestamp}-text`).addClass('stats-cell-text');
      return;
    }
  })
}

function renderStats(selector, statText) {
  $(selector).text(statText);
}

// For interfacing with template
export function handleSlotDelete(rawSlot) {
  if (!rawSlot) {
    return;
  }

  let shift = shiftStore.byId(rawSlot.ID.toString());
  if (rawSlot.Repeat) {
    // For now, we get date of the repeat from the modal to delete only the "extracted" shift.
    // In the future, slot modal should pass the deleted slot entity / feId so we don't need to query.
    const oplusTimeUnix = SlotModal.data?.dateUnix;
    if (!oplusTimeUnix) {
      return;
    }
    const oplusTimeDate = dateFromSecondsTimestamp(oplusTimeUnix);
    const d = fromOplusTime(oplusTimeDate);
    const max = endDateForPeriod(d, periodTypes.DAY, "Asia/Tokyo");
    const res = shiftStore.query({repeatTemplateId: rawSlot.ID, startDateMin: d, startDateMax: max});
    shift = res[0];
  }

  if (!shift) {
    return;
  }

  let statsShift = shift;

  const options = {
    isDelete: true,
  }

  if (shift.isLinkedShift()) {
    let night, morning;
    if (shift.isMorningShift()) {
      morning = shift;
      night = shiftStore.byId(shift.getLinkedId());
    } else {
      night = shift;
      morning = shiftStore.byId(shift.getLinkedId());
    }
    if (morning && night) {
      // Only calc stats for merged shift once, if both shift are in view.
      if (morning.getId() == shift.getId()) {
        return;
      }
      statsShift = mergeLinkedShifts(morning, night);
      options.countAllPeriods = true;
    }
  }

  summaryStats.handleShiftChange(statsShift, options);
  renderAllStatsColumns();
  renderAllStatsRows();
  // delete from store
  if (statsShift.isMergedShift()) {
    shiftStore.delete(statsShift.getMorningShiftId());
    shiftStore.delete(statsShift.getNightShiftId());
    return;
  }

  shiftStore.delete(shift.getId());
}

async function ccbizSync(startDate, endDate) {
  if (!activeCom?.hasCCBizMate) {
    alertModal.alert("The current company has no CC·BizMate id!", false)
    return;
  }

  ccbiz.sync(startDate, endDate);
}


/* ----------------------------------------------------

ATTENTION:
This is used by the print page as well,
any changes to the below function will very likely break the print page.

-------------------------------------------------------
*/
export async function initTable(props) {
  branchStore = new BranchStore();
  const branch = branchParser.parseFromRaw(props.pipelineActiveCompany);
  branch.setSettings(gBranchSettings);
  branchStore.add(branch);
  const req = {
    id: props.activeUserID,
  }
  const user = new User(req)
  meStore = new MeStore({user}, {
    isLeader: props.canEdit
  });
  meStore.setActiveBranchId(branch.getId())

  roleStore = new RoleStore();

  props.roles?.forEach(raw => {
    // parse as main role
    if (props.orRolesMap.mainRolesMap[raw.ID]) {
      const subs = props.orRolesMap.mainRolesMap[raw.ID];
      const mainRole = roleParser.parseMainRoleFromRaw(raw, subs);
      roleStore.add(mainRole);
      return;
    }

    // parse as sub role
    if (props.orRolesMap.subRolesMap[raw.ID]) {
      const mainRoleId = props.orRolesMap.subRolesMap[raw.ID];
      const subRole = roleParser.parseOrRoleFromRaw(raw, mainRoleId);
      roleStore.add(subRole);
      return
    }

    const role = roleParser.parseFromRaw(raw);
    roleStore.add(role);
  })

  employmentStore = new EmploymentStore();
  props.users.forEach(rawEmployment => {
    const employment = employmentParser.parseFromRaw(rawEmployment, branch.getOriginalId());
    employmentStore.add(employment);
  })

  // Hotfix: 1 is month, 0 is week
  const defaultSetting = props?.pipelineActiveBranchSettings?.DefaultView == 0 ? viewTypes.WEEK : viewTypes.MONTH;
  const viewType = getUrlParam("list") || defaultSetting;
  const dateStr = getUrlParam("date");
  const endDateStr = getUrlParam("until");
  // To work "properly" with oplus time, hardcode time zone to JST
  // Eventually, this will be a user setting and/or guess
  const localTimezone = "Asia/Tokyo";
  const activeBranch = branchStore.byId(meStore.activeBranchId);

  let startDate, endDate;
  // To work with oplus time DOM and query data, force date to always be JST.
  const luxonDate = DateTime.now().setZone(localTimezone);
  startDate = luxonDate.toJSDate();
  startDate = truncate(startDate, periodTypes.DAY, localTimezone);
  if (dateStr) {
    try {
      startDate = dateFromString(dateStr, localTimezone);
    } catch (e) {
      alert(e);
      return;
    }
  }

  // Custom
  if (endDateStr) {
    try {
      endDate = dateFromString(endDateStr, localTimezone);
    } catch (e) {
      alert(e)
      return;
    }
  } else {
    // Monthly or weekly
    try {
      startDate = calcStartDate(startDate, viewType, localTimezone, {monthStart: activeBranch.getMonthStart(), weekStart: activeBranch.getWeekStart()})
      endDate = calcEndDate(startDate, viewType, localTimezone, {monthStart: activeBranch.getMonthStart()});
    } catch (e) {
      alert(e.message);
      return;
    }
  }

  tableDates = makeTableDates(startDate, endDate, periodTypes.DAY, localTimezone);

  shiftStore = new ShiftStore();

  summaryStats = initStats(roleStore, employmentStore, shiftStore, meStore, tableDates, periodTypes.DAY, gBranchSettings, localTimezone);

  Object.keys(SlotMap).forEach(slotId => {
    const rawSlot = SlotMap[slotId];

    handleSlotChange(rawSlot, activeBranch.getTimeZone());
  });

  shiftStore.query({}).forEach(s => {
    const options = {};
    let shift = s;
    if (shift.isLinkedShift()) {
      let night, morning;
      if (shift.isMorningShift()) {
        morning = shift;
        night = shiftStore.byId(shift.getLinkedId());
      } else {
        night = shift;
        morning = shiftStore.byId(shift.getLinkedId());
      }
      if (morning && night) {
        // Only calc stats for merged shift once, if both shift are in view.
        if (morning.getId() == shift.getId()) {
          return;
        }
        shift = mergeLinkedShifts(morning, night);
        options.countAllPeriods = true;
        options.applyRestFieldToFirstPeriodOnly = true;
      }
    }
    if (gBranchSettings.getShiftSettings().splitNightShift) {
      options.countAllPeriods = true;
    }
    summaryStats.handleShiftChange(shift, options);
  })

  renderAllStatsColumns();
  renderAllStatsRows();

  if (props.canEdit) { // TODO: use meStore to determine perm level
    await initFunctionRows(props.legacyPlans, props.pipelineActiveBranchSettings.PlanEquationValue, activeBranch, tableDates);
  }
}

// initFunctionRows will initialize the plan function rows for the current view.
// Also used on dws.
export async function initFunctionRows(legacyPlans = [], planEquationValue, activeBranch, tableDates) {
  const planVarStore = new PlanVariableStore()
  const valueStore = new PlanValueStore();
  const functionStore = new PlanFunctionStore();
  const initParams = {
    legacyPlans,
    branch: activeBranch,
    planEquationValue,
  }

  initLegacyFunctions(functionStore, valueStore, planVarStore, initParams);

  await initPlanFunctions(tableDates, activeBranch, functionStore, valueStore);

  renderFunctionRows(functionStore, valueStore, tableDates, activeBranch);
}

function renderFunctionRows(functionStore, valueStore, tableDates, activeBranch) {
  const toCompute = functionStore.query({branchId: activeBranch.getId()});
  const dateToFuncIdToValue = makeComputedValuesMap(tableDates, toCompute, functionStore, valueStore);
  const tableDatesMap = {};
  tableDates.forEach(td => tableDatesMap[td.index()] = td);
  Object.keys(dateToFuncIdToValue).forEach(d => {
    const td = tableDatesMap[d];
    if (!td) {
      return;
    }

    Object.keys(dateToFuncIdToValue[d]).forEach(pfId => {
      // Hacky render for now
      const v = dateToFuncIdToValue[d][pfId];
      const oplusTime = toOplusTime(td.getDate());
      const id = `#pf-${pfId}-${timestampSecsFromDate(oplusTime)}`;
      $(id).text(roundTwoDecimals(v));
    })
  })
}

function mountCellEditMenuApp() {
  const app = createApp(CellEditMenuWrapper);
  app.mount("#cell-edit-menu-app");
}

// Scrolls to today's date in the table
export function scrollToToday() {
  var todayElm = `#date-${GetToday()}`;
  var todayElement = document.querySelector(todayElm);

  if (todayElement) {
    setTimeout(() => {
      todayElement.scrollIntoView({ behavior: "smooth", inline: "center" });
    }, 400);
  }
}

export function openAlertModal(errText) {
  return alertModal.alert(errText)
}
export async function initPage(props) {
  // Legacy. Can remove when confirmed not in use.
  roleStoreOld.init(props.roles, props.orRolesMap);
  const {startDate: startOplusTime, endDate: endOplusTime} = createStartAndEndDatesFromArray(props.dates)
  activeUser = transformUser({
    canEdit: props.canEdit,
  });
  activeCom = transformCompany(props.pipelineActiveCompany);
  gBranchSettings = branchSettingsParser.parseFromRaw(props.pipelineActiveBranchSettings);

  // Table init
  await initTable(props);

  isUsingRequestSlots = props.isUsingRequestSlots;

  buildCellsMap(SlotMap, endOplusTime, props.lockedOnly, gBranchSettings.splitNightShift, gBranchSettings.timezone);
  renderVueCells(cellsMap, activeUser.canEdit, props.dates, gBranchSettings, SlotMap);
  if (isUsingRequestSlots) {
    disableSaveUndo();
  }
  mountTagRoleFilterModal(props);
  mountAutoModal(props)
  mountCellEditMenuApp();
  initSlotModalRoleListComponent(activeCom.ID)
  // as of 2023-12-06 DashURL function is in dashboard_base.html line 4602
  getPageSlotURL(DashURL("slot"))
  scrollToToday();

  // CCbiz
  // sync button
  $(`#${ccbiz.CCBIZ_ELEMENT_IDS.SYNC_BUTTON}`).on('click', () => ccbizSync(startOplusTime, endOplusTime));

  $(`#${kot.BULK_SYNC_BUTTON}`).on('click', () => kot.bulkSync(startOplusTime, endOplusTime));

  // Override submit/delete button for modal and template functions to isolate summary page's (monthly/weekly) slot CRUD
  // In the future, it should also apply to other pages that needs the same CRUD functions, (shift_base, summary_dws)
  $(SLOT_SUBMIT_BUTTON_ID.SUBMIT).attr("onclick", "").on("click", () => handleSlotSubmit());
  $(SLOT_SUBMIT_BUTTON_ID.EXTRACT).attr("onclick", "").on("click", () => handleSlotExtract());
  $(SLOT_SUBMIT_BUTTON_ID.DELETE).attr("onclick", "").on("click", () => summarySlotDelete());
  $(SLOT_SUBMIT_BUTTON_ID.DELETE_REPEAT).attr("onclick", "").on("click", () => summaryDeleteRepeatSlot());
  $(SLOT_SUBMIT_BUTTON_ID.SUBMIT_COMMENT_EXTRACT).attr("onclick", "").on("click", () => handleSlotCommentExtract());
}
