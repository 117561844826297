<script>
// NOTE: This is a copy of ShiftCell component with little changes:
// TODO: Refactor common methods or functions,
import ShiftWeeklySlot from './ShiftWeeklySlot.vue';
import ShiftSlot from '../ShiftSlot.vue';
import { getSlotToDate, isSlotHourlyType } from '../../js/slot_helpers'
import { JPShortDateString, timestampSecsFromDate, timespansOverlap, dateFromSecondsTimestamp, intToJapaneseWeekdayKanji, timestampFromDate } from '../../js/common';
import { filterSlotsForCalendarCell } from '../../js/shift'
import { MeStore } from '../../store/v1/meStore';

  export default {
    props: {
      cellProps: Object,
      isSelected: Boolean,
      isHoliday: Boolean,
      timeView: Array,
      date: String,
      branchSettings: Object,
      meStore: MeStore,
    },
    data() {
      return {
        cellHeight: 60,
        slots: [],
        weeklySlotsData: [],
      }
    },
    methods: {
      handleAddSlotClick(event) {
        event.stopPropagation();
        if (this.isAddSlotDisabled) {
          return;
        }
        const date = this.cellProps.date;
        if (selectedTemplate) {
            addSlotTemplate(timestampSecsFromDate(date), this.meStore.getActiveUserId(), "")
            return
        }
        handleOpenShiftSlotModal(timestampSecsFromDate(date))

      },
      handleCellClick() {
        // We use next here to wait for the popup modal to show,
        // $ref function won't be able to reference the element if its hidden or not mounted.
        this.$nextTick(() => {
          this.$emit("emit-selected-cell", this.cellProps.date);
        })
      },
      closePopup(event) {
        this.$emit("emit-close-popup", event);
      },
      updateAndSortSlots() {
        this.slots = [] // needs to trigger reactivity
        const ids = this.filteredSlots;
        ids.sort((a, b) => new Date(this.slotMap[a].From) - new Date(this.slotMap[b].From))
        this.slots = [...ids];
      },
      updateSectionMap(slotId) {
        const slot = this.slotMap?.[slotId];
        if (slot && slot?.SectionSlots) {
          slot.SectionSlots.forEach(section => {
            this.sectionMap[section.ID] = section;
          })
        }
      },
      handleEmittedSlotId(slotId) {
        const slotData = this.getSlotData(slotId);
        return this.setSlotPosition(slotData);
      },
      handleEmittedSectionId(sectionId) {
        const sectionData = this.getSlotData(sectionId, true);
        return this.setSectionPosition(sectionData);
      },
      getSlotData(slotId, isSection = false) {
        let slot = this.slotMap?.[slotId];
        if (isSection) {
          slot = this.sectionMap[slotId]
        }
        const from = new Date(slot.From)
        const to = new Date(slot.To)

        const out = {
          start: from.valueOf(),
          end: to.valueOf(),
          isTypeZero: slot.Type === 0,
          id: slot.ID,
          hideTo: slot.HideTo,
          parentStart: null,
          parentEnd: null,
          parentId: 0,
        }

        if (isSection) {
          out.parentStart = new Date(this.slotMap[slot.SectionParent].From).getTime();
          out.parentEnd = new Date(this.slotMap[slot.SectionParent].To).getTime();
          out.parentId = slot.SectionParent;
          out.isTypeZero = slot.Type === 0 || slot.Type === 4;
        }

        return out;
      },
      setSlotPosition(slotData) {
        const columnHeight = this.$refs['weekly-calendar-column'].clientHeight;
        this.setPosition(
          slotData,
          columnHeight,
          this.cellProps.shiftTimeStart,
          this.cellProps.shiftTimeEnd,
        );
      },
      setSectionPosition(sectionData) {
        const parentSlotHeight = this.$refs[`slot-${sectionData.parentId}`][0].clientHeight;
        let parentStart = sectionData.parentStart;
        if (parentStart < this.cellProps.shiftTimeStart) {
          parentStart = this.cellProps.shiftTimeStart
        }
        this.setPosition(
          sectionData,
          parentSlotHeight,
          parentStart,
          sectionData.parentEnd,
        );
      },
      setPosition(shiftData, parentHeight, startTimeInTs, endTimeInTs) {
        const totalLengthInTs = endTimeInTs - startTimeInTs;
        const diffInMilliSecs = shiftData.start - startTimeInTs;
        const slotComponentDOM = this.$refs[`slot-${shiftData.id}`][0];
        const positionInPixel = parentHeight * (diffInMilliSecs / totalLengthInTs);

        let totalSlotLength = shiftData.end - shiftData.start;
        if (shiftData.hideTo) {
          totalSlotLength = endTimeInTs - shiftData.start;
        }

        // Set top position
        slotComponentDOM.style.top = diffInMilliSecs > 0 ? `${positionInPixel}px` : '0px';

        // Set height
        if ((diffInMilliSecs === 0 && shiftData.end >= endTimeInTs) || !shiftData.isTypeZero) {
          slotComponentDOM.style.height = `${parentHeight}px`;
        } else if (diffInMilliSecs < 0 && shiftData.start < startTimeInTs) {
          const overlappedLength = totalSlotLength - (startTimeInTs - shiftData.start);
          slotComponentDOM.style.height = `${parentHeight * (overlappedLength / totalLengthInTs)}px`;
        } else {
          slotComponentDOM.style.height = `${parentHeight * (totalSlotLength / totalLengthInTs)}px`;
        }
      },
      // Checks if slot is visible inside the table given DayTo and DayFrom
      isSlotWithinShiftTimeFrame(shiftStartTime, shiftEndTime, slot) {
        if (!isSlotHourlyType(slot)) {
          return true
        }
        const slotStartTime = dateFromSecondsTimestamp(slot.FromUnix).valueOf();
        const slotEndTime = getSlotToDate(slot).valueOf();

        return timespansOverlap(shiftStartTime, slotEndTime, slotStartTime, shiftEndTime)
      },
      isShiftOverlapped(currentSlot, currentIndex, shiftIdsArray, shiftMap) {
        const currentFrom = dateFromSecondsTimestamp(currentSlot.FromUnix);
        const currentTo = getSlotToDate(currentSlot);
        for (let i = 0; i < shiftIdsArray.length; i++) {
          if (currentIndex === i) {
            continue;
          }
          const otherSlot = shiftMap[shiftIdsArray[i]];
          const otherSlotFrom = dateFromSecondsTimestamp(otherSlot.FromUnix);
          const otherSlotTo = getSlotToDate(otherSlot);

          if (timespansOverlap(currentFrom, currentTo, otherSlotFrom, otherSlotTo)) {
            return true;
          }
        }
        return false;
      },
      filterOverlappingSections(sectionIds) {
        const filteredSectionIds = [];
        const sortedSectionIds = sectionIds.sort((a, b) => new Date(this.sectionMap[a].From) - new Date(this.sectionMap[b].From));
        sortedSectionIds.forEach((sectionId, index) => {
          const currentSection = this.sectionMap[sectionId];
          if (!currentSection) {
            return;
          }
          const isOverlapping = this.isShiftOverlapped(currentSection, index, sortedSectionIds, this.sectionMap);
          filteredSectionIds.push({
            id: sectionId,
            isOverlapping: isOverlapping,
          })
        })
        return filteredSectionIds;
      },
    },
    computed: {
      disabledText() {
        return `必要人数が満たされているため無効です`;
      },
      isAddSlotDisabled() {
        return !(this.roleDates?.[this?.$parent?.selectedRoleID]?.[timestampSecsFromDate(this.cellProps.date)]?.ShouldShow ?? true);
      },
      disabledBtnClass() {
        return {
          "add-disabled": this.isAddSlotDisabled,
          "tooltip": this.isAddSlotDisabled,
          "tooltip-left": this.isAddSlotDisabled && this.cellOplusDay != 0,
        };
      },
      computedColumnHeight() {
        return {
          height:  `${this.cellHeight * this.timeView.length}px`
        }
      },
      weeklySlots() {
        this.weeklySlotsData = [];
        const slotIds = this.filteredSlots;
        const cellStartTime = this.cellProps.shiftTimeStart;
        const cellEndTime = this.cellProps.shiftTimeEnd;
        slotIds.forEach((slotId, index) => {
          if (this.isSlotWithinShiftTimeFrame(cellStartTime, cellEndTime, this.slotMap[slotId])) {
            const currentSlot = this.slotMap[slotId];
            const isOverlapping = this.isShiftOverlapped(currentSlot, index, slotIds, this.slotMap)
            this.updateSectionMap(slotId);
            const slot = this.cellProps.slots[slotId];
            const sectionIds = slot && slot.sections ? Object.keys(slot.sections) : [];
            this.weeklySlotsData.push({
              id: slotId,
              isOverlapping: isOverlapping,
              sections: this.filterOverlappingSections(sectionIds),
            })
          }
        })
        return this.weeklySlotsData;
      },
      filteredSlots() {
        return filterSlotsForCalendarCell(this.cellProps.slots, this.isCellCannotAddSlot, this.slotMap);
      },
      getOplusTimeJapDayString() {
        return intToJapaneseWeekdayKanji[this.cellProps.date.getUTCDay()]
      },
      todayClass() {
        return {
          "today-cell": (this.cellProps.date).valueOf() === timestampFromDate(new Date()),
        }
      },
      getOplusTimeWeekday() {
        return this.cellProps.date.getUTCDate();
      },
      cellOplusDate() {
        return this.cellProps?.date;
      },
      cellOplusDay() {
        return this.cellOplusDate.getUTCDay();
      },
      isCellLocked() {
        return this.cellProps?.isLocked;
      },
      isStaffLocked() {
        return this.cellProps?.isStaffLocked;
      },
      isCellClosed() {
        return this.cellProps?.isClosed;
      },
      hasSlots() {
        if (this.cellProps) {
          return Object.keys(this.cellProps.slots).length > 0
        }
      },
      isCellCannotAddSlot() {
        return this.isCellLocked || this.isCellClosed;
      },
      holidayClass() {
        return {
          "th-holiday": this.cellOplusDay === 0 || this.isHoliday,
          "th-saturday": this.cellOplusDay === 6,
        }
      },
      japanOplusDateFormat() {
        return JPShortDateString(this.cellOplusDate, true);
      },
      disabledPopup() {
        return {
          'disabled-popup': this.isCellCannotAddSlot
        }
      },
    },
    emits: [
      "emit-selected-cell",
      "weekly-cell-height",
      "emit-close-popup"
    ],
    // Chose to use watch here, as computed are cached, and wont react to changes when slots are edited
    watch: {
      cellProps: {
        handler() {
          this.weeklySlotsData = [];
          this.updateAndSortSlots();
        },
        deep: true
      }
    },
    components: {
      ShiftWeeklySlot,
      ShiftSlot
    },
    inject: [
      'slotMap',
      'roleDates',
      'sectionMap',
    ],
    created() {
      this.$emit('weekly-cell-height', this.cellHeight)
      this.updateAndSortSlots();
    },
  }
</script>
<template>
  <div @click="handleCellClick()">
    <!-- Cell Headers -->
    <div class="weekly-cell-header">
      <div class="calendar-header-cell" :class="holidayClass" :style="{height: `${this.cellHeight + 30}px`}">
        <span>{{ getOplusTimeJapDayString }}</span>
        <span :class="todayClass">{{ getOplusTimeWeekday }}</span>
        <div class="weekly-cell-add-button">
          <div v-if="cellProps.isLocked">
            <i class="zmdi zmdi-lock zmdi-hc-lg text-gray"></i>
          </div>
          <div v-else-if="cellProps.isClosed">
            <i class="zmdi zmdi-close zmdi-hc-lg text-gray"></i>
          </div>
          <div v-if="!isCellCannotAddSlot" class="selected-template-add">
            <button v-if="!isStaffLocked" class="btn btn-slot-add-primary" :class="disabledBtnClass" :data-tooltip="disabledText" @click="handleAddSlotClick($event)">
              <i class="icon icon-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Cell Body -->
    <div ref="weekly-calendar-column" class="weekly-calendar-column" :style="computedColumnHeight">
      <div v-if="isCellCannotAddSlot" class="weekly-locked-cell"></div>
      <div v-if="hasSlots" class="weekly-slots">
        <!-- Slots -->
        <div v-for="slot in weeklySlots" :key="slot.id" class="weekly-slot-container" :class="slot.isOverlapping ? 'is-overlapped' : ''">
          <div :ref="'slot-' + slot.id" class="weekly-slot-wrapper">
            <ShiftWeeklySlot
              :slot="slotMap[slot.id]"
              :repeat-date="slotMap[slot.id].Repeat ? new Date(cellProps.date) : null"
              :date="date"
              :branch-settings="branchSettings"
              :me-store="meStore"
              @emit-slot-id="handleEmittedSlotId"
            />
            <!-- Sections -->
             <div class="weekly-section-overlay">
               <div class="weekly-sections">
                 <div v-for="section in slot.sections" :key="section.id" class="weekly-section-container" :class="section.isOverlapping ? 'is-overlapped' : ''">
                   <div :ref="'slot-' + section.id" class="weekly-section-wrapper">
                     <ShiftWeeklySlot
                      :slot="sectionMap[section.id]"
                      :repeat-date="null"
                      :date="date"
                      :branch-settings="branchSettings"
                      :me-store="meStore"
                      @emit-slot-id="handleEmittedSectionId"
                    />
                   </div>
                 </div>
               </div>
             </div>

          </div>
        </div>
      </div>

      <div class="column-cell-wrapper">
        <div v-for="time in timeView" class="time-cell" :style="{ height: cellHeight + 'px' }"></div>
      </div>

    </div>
    <!-- Popup modal (same as monthly)-->
    <transition name="top-fade">
      <div v-if="isSelected" class="cell-details-container">
        <div class="cell-details-overlay" @click="closePopup"></div>
        <div class="cell-details-card" ref="cell-details-card">
          <span class="card-date-text" :class="holidayClass">{{ japanOplusDateFormat }}</span>
          <div class="calendar-slots-container">
            <div v-if="hasSlots" :class="disabledPopup">
              <div class="calendar-slots flex-column-reverse">
                <ShiftSlot v-for="id in slots.slice().reverse()"
                  :key="id"
                  :slot="slotMap[id]"
                  :repeat-date="slotMap[id].Repeat ? new Date(cellProps.date) : null"
                  :date="date"
                  :branch-settings="branchSettings"
                  :me-store="meStore"
                />
              </div>
            </div>
            <div v-else>
              <span class="empty-slot-text">シフトがありません</span>
            </div>
          </div>
          <div v-if="!isStaffLocked" class="add-slot-button-wrapper edit">
            <div v-if="!isCellCannotAddSlot" class="selected-template-add">
              <button class="btn btn-slot-add-primary" :class="disabledBtnClass" :data-tooltip="disabledText" @click="handleAddSlotClick($event)">
                <i class="icon icon-plus"></i>
              </button>
            </div>
            <div v-else>
              <i v-if="isCellLocked" class="zmdi zmdi-lock zmdi-hc-lg text-gray"></i>
              <i v-else class="zmdi zmdi-close zmdi-hc-lg text-gray"></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>

<style scoped>
  .add-disabled.tooltip::after {
    width: 150px;
    white-space: break-spaces;
  }
  .btn-slot-add-primary.add-disabled {
    border-color: #BBBDBF;
    color: #BBBDBF;
    cursor: not-allowed;
  }
  .btn-slot-add-primary.add-disabled:hover {
    border-color: #fff;
    color: #fff;
  }

  .btn-slot-add-primary.add-disabled:hover {
    background-color: #BBBDBF;
  }
  .weekly-calendar-column {
    position: relative;
    overflow: hidden;
  }
  .weekly-slots,
  .weekly-sections,
  .weekly-slot-container,
  .weekly-section-container {
    height: 100%;
    width: 100%;
  }

  .weekly-sections {
    overflow: hidden;
  }

  .weekly-slots {
    display: flex;
    position: relative;
    z-index: 1;
    padding: 1.5px 0;
    width: 97%;
    margin: auto;
  }

  .weekly-section-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .weekly-sections {
    position: relative;
    display: flex;
    z-index: 2;
  }

  .weekly-slot-container,
  .weekly-section-container {
    position: absolute;
    overflow: visible;
  }

  .weekly-slot-container.is-overlapped,
  .weekly-section-container.is-overlapped {
    position: relative;
  }

  .weekly-slot-wrapper,
  .weekly-section-wrapper {
    position: absolute;
    width: 100%;
  }

  .weekly-section-wrapper {
    padding: 0 .2rem;
    overflow: hidden;
  }

  .weekly-shift-cell {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    position: relative;
  }

  .weekly-day-cell {
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fff;
  }

  .hour-cell {
    justify-content: left;
    align-items: flex-start;
    gap: 5px;
    height: 60px;
  }

  .cell-details-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .column-cell-wrapper {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .weekly-locked-cell {
    height: 100%;
    width: 100%;
    padding: 3px;
    background-color: #e0e0e09c;
    border-radius: 5px;
    z-index: 2;
    position: absolute;
  }

  .weekly-cell-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 4;
    border-bottom: .05rem solid #e0e0e0;
  }
  .calendar-header-cell {
    border-left: .05rem solid #e0e0e0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .weekly-cell-add-button {
    height: 22px;
  }
  .disabled-popup {
    pointer-events: none;
    opacity: .7;
  }
</style>
