<script>
import { OPLUS_COLOR_PALETTE } from "../../js/models";
import GroupRoleIcon from "../icons/GroupRoleIcon.vue";
import { isSlotHourlyType, isSlotGroupRole } from "../../js/slot_helpers";
import { SLOT_TYPES } from "../../js/models";
import RequestedShiftIcon from "../icons/RequestedShiftIcon.vue";
import SlotBadges from "./SlotBadges.vue";
import { parseOplusTimeRaw } from "../../legacy/parser/shiftParser";
import { makeTableDate } from "../../table/v1/table";
import { fromOplusTime } from "../../common/common";
import periodTypes from "../../table/v1/periodTypes";
import timeZones from "../../common/timeZones";
import { shiftName } from "../../view/shift/shift";
import { MeStore } from "../../store/v1/meStore";

export default {
  props: {
    slot: Object,
    repeatDate: Date,
    date: String,
    branchSettings: Object,
    meStore: MeStore
  },
  // Note: Slot.vue, ShiftSlot.vue and ShiftWeeklySlot.vue (this component) shares all of these computed properties,
  // Todo: Refactor so that we won't be having same functions in different places
  computed: {
    slotName() {
      if (this.slot.Type === 4) {
        return "休憩"
      }
      const shift = parseOplusTimeRaw(this.slot);
      const tz = timeZones.ASIA_TOKYO
      const date = makeTableDate(fromOplusTime(new Date(this.date)), periodTypes.DAY, tz);
      return shiftName(shift, this.branchSettings, date.getDate(), date.getEnd(), tz);
    },
    slotComputedStyle() {
      const noColor = !this.slot.Color || this.slot.Color === OPLUS_COLOR_PALETTE.DEFAULT;
      return {
        backgroundColor: noColor ? "" : `#${this.slot.Color}`,
      }
    },
    hasRequestSlot() {
      return this.slot.RequestedSlotID && this.slot.RequestedSlot;
    },
    hasLinkPrev() {
      return this.slot.LinkPrev ? true : false;
    },
    hasLinkNext() {
      return this.slot.LinkNext ? true : false;
    },
    companyName() {
        return this.isClone ? this.slot.CompanyName : "";
    },
    isHelp() {
      return this.slot.IsHelp;
    },
    isAuto() {
      return this.slot.IsAuto;
    },
    isKOTSynced() {
      return this.slot.KOTSynced;
    },
    isTypeHourly() {
      return isSlotHourlyType(this.slot)
    },
    isTypeOne() {
      return this.slot.Type === 1;
    },
    isDefaultTextTypeTwo() {
      return this.isTypeTwo && this.slotName === '×';
    },
    isTypeTwo() {
      return this.slot.Type === 2;
    },
    isHolidayType() {
      return this.slot.Type > 1;
    },
    isRepeat() {
      return this.slot.Repeat;
    },
    numComments() {
      return this.slot.NumComments;
    },
    rejectedAsk() {
      return this.slot.AskStatus === 3;
    },
    isAsked() {
      return this.slot.AskStatus > 0 && !this.isLocked;
    },
    isClone() {
      return this.slot.CompanyID != this.meStore.getActiveBranchId();
    },
    isLocked() {
      if (this.isRepeat) {
        return this.isRepeatLocked;
      }

      return this.slot.Locked;
    },
    isRole() {
      if (this.slot.RoleID) {
          return true
      }
      return false
    },
    isGroupRole() {
      if (!this.isRole) {
        return false
      }
      return isSlotGroupRole(this.slot);
    },
    isRepeatLocked() {
      if (!this.isRepeat) {
          return false;
      }
      const repeatDateZeroed = new Date(this.repeatDate).setUTCHours(0, 0, 0, 0);
      const ind = this.slot.RepeatLocked?.findIndex(d => new Date(d).setUTCHours(0, 0, 0 ,0) === repeatDateZeroed);
      if (ind >= 0) {
          return true;
      }

      return false;
    },
    isSection() {
      return Boolean(this.slot?.SectionParent)
    },
    computeChipStyle() {
      let bg = this.slot.Color ? `#${this.slot.Color}` : '#B0ADAC40';
      return {
        'backgroundColor': bg,
        'cursor': this.isClone ? 'default' : 'pointer',
      };
    },
    lockStyle() {
      return {
        'color': this.isKOTSynced ? '#1E9038' : '',
      }
    },
    borderStyle() {
      if (!this.useShiftBorders) {
        return {};
      }
      return {
        'outline': !this.isLocked ? '1.5px dashed #646565' : '',
        'border': !this.isLocked ? 'none' : '',
      }
    },
    chipClass() {
      return {
        'clone': this.isClone,
        'text-light': this.slot.Color,
      }
    },
    slotNameClass() {
      return {
        'text-success': this.isTypeOne,
        'zmdi-hc-lg': this.isDefaultTextTypeTwo,
        'zmdi': this.isTypeTwo && !this.isRole,
        'text-error': this.isHolidayType,
        'text-ellipsis': !this.isSection,
        'weekly-section-text': this.isSection,
      }
    },
    requestSlotNameClass() {
      return {
        'zmdi zmdi-hc-lg': this.isRequestDefaultTextTypeTwo
      }
    },
    isRequestSlotTypeTwo() {
      return this.slot?.RequestedSlot.Type === SLOT_TYPES.OFF_DAY;
    },
    isRequestDefaultTextTypeTwo() {
      return this.isRequestSlotTypeTwo && isSlotTypeXOffDayString(this.slot.RequestedSlot.Text);
    },
    numUnreadComments() {
      if (this.slotLogMap) {
        const slotLogs = this.slotLogMap[this.slot.ID];
        return slotLogs ? slotLogs.length : 0;
      }
      return 0;
    },
  },
  inject: [
    'slotMap',
    'slotLogMap',
    'useShiftBorders', // Browser setting for enabling dashed borders for unconfirmed shifts
  ],
  components: {
    GroupRoleIcon,
    RequestedShiftIcon,
    SlotBadges,
  },
  mounted() {
    this.$emit('emit-slot-id', this.slot.ID);
  },
  updated() {
    this.$emit('emit-slot-id', this.slot.ID);
  },
}
</script>
<template>
  <div ref="weekly-slot" class="weekly-slot" :class="chipClass" :style="[borderStyle, lockStyle, slotComputedStyle]">

    <SlotBadges
      :numReadComments="numComments"
      :numUnreadComments="numUnreadComments"
      :isClone="isClone"
      :shouldShowAlert="rejectedAsk"
      alertDescription="要請が承認されませんでした"
      :isAsked="isAsked"
      :tooltipBottom="true"
    />

    <i v-if="hasLinkPrev" class="icon icon-link"></i>
    <div class="weekly-slot-content">
      <div class="weekly-slot-label">
        <span :class="slotNameClass">{{ slotName }}</span>
      </div>
      <RequestedShiftIcon v-if="hasRequestSlot"/>
      <GroupRoleIcon v-if="isGroupRole"/>
      <i v-if="isHelp" class="zmdi zmdi-assignment-return"></i>
      <small v-if="companyName">{{ companyName }}</small>
      <i v-if="isLocked && !isKOTSynced && !useShiftBorders" class="icon icon-check"></i>
      <i v-if="isLocked && isKOTSynced" class="icon icon-check" :style="lockStyle"></i>
      <span v-if="isRepeat">∞</span>
      <i v-if="hasLinkNext" class="link-next-icon icon icon-link"></i>
    </div>
  </div>
</template>
<style scoped>
  .weekly-slot {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
    position: relative;
    border-radius: 5px;
    height: 100%;
    background-color: #eef0f3;
    border: .05rem solid #fff;
    overflow: hidden;
    font-size: 14px;
    padding: 5px;
    gap: 3px;
    cursor: pointer;
    overflow: visible;
  }

  .weekly-section-text {
    overflow: hidden;
  }

  .weekly-slot-label {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 3px
  }

  .weekly-slot-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3px;
    width: 100%;
  }

  .weekly-comment-icon {
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
  }

  .link-next-icon {
    position: absolute;
    bottom: 5px;
  }
</style>
