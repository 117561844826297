<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 28 20" style="enable-background:new 0 0 22 22;" xml:space="preserve">
  <path fill="#656465" d="M11.14,14.73c-2,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62c2,0,3.62,1.62,3.62,3.62
  S13.13,14.73,11.14,14.73z M11.14,8.39c-1.5,0-2.72,1.22-2.72,2.72s1.22,2.72,2.72,2.72c1.5,0,2.72-1.22,2.72-2.72
  S12.64,8.39,11.14,8.39z"/>
  <path fill="#D61518" d="M3.74,8.63H1.69V7.64h5.25v0.99H4.88v5.92H3.74V8.63z M17.42,8.63h-2.06V7.64h5.25v0.99h-2.05v5.92h-1.14V8.63
  z M11.44,8.84h-0.6v1.99h-1.5v0.6h2.1V8.84z"/>
</svg>
</template>
