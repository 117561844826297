<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 72 22" height="35px" style="enable-background:new 0 0 72 22;" xml:space="preserve">
    <path fill="#0047BB" class="" d="M15.83,18.31H5.17c-2.4,0-4.5-2.21-4.5-4.74V13c0-2.52,2.1-4.74,4.5-4.74h0.56C5.89,7.81,6.15,7.22,6.5,6.75
		C7.16,5.87,8.18,5.4,8.29,5.34c0.45-0.2,0.99,0,1.19,0.45c0.2,0.45,0,0.99-0.45,1.19C8.84,7.07,8.27,7.4,7.94,7.83
		C7.59,8.3,7.33,9.11,7.26,9.38c-0.1,0.4-0.46,0.68-0.87,0.68H5.17c-1.41,0-2.7,1.4-2.7,2.94v0.58c0,1.54,1.29,2.94,2.7,2.94h10.65
		c2.62,0,3.6-2.35,3.6-3.84V11.8c0-1.31-0.72-2.35-2.34-3.38c-0.26-0.16-0.42-0.45-0.42-0.76V6.47h-3.96v1.44c0,0.5-0.4,0.9-0.9,0.9
		c-0.5,0-0.9-0.4-0.9-0.9V4.69c0-0.5,0.4-0.9,0.9-0.9h5.76c0.5,0,0.9,0.4,0.9,0.9v2.49c1.85,1.29,2.76,2.81,2.76,4.62v0.88
		C21.23,15.02,19.55,18.31,15.83,18.31z M16.86,13.56l-0.72-0.83c0.07-0.16,0.1-0.33,0.1-0.51c0-0.53-0.3-0.98-0.74-1.2V8.6
		c0-0.33-0.27-0.6-0.6-0.6c-0.33,0-0.6,0.27-0.6,0.6v2.42c-0.44,0.22-0.74,0.68-0.74,1.2c0,0.74,0.6,1.34,1.34,1.34
		c0.12,0,0.23-0.02,0.33-0.04l0.72,0.83c0.12,0.14,0.29,0.21,0.45,0.21c0.14,0,0.28-0.05,0.4-0.15
		C17.05,14.19,17.07,13.81,16.86,13.56z M26.61,6.44h1.82v0.71c0,0.26,0.21,0.39,0.53,0.39c0.32,0,0.53-0.13,0.53-0.39V6.44h1.52
		c0.24,0,0.39-0.2,0.39-0.47c0-0.29-0.15-0.48-0.39-0.48h-1.52V4.64c0-0.26-0.21-0.39-0.53-0.39c-0.32,0-0.53,0.13-0.53,0.39v0.86
		h-1.82V4.64c0-0.26-0.2-0.39-0.51-0.39c-0.32,0-0.54,0.13-0.54,0.39v0.86h-1.64c-0.24,0-0.38,0.2-0.38,0.48
		c0,0.27,0.14,0.47,0.38,0.47h1.64v0.71c0,0.26,0.23,0.39,0.54,0.39c0.32,0,0.51-0.13,0.51-0.39V6.44z M26.94,11.06v1.02h-2.73
		c-0.24,0-0.36,0.18-0.36,0.44c0,0.26,0.12,0.47,0.36,0.47h2.73v1.05H24.6c-0.25,0-0.38,0.17-0.38,0.44c0,0.24,0.12,0.47,0.38,0.47
		h2.34v1.29c-0.98,0.13-1.95,0.23-2.82,0.29c-0.29,0.02-0.47,0.17-0.47,0.45c0,0.03,0,0.08,0,0.1c0.03,0.35,0.23,0.5,0.51,0.5
		c0.03,0,0.06,0,0.08,0c1.86-0.15,4.37-0.56,6.12-0.98c0.26-0.06,0.36-0.23,0.36-0.44c0-0.05,0-0.09-0.01-0.12
		c-0.03-0.24-0.18-0.36-0.38-0.36c-0.03,0-0.06,0-0.11,0.01c-0.69,0.15-1.46,0.29-2.25,0.41v-1.16h2.25c0.24,0,0.38-0.23,0.38-0.47
		c0-0.27-0.14-0.44-0.38-0.44h-2.25v-1.05h2.64c0.24,0,0.36-0.21,0.36-0.47c0-0.27-0.12-0.44-0.36-0.44h-2.64v-1.02h1.88
		c0.77,0,1.11-0.29,1.11-1.04V8.78c0-0.74-0.35-1.02-1.11-1.02h-4.71c-0.77,0-1.11,0.29-1.11,1.02v1.25c0,0.75,0.35,1.04,1.11,1.04
		H26.94z M26.94,8.63v1.58h-1.5c-0.26,0-0.39-0.08-0.39-0.33v-0.9c0-0.27,0.13-0.35,0.39-0.35H26.94z M27.97,10.21V8.63h1.61
		c0.26,0,0.39,0.08,0.39,0.35v0.9c0,0.26-0.14,0.33-0.39,0.33H27.97z M31.73,7.51c-0.25,0-0.41,0.21-0.41,0.53
		c0,0.32,0.15,0.53,0.41,0.53h1.16c-0.11,1.71-0.29,3.09-0.59,4.2c-0.45,1.71-1.17,3.23-2.09,4.25c-0.12,0.13-0.18,0.29-0.18,0.42
		c0,0.17,0.08,0.33,0.23,0.45c0.12,0.09,0.26,0.14,0.39,0.14c0.18,0,0.36-0.09,0.5-0.26c1.01-1.26,1.74-2.9,2.21-4.67
		c0.33-1.2,0.54-2.72,0.66-4.53h1.5c0.32,0,0.47,0.08,0.47,0.42c0,3.06-0.2,5.61-0.54,7.1c-0.11,0.5-0.35,0.68-0.78,0.68
		c-0.35,0-0.72-0.06-1.05-0.17c-0.03-0.01-0.06-0.01-0.09-0.01c-0.2,0-0.38,0.15-0.45,0.44c-0.02,0.06-0.03,0.13-0.03,0.2
		c0,0.24,0.13,0.45,0.35,0.51c0.42,0.12,0.95,0.16,1.44,0.16c0.96,0,1.49-0.38,1.7-1.38c0.36-1.59,0.59-4.23,0.6-7.71
		c0-0.9-0.41-1.28-1.32-1.28h-1.73c0.04-0.84,0.06-1.74,0.06-2.69c0-0.27-0.21-0.42-0.57-0.42c-0.33,0-0.57,0.13-0.57,0.42
		c0,0.98-0.02,1.86-0.05,2.69H31.73z M46.52,8.68v0.96h-3.59c-0.78,0-1.17,0.32-1.17,1.1v1.65c0,0.78,0.39,1.11,1.17,1.11h3.59v1.22
		h-5.61c-0.27,0-0.42,0.2-0.42,0.53c0,0.32,0.15,0.53,0.42,0.53h5.61v2.04c0,0.27,0.23,0.44,0.56,0.44c0.33,0,0.54-0.16,0.54-0.44
		v-2.04h5.87c0.27,0,0.44-0.21,0.44-0.53c0-0.33-0.17-0.53-0.44-0.53h-5.87v-1.22h3.84c0.78,0,1.17-0.33,1.17-1.13v-1.62
		c0-0.8-0.39-1.11-1.17-1.11h-3.84V8.68h1.71c0.77,0,1.16-0.29,1.16-1.05V6.55h3.06c0.27,0,0.44-0.21,0.44-0.51
		c0-0.32-0.16-0.51-0.44-0.51h-3.06V4.59c0-0.26-0.23-0.41-0.54-0.41c-0.35,0-0.57,0.15-0.57,0.41v0.93h-4.52V4.59
		c0-0.26-0.21-0.41-0.56-0.41c-0.33,0-0.56,0.15-0.56,0.41v0.93h-2.91c-0.27,0-0.42,0.2-0.42,0.51c0,0.3,0.15,0.51,0.42,0.51h2.91
		v1.08c0,0.77,0.41,1.05,1.16,1.05H46.52z M46.52,12.55h-3.23c-0.27,0-0.42-0.09-0.42-0.38v-1.22c0-0.29,0.15-0.38,0.42-0.38h3.23
		V12.55z M44.86,6.55h4.52v0.89c0,0.27-0.14,0.35-0.41,0.35h-3.72c-0.26,0-0.39-0.08-0.39-0.35V6.55z M47.62,12.55v-1.97h3.48
		c0.27,0,0.42,0.09,0.42,0.38v1.22c0,0.29-0.15,0.38-0.42,0.38H47.62z M61.53,6.22c0-0.86-0.41-1.2-1.22-1.2h-1.64
		c-0.84,0-1.25,0.35-1.25,1.2v9.17c0,0.86,0.41,1.2,1.25,1.2h1.64c0.81,0,1.22-0.35,1.22-1.2V6.22z M58.52,10.03v-3.6
		c0-0.32,0.14-0.39,0.44-0.39h1.05c0.29,0,0.42,0.08,0.42,0.39v3.6H58.52z M60.43,11.03v4.11c0,0.33-0.13,0.39-0.42,0.39h-1.05
		c-0.3,0-0.44-0.06-0.44-0.39v-4.11H60.43z M65.67,8.84h-3.33c-0.29,0-0.44,0.21-0.44,0.51c0,0.3,0.15,0.53,0.44,0.53h5.52v1.68
		h-5.51c-0.29,0-0.45,0.21-0.45,0.51c0,0.32,0.17,0.53,0.45,0.53h5.51v3.68c0,0.54-0.14,0.66-0.69,0.66c-0.33,0-0.78-0.03-1.14-0.12
		c-0.04-0.02-0.09-0.02-0.14-0.02c-0.23,0-0.39,0.15-0.45,0.44c-0.01,0.06-0.01,0.12-0.01,0.17c0,0.26,0.12,0.44,0.41,0.5
		c0.41,0.09,0.87,0.13,1.47,0.13c1.11,0,1.65-0.39,1.65-1.41v-4.02h1.52c0.27,0,0.42-0.21,0.42-0.53c0-0.3-0.15-0.51-0.42-0.51
		h-1.52V9.88h1.55c0.26,0,0.42-0.23,0.42-0.54c0-0.3-0.17-0.5-0.42-0.5h-3.72V6.98h3.14c0.27,0,0.41-0.21,0.41-0.53
		c0-0.29-0.13-0.5-0.41-0.5h-3.14V4.67c0-0.29-0.23-0.44-0.57-0.44c-0.33,0-0.54,0.15-0.54,0.44v1.29h-2.79
		c-0.29,0-0.44,0.23-0.44,0.51c0,0.3,0.15,0.51,0.44,0.51h2.79V8.84z M64.18,13.37c-0.15-0.14-0.29-0.2-0.44-0.2
		c-0.13,0-0.26,0.04-0.39,0.15c-0.15,0.14-0.24,0.29-0.24,0.45c0,0.12,0.06,0.25,0.18,0.38c0.45,0.47,1.04,1.14,1.44,1.73
		c0.12,0.18,0.3,0.27,0.48,0.27c0.15,0,0.29-0.06,0.41-0.17c0.15-0.13,0.24-0.27,0.24-0.44c0-0.13-0.05-0.27-0.17-0.44
		C65.28,14.53,64.68,13.82,64.18,13.37z"/>
 </svg>
</template>
