<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="30px" viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;"
    xml:space="preserve">
    <path fill="currentColor" d="M9.284,17.704c-4.397,0-7.975-3.578-7.975-7.976c0-1.682,0.516-3.29,1.493-4.65
    c0.15-0.211,0.442-0.259,0.653-0.108c0.21,0.151,0.258,0.444,0.107,0.654c-0.862,1.2-1.318,2.62-1.318,4.104
    c0,3.882,3.158,7.04,7.039,7.04s7.038-3.158,7.038-7.04c0-3.724-2.906-6.782-6.57-7.023v2.345c0,0.259-0.209,0.468-0.468,0.468
    S8.816,5.308,8.816,5.049V2.221c0-0.259,0.209-0.468,0.468-0.468c4.397,0,7.975,3.578,7.975,7.976
    C17.259,14.126,13.682,17.704,9.284,17.704z"/>
    <path fill="currentColor" d="M9.284,10.196c-0.125,0-0.25-0.05-0.342-0.148L5.958,6.86C5.782,6.671,5.792,6.375,5.98,6.198
    C6.17,6.023,6.465,6.031,6.643,6.22l2.984,3.188c0.176,0.189,0.167,0.485-0.022,0.662C9.514,10.154,9.399,10.196,9.284,10.196z"/>
  </svg>
</template>
