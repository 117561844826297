<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="30px"  viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;"
    xml:space="preserve">
    <path fill="currentColor" d="M14.988,18.834H4.072c-0.758,0-1.375-0.617-1.375-1.375V6.542c0-0.758,0.617-1.375,1.375-1.375h10.917
    c0.758,0,1.375,0.617,1.375,1.375v10.917C16.363,18.217,15.747,18.834,14.988,18.834z M4.072,5.917
    c-0.345,0-0.625,0.28-0.625,0.625v10.917c0,0.345,0.28,0.625,0.625,0.625h10.917c0.345,0,0.625-0.28,0.625-0.625V6.542
    c0-0.345-0.28-0.625-0.625-0.625H4.072z"/>
    <path fill="currentColor" d="M13.871,4.646H5.195v-3.97h2.846l0.096,0.23C8.375,1.474,8.93,1.841,9.55,1.841
    c0.618,0,1.178-0.369,1.426-0.94l0.098-0.225h2.796V4.646z M5.945,3.896h7.176v-2.47h-1.57c-0.408,0.715-1.169,1.165-2.001,1.165
    c-0.837,0-1.594-0.45-1.993-1.165H5.945V3.896z"/>
    <path fill="currentColor" d="M9.553,17.092c-2.812,0-5.101-2.289-5.101-5.102c0-2.812,2.288-5.101,5.101-5.101
    c2.813,0,5.101,2.288,5.101,5.101C14.654,14.803,12.366,17.092,9.553,17.092z M9.553,7.64c-2.399,0-4.351,1.952-4.351,4.351
    c0,2.399,1.952,4.352,4.351,4.352c2.399,0,4.351-1.952,4.351-4.352C13.904,9.591,11.953,7.64,9.553,7.64z"/>
    <path fill="currentColor" d="M11.84,12.366H9.55c-0.207,0-0.375-0.168-0.375-0.375V8.922c0-0.207,0.168-0.375,0.375-0.375
    s0.375,0.168,0.375,0.375v2.694h1.916c0.207,0,0.375,0.168,0.375,0.375S12.047,12.366,11.84,12.366z"/>
  </svg>
</template>
