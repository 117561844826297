"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.branchControllerInjectKey = exports.proUpgradePresenterInjectKey = exports.proUpgradeViewModelInjectKey = exports.proPaymentPresenterInjectKey = exports.proPaymentViewModelInjectKey = exports.chatSettingsViewModelInjectKey = exports.chatSettingsControllerInjectKey = void 0;
exports.chatSettingsControllerInjectKey = Symbol('chatSettingsController');
exports.chatSettingsViewModelInjectKey = Symbol('chatSettingsPageViewModel');
exports.proPaymentViewModelInjectKey = Symbol('proPaymentViewModel');
exports.proPaymentPresenterInjectKey = Symbol('proPaymentPresenter');
exports.proUpgradeViewModelInjectKey = Symbol('proUpgradeViewModel');
exports.proUpgradePresenterInjectKey = Symbol('proUpgradePresenter');
exports.branchControllerInjectKey = Symbol('branchController');
