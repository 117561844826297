<template>
  <svg version="1.1" class="smartHR-logo-small" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 40 22" style="enable-background:new 0 0 22 22;" xml:space="preserve">
    <path fill="#00C4CC" d="M15,13.5c-0.1,1-0.7,2-1.6,2.6c-0.7,0.5-1.6,0.8-2.5,0.8c-0.2,0-0.4,0-0.5,0c-1.7-0.2-3-1.4-3.4-3
    c-0.1-0.4,0.2-0.8,0.6-0.9c0.4,0,0.9,0.2,0.9,0.6c0.2,1,1.1,1.7,2.1,1.9c0.7,0.1,1.3-0.1,1.9-0.5c0.6-0.4,0.9-1,1-1.6
    c0.2-1.1-0.5-2.2-1.5-2.6c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.5,1-0.4C14.3,10.1,15.3,11.8,15,13.5z M15,7.8
    c-0.6-1.5-2.1-2.5-3.9-2.5C8.8,5.3,7,7.1,7,9.3c0,1.8,1.2,3.4,3,3.8c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5
    c0.1-0.4-0.1-0.8-0.5-0.9c-1.1-0.3-1.9-1.3-1.9-2.5c0-1.4,1.2-2.5,2.6-2.5c1.1,0,2,0.6,2.4,1.6c0.2,0.4,0.6,0.5,1,0.4
    C15,8.5,15.2,8.1,15,7.8z M21.5,7.3v7.3c0,3.5-3,6.3-6.6,6.3H7.2c-3.7,0-6.7-2.8-6.7-6.3V7.3c0-3.5,3-6.3,6.7-6.3h7.7
    C18.5,1,21.5,3.9,21.5,7.3z M20,7.6c0-2.7-2.3-5.1-5.1-5.1H7.2C4.3,2.5,2,4.6,2,7.3v7.3c0,2.7,2.3,4.9,5.1,4.9h7.7
    c2.8,0,5.2-2,5.2-4.7V7.6z"/>
  </svg>
</template>
