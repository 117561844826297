<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 22 22" height="55px" style="enable-background:new 0 0 22 22;" xml:space="preserve">
  <path fill="#0075C9"  d="M11.43,8.95c0,0.52-0.42,0.95-0.95,0.95S9.53,9.48,9.53,8.95s0.42-0.95,0.95-0.95S11.43,8.43,11.43,8.95z
  M13.68,6.16c0.09-0.38-3.09-1.7-5.21,0.36c-1.82,1.77-1.6,5.03-1.31,6.57c0.4,2.13,2.18,4.66,2.59,4.49s-0.44-1.54-0.65-2.89
  c-0.11-0.7-0.1-1.98,0.13-1.87s0.65,1.08,2.02,2.23c1.43,1.21,3.31,1.68,3.44,1.34c0.09-0.23-1.52-1.4-2.39-2.28
  c-1.32-1.34-1.31-2.05-1.24-2.17c0.08-0.14,1.03,0.06,2.16-0.46c0.88-0.41,1.53-0.74,1.85-2.21c0.39-1.81-0.74-2.79-1.05-2.76
  c-0.3,0.03,0.33,0.81,0.15,1.77c-0.19,1.04-1.11,1.81-1.85,2.06c-1.15,0.39-2.79,0.18-3.24-0.71C8.72,8.86,9.4,7.29,10.44,6.6
  C11.64,5.81,13.59,6.53,13.68,6.16z M20.62,11.1c0-5.27-4.29-9.56-9.56-9.56c-5.27,0-9.56,4.29-9.56,9.56
  c0,5.27,4.29,9.56,9.56,9.56C16.33,20.66,20.62,16.38,20.62,11.1z M19.77,11.1c0,4.8-3.91,8.71-8.71,8.71
  c-4.8,0-8.71-3.91-8.71-8.71c0-4.8,3.91-8.71,8.71-8.71C15.86,2.4,19.77,6.3,19.77,11.1z"/>
 </svg>
</template>
