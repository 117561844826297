"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.patchChatSettings = exports.getChatSettings = void 0;
const webappApi_1 = require("./webappApi");
const route = "group_chat_settings";
async function getChatSettings(id) {
    const res = await (0, webappApi_1.makeGetRequest)(`${route}/${id}`);
    const data = await res.json();
    const out = {
        id: data.id,
        blockStaffRoomCreation: data.blockStaffRoomCreation,
    };
    return out;
}
exports.getChatSettings = getChatSettings;
async function patchChatSettings(id, body) {
    const res = await (0, webappApi_1.makePatchRequest)(`${route}/${id}`, JSON.stringify(body));
    const data = await res.json();
    const out = {
        id: data.id,
        blockStaffRoomCreation: data.blockStaffRoomCreation,
    };
    return out;
}
exports.patchChatSettings = patchChatSettings;
