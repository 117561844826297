"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingsControllerImpl = void 0;
class ChatSettingsControllerImpl {
    subject;
    webAppApi;
    constructor(subject, webAppApi) {
        this.subject = subject;
        this.webAppApi = webAppApi;
    }
    async getChatSettings(id) {
        const res = await this.webAppApi.getChatSettings(id);
        this.subject.set(res);
    }
    async patchChatSettings(id, body) {
        const res = await this.webAppApi.patchChatSettings(id, body);
        this.subject.set(res);
    }
}
exports.ChatSettingsControllerImpl = ChatSettingsControllerImpl;
