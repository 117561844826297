"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingsWebAppApiImpl = void 0;
const groupChatSettings_1 = require("./v2/groupChatSettings");
class ChatSettingsWebAppApiImpl {
    async getChatSettings(id) {
        return await (0, groupChatSettings_1.getChatSettings)(id);
    }
    async patchChatSettings(id, body) {
        return await (0, groupChatSettings_1.patchChatSettings)(id, body);
    }
}
exports.ChatSettingsWebAppApiImpl = ChatSettingsWebAppApiImpl;
