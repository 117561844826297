import Settings from "../vue/components/Settings.vue"
import { createApp } from "vue";
import { fullWidthToAscii } from '../common/common'
import { MeStore } from "../store/v1/meStore";
import { User } from "../model/v1/user";
import { Branch } from "../model/v1/branch";
import { BranchSettings } from "../model/v1/branchSettings";
import timeZones from "../common/timeZones.ts"
import { parseFromRaw as ptoConfigParser } from '../pto/parser/ptoConfigParser'
import { GroupSettings } from "../model/v1/groupSettings";
import { Group } from "../model/v1/group";
import { ChatSettingsApp } from "../app/chatSettingsImpl";

const branchSettings = new BranchSettings({
  timeZone: timeZones.ASIA_TOKYO
})

export function initPage(props) {
  try {
    if (!props?.activeGroup) {
      throw new Error("No active group")
    }
    const ptoConfig = initPtoConfig(props);
    const activeBranch = initActiveBranch(props);
    const activeGroup = initActiveGroup(props, ptoConfig)
    const meStore = initMeStore(props);
    const isDefaultSettingsPage = props.isDefaultSettingsPage
    const permissions = props.permissions

    if (isDefaultSettingsPage) {
      mountGroupSettingsApp({meStore, activeBranch, activeGroup, isDefaultSettingsPage, permissions});
      return;
    }

    mountBranchSettingsApp({meStore, activeBranch, activeGroup, isDefaultSettingsPage, permissions});
  } catch (err) {
    alertModal.alert(err);
  }
}

function mountGroupSettingsApp(props) {
  const app = createApp(Settings, props);

  new ChatSettingsApp(app, props.activeGroup, props.permissions.group);

  app.mount("#settings-app")
}

function mountBranchSettingsApp(props) {
  const app = createApp(Settings, props);

  app.mount("#settings-app")
}

function initActiveGroup(props, ptoConfig) {
  const groupSettings = new GroupSettings({
    groupSettings: branchSettings,
    ptoConfig: ptoConfig,
    selectedOem: props.activeGroup.selectedOem
  })
  const groupReqParam = {
    id: props.activeGroup.id,
    name: props.activeGroup.name,
    address: props.activeGroup.address,
  }
  const groupOptParam = {
    groupSettings: groupSettings,
  }
  return new Group(groupReqParam, groupOptParam);
}

function initActiveBranch(props) {
  const branchReqParam = {
    id: props.activeBranch.ID,
    name: props.activeBranch.Name,
    settings: branchSettings,
  }
  const branchOptParam = {
    canEditSettings: props.branchCanEditSettings
  }
  return new Branch(branchReqParam, branchOptParam);
}

function initMeStore(props) {
  const userReqParam = {
    id: props.activeUserId,
  }
  const user = new User(userReqParam);
  const meStore = new MeStore({ user });
  meStore.setActiveBranchId(props.activeBranch.ID);
  meStore.setActiveGroupId(props.activeGroup.id);
  return meStore;
}

function initPtoConfig(props) {
  const raw = props.rawPtoConfig;

  return ptoConfigParser(raw);
}

// Note: this is copied function from dashboard_base file
export function getCSVEncoding() {
  return window.localStorage.getItem("encodingSelected") || "0";
}

export function setCSVEncoding(encodingInt = 0) {
  return window.localStorage.setItem("encodingSelected", encodingInt);
}

export function getShiftBordersSetting() {
  return window.localStorage.getItem("useShiftBorders") === "true";
}

export function setShiftBordersSetting(value) {
  return window.localStorage.setItem("useShiftBorders", value);
}

export function importAlertModal() {
  return alertModal;
}

export function convertInputValToInt(e) {
  const target = e.target
  const newVal = parseInt(fullWidthToAscii(target.value)) || 0;

  return newVal;
}
