<template>
  <svg version="1.1" class="kot-mob" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 65 25" style="enable-background:new 0 0 60 25;" xml:space="preserve">
    <path fill="#1D9E48" d="M10.01,20.71c-3.96,0-7.19-3.22-7.19-7.19c0-3.96,3.22-7.19,7.19-7.19c3.96,0,7.19,3.22,7.19,7.19
		C17.2,17.48,13.98,20.71,10.01,20.71z M10.01,7.45c-3.35,0-6.07,2.72-6.07,6.07s2.72,6.07,6.07,6.07c3.35,0,6.07-2.72,6.07-6.07
		S13.36,7.45,10.01,7.45z M38.4,20.03c-3.02,0-5.48-2.46-5.48-5.48c0-3.02,2.46-5.48,5.48-5.48s5.48,2.46,5.48,5.48
		S41.42,20.03,38.4,20.03z M38.4,11.09c-1.91,0-3.46,1.55-3.46,3.46s1.55,3.46,3.46,3.46c1.91,0,3.46-1.55,3.46-3.46
		S40.31,11.09,38.4,11.09z M32.27,19.67h-2.69c-0.23-3.27-2.96-5.87-6.3-5.87c-0.13,0-0.27,0-0.4,0.01v5.86h-2.69V6.03h2.69v5.07
		c2.95-0.12,5.38-2.26,5.94-5.07h2.73c-0.36,2.58-1.81,4.82-3.88,6.22C30.3,13.73,32.12,16.49,32.27,19.67z M56.65,5.93v2.69h-4.46
		v11.05H49.5V8.62h-4.45V5.93H56.65z M40.35,8.69h-0.97h-0.97h-0.97h-0.97V8.05V7.42V6.78V6.14l0.97,0.86l0.97-0.86l0.97,0.86
		l0.97-0.86v0.64v0.64v0.63V8.69z M8.07,9.74c0.37,0,0.67,0.4,0.67,0.9s-0.3,0.9-0.67,0.9c-0.37,0-0.67-0.4-0.67-0.9
		S7.7,9.74,8.07,9.74z M11.92,9.74c0.37,0,0.67,0.4,0.67,0.9s-0.3,0.9-0.67,0.9s-0.67-0.4-0.67-0.9S11.55,9.74,11.92,9.74z"/>
	<path fill="#F7901E" d="M11.92,5.89h-0.97H9.98H9.01H8.04V5.25V4.61V3.98V3.34l0.97,0.86l0.97-0.86l0.97,0.86l0.97-0.86v0.64v0.64v0.64V5.89z
		 M9.4,13.64L8.4,14.65l-1.01,1.01l-1.24-1.24l-0.97-0.97l-0.48,0.48l-0.1-1.83l1.84,0.1l-0.47,0.47l1.41,1.41L8,13.46l0.61-0.61
		l0.39,0.4L9.4,13.64z M10.6,13.64l1.01,1.01l1.01,1.01l1.23-1.24l0.97-0.97l0.48,0.48l0.1-1.83l-1.83,0.1l0.47,0.47l-1.41,1.41
		L12,13.46l-0.61-0.61L11,13.25L10.6,13.64z M42.28,11.09v-0.93h-0.06c-0.81,0-1.61,0.41-2.25,1.14c-0.51,0.59-0.91,1.53-1.06,2.42
		c0.28,0.17,0.47,0.47,0.47,0.83c0,0.53-0.43,0.96-0.96,0.96c-0.53,0-0.96-0.43-0.96-0.96c0-0.41,0.26-0.76,0.61-0.89
		c0.16-1.06,0.64-2.17,1.26-2.89c0.8-0.92,1.82-1.43,2.88-1.43h0.06V9.01l0-0.77l1.59,1.42L42.28,11.09z"/>
 </svg>
</template>
