<template>
<svg version="1.1" class="kot-mob" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 65 25" style="enable-background:new 0 0 60 25;" xml:space="preserve">
  <path fill="#00A040" d="M41.62,6.22c0-1.29-0.88-2.37-2.06-2.67c-0.21-0.05-0.43-0.08-0.66-0.08h-6.11v18.19h6.11
  c1.5,0,2.73-1.23,2.73-2.75v-4.71c0-0.65-0.23-1.25-0.61-1.72c0.38-0.47,0.61-1.07,0.61-1.72V6.22z M39.56,18.9
  c0,0.37-0.31,0.69-0.66,0.69h-3.39c-0.36,0-0.66-0.32-0.66-0.69v-5.4h4.05c0.36,0,0.66,0.32,0.66,0.69V18.9z M39.56,10.75
  c0,0.37-0.31,0.69-0.66,0.69h-3.39c-0.36,0-0.66-0.32-0.66-0.69V6.22c0-0.37,0.31-0.69,0.66-0.69h3.39c0.36,0,0.66,0.32,0.66,0.69
  V10.75z M45.4,21.79c-0.57,0-1.03-0.46-1.03-1.03V8.62c0-0.57,0.46-1.03,1.03-1.03s1.03,0.46,1.03,1.03v12.14
  C46.43,21.32,45.97,21.79,45.4,21.79z M55.95,21.29h-7.56l5.54-11.16h-4.52c-0.57,0-1.03-0.46-1.03-1.03s0.46-1.03,1.03-1.03h7.84
  l-5.54,11.16h4.24c0.57,0,1.03,0.46,1.03,1.03S56.52,21.29,55.95,21.29z"/>
  <path fill="#000" d="M13.06,8.28V6.22c0-1.52-1.23-2.75-2.75-2.75H6.97c-1.52,0-2.75,1.23-2.75,2.75V18.9
  c0,1.52,1.23,2.75,2.75,2.75h3.33c1.52,0,2.75-1.23,2.75-2.75v-2.06h-2.06v2.06c0,0.37-0.32,0.69-0.69,0.69H6.97
  c-0.37,0-0.69-0.31-0.69-0.69V6.22c0-0.37,0.31-0.69,0.69-0.69h3.33c0.37,0,0.69,0.31,0.69,0.69v2.06H13.06z M22.34,8.29V6.21
  c0-0.38-0.33-0.68-0.7-0.68h-3.33c-0.38,0-0.68,0.3-0.68,0.68v12.68c0,0.38,0.3,0.7,0.68,0.7h3.33c0.38,0,0.7-0.33,0.7-0.7v-2.06
  h2.06v2.06c0,1.53-1.23,2.76-2.76,2.76h-3.33c-1.5,0-2.76-1.23-2.76-2.76V6.21c0-1.5,1.25-2.73,2.76-2.73h3.33
  c1.53,0,2.76,1.23,2.76,2.73v2.08H22.34z M30.29,12.21c0,0.94-0.76,1.71-1.71,1.71c-0.94,0-1.71-0.76-1.71-1.71
  s0.76-1.71,1.71-1.71C29.53,10.5,30.29,11.27,30.29,12.21z M47.08,4.39c0,0.94-0.76,1.71-1.71,1.71s-1.71-0.76-1.71-1.71
  s0.76-1.71,1.71-1.71S47.08,3.45,47.08,4.39z"/>
  </svg>
</template>
