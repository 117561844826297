"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.rawPtoConfigInputs = exports.rawInputsFields = void 0;
exports.rawInputsFields = Object.freeze({
    onePerWeek: "onePerWeek",
    twoPerWeek: "twoPerWeek",
    threePerWeek: "threePerWeek",
    fourPerWeek: "fourPerWeek",
    fivePerWeek: "fivePerWeek",
});
exports.rawPtoConfigInputs = Object.freeze({
    onePerWeek: {
        half: "one-per-week-half",
        oneHalf: "one-per-week-one-half",
        twoHalf: "one-per-week-two-half",
        threeHalf: "one-per-week-three-half",
        fourHalf: "one-per-week-four-half",
        fiveHalf: "one-per-week-five-half",
        sixHalf: "one-per-week-six-half",
    },
    twoPerWeek: {
        half: "two-per-week-half",
        oneHalf: "two-per-week-one-half",
        twoHalf: "two-per-week-two-half",
        threeHalf: "two-per-week-three-half",
        fourHalf: "two-per-week-four-half",
        fiveHalf: "two-per-week-five-half",
        sixHalf: "two-per-week-six-half",
    },
    threePerWeek: {
        half: "three-per-week-half",
        oneHalf: "three-per-week-one-half",
        twoHalf: "three-per-week-two-half",
        threeHalf: "three-per-week-three-half",
        fourHalf: "three-per-week-four-half",
        fiveHalf: "three-per-week-five-half",
        sixHalf: "three-per-week-six-half",
    },
    fourPerWeek: {
        half: "four-per-week-half",
        oneHalf: "four-per-week-one-half",
        twoHalf: "four-per-week-two-half",
        threeHalf: "four-per-week-three-half",
        fourHalf: "four-per-week-four-half",
        fiveHalf: "four-per-week-five-half",
        sixHalf: "four-per-week-six-half",
    },
    fivePerWeek: {
        half: "five-per-week-half",
        oneHalf: "five-per-week-one-half",
        twoHalf: "five-per-week-two-half",
        threeHalf: "five-per-week-three-half",
        fourHalf: "five-per-week-four-half",
        fiveHalf: "five-per-week-five-half",
        sixHalf: "five-per-week-six-half",
    },
});
