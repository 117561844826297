<template>
  <svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 62 22" style="enable-background:new 0 0 62 22;" xml:space="preserve">
    <path fill="#3A3736" d="M5.42,11.96c0-1.39,0.88-2.36,2.26-2.36c1.4,0,2.27,0.98,2.27,2.36c0,1.39-0.88,2.36-2.26,2.36
    C6.3,14.32,5.42,13.34,5.42,11.96z M8.83,11.96c0-0.85-0.42-1.48-1.15-1.48c-0.74,0-1.14,0.65-1.14,1.48
    c0,0.84,0.41,1.48,1.14,1.48C8.43,13.44,8.83,12.81,8.83,11.96z M10.64,12.61v-2.9h1.11v2.67c0,0.72,0.34,1.06,0.87,1.06
    c0.56,0,0.93-0.41,0.93-1.11V9.71h1.1v4.49h-1.06v-0.79l0.05,0.05c-0.22,0.53-0.77,0.86-1.38,0.86
    C11.28,14.32,10.64,13.71,10.64,12.61z M15.37,11.95c0-1.36,0.81-2.35,2.2-2.35c1.14,0,1.9,0.66,2.03,1.67H18.5
    c-0.07-0.52-0.44-0.79-0.93-0.79c-0.68,0-1.09,0.6-1.09,1.47c0,0.86,0.39,1.49,1.08,1.49c0.53,0,0.91-0.37,0.95-0.98h1.1
    c-0.09,1.11-0.86,1.85-2.05,1.85C16.23,14.32,15.37,13.36,15.37,11.95z M20.23,8h1.11v2.49l-0.08-0.04
    c0.22-0.54,0.79-0.85,1.38-0.85c0.98,0,1.61,0.62,1.61,1.72v2.89h-1.1v-2.67c0-0.72-0.34-1.06-0.88-1.06
    c-0.56,0-0.93,0.39-0.93,1.13v2.6h-1.11V8z M35.04,9.71h1.06v0.78l-0.04-0.04c0.22-0.54,0.8-0.85,1.39-0.85
    c0.98,0,1.61,0.62,1.61,1.72v2.89h-1.1v-2.67c0-0.72-0.34-1.06-0.88-1.06c-0.55,0-0.93,0.4-0.93,1.1v2.63h-1.11V9.71z M45.93,10.78
    h1.11v3.42h-1.11V10.78z M45.92,9.24h1.13v1.11h-1.13V9.24z M53.5,9.6c-0.61,0-1.13,0.3-1.42,0.79c-0.25-0.53-0.75-0.79-1.39-0.79
    c-0.53,0-1.01,0.28-1.28,0.77V9.71h-1.06v4.49h1.1v-2.61c0-0.7,0.35-1.12,0.89-1.12c0.54,0,0.82,0.34,0.82,1.06v2.67h1.1v-2.69
    c0.03-0.71,0.41-1.04,0.88-1.04c0.54,0,0.83,0.34,0.83,1.04v2.69h1.1v-3.03C55.07,10.23,54.5,9.6,53.5,9.6z M55.76,11.95
    c0-1.35,0.84-2.36,2.24-2.36c1.33,0,2.2,0.89,2.2,2.3v0.34h-3.31c0.03,0.7,0.45,1.22,1.19,1.22c0.48,0,0.92-0.2,1.03-0.6h1.04
    c-0.18,0.88-1.01,1.47-2.11,1.47C56.65,14.32,55.76,13.32,55.76,11.95z M59.07,11.47c-0.04-0.59-0.46-1.01-1.08-1.01
    c-0.65,0-1.05,0.43-1.11,1.01H59.07z"/>
    <path fill="#656465" d="M30.54,14.68c-2,0-3.62-1.62-3.62-3.62s1.62-3.62,3.62-3.62c2,0,3.62,1.62,3.62,3.62
    S32.54,14.68,30.54,14.68z M30.54,8.34c-1.5,0-2.72,1.22-2.72,2.72s1.22,2.72,2.72,2.72c1.5,0,2.72-1.22,2.72-2.72
    S32.04,8.34,30.54,8.34z"/>
    <path fill="#D61518" d="M2.74,8.28H0.69V7.29h5.25v0.99H3.88v5.92H2.74V8.28z M43.04,8.28h-2.06V7.29h5.25v0.99h-2.05v5.92h-1.14
    V8.28z M30.85,8.78h-0.6v1.99h-1.5v0.6h2.1V8.78z"/>
  </svg>
</template>
