"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateBranchTimecardDefaultSettings = exports.updateBranchDefaultShiftSettings = exports.updateBranchTimecardSettings = exports.updateBranchShiftSettings = exports.getBranchSettings = exports.getDefaultBranchSettings = void 0;
const branchSettingsParser_1 = require("../parser/branchSettingsParser");
const webappApi_1 = require("../webappApi/v2/webappApi");
async function getDefaultBranchSettings(groupId) {
    const route = `branch_defaults/${groupId}`;
    try {
        const response = await (0, webappApi_1.makeGetRequest)(route);
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.getDefaultBranchSettings = getDefaultBranchSettings;
async function getBranchSettings(branchId) {
    const route = `branch_settings/${branchId}`;
    try {
        const response = await (0, webappApi_1.makeGetRequest)(route);
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.getBranchSettings = getBranchSettings;
async function updateBranchShiftSettings(branchId, rawData) {
    const route = `branch_settings/${branchId}`;
    try {
        const response = await (0, webappApi_1.makePatchRequest)(route, JSON.stringify(rawData.shift));
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.updateBranchShiftSettings = updateBranchShiftSettings;
async function updateBranchTimecardSettings(branchId, rawData) {
    const route = `branch_settings/${branchId}`;
    try {
        const response = await (0, webappApi_1.makePatchRequest)(route, JSON.stringify(rawData.timecard));
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.updateBranchTimecardSettings = updateBranchTimecardSettings;
async function updateBranchDefaultShiftSettings(groupId, rawData) {
    const route = `branch_defaults/${groupId}`;
    try {
        const response = await (0, webappApi_1.makePatchRequest)(route, JSON.stringify(rawData.shift));
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.updateBranchDefaultShiftSettings = updateBranchDefaultShiftSettings;
async function updateBranchTimecardDefaultSettings(groupId, rawData) {
    const route = `branch_defaults/${groupId}`;
    try {
        const response = await (0, webappApi_1.makePatchRequest)(route, JSON.stringify(rawData.timecard));
        const data = await response.json();
        const parsedData = (0, branchSettingsParser_1.parseFromAPIRaw)(data);
        return parsedData;
    }
    catch (err) {
        throw err;
    }
}
exports.updateBranchTimecardDefaultSettings = updateBranchTimecardDefaultSettings;
