<script>
import ShiftSlot from './ShiftSlot.vue';
import ShiftSmallSlot from './ShiftSmallSlot.vue';
import { timestampFromDate, JPShortDateString, timestampSecsFromDate } from '../js/common';
import { filterSlotsForCalendarCell } from '../js/shift.js';
import { MeStore } from '../store/v1/meStore';
import { fromOplusTime } from '../common/common';
import { updateCurrentDate } from '../slotModal/roleList';

  const ANIMATION_NAME = {
    RIGHT_FADE: "right-fade",
    LEFT_FADE: "left-fade",
  }

  export default {
    props: {
      cellProps: Object,
      cellIndex: Number,
      isSelected: Boolean,
      isHoliday: Boolean,
      branchSettings: Object,
      date: String,
      meStore: MeStore,
    },
    data() {
      return {
        slots: [],
        animationFade: '',
      }
    },
    methods: {
      handleAddSlotClick(event) {
        event.stopPropagation();
        if (this.isCellCannotAddSlot || this.isAddSlotDisabled) {
          return;
        }
        const dateUnix = timestampSecsFromDate(this.cellProps.date)
        if (selectedTemplate) {
          return addSlotTemplate(dateUnix, this.meStore.getActiveUserId(), "")
        } else {
          return handleOpenShiftSlotModal(dateUnix);
        }
      },
      handleCellClick() {
        // We use next here to wait to wait for the popup modal to show,
        // $ref function won't be able to refrence the element if its hidden.
        this.$nextTick(() => {
          this.calcElementMargin();
          this.$emit("emit-selected-cell", this.cellProps.date);
        })
      },
      closePopup(event) {
        this.$emit("emit-close-popup", event);
      },
      calcElementMargin() {
        const cellModalHeight = 200;
        const cellModalHalfHeight = cellModalHeight / 2;
        // TODO: Mobile view should just show regular modal instead of dynamic position popup
        const mobileViewCompensate = 175;

        const { top, left, width } = this.getCellPosition();
        let marginLeft = 0;
        let marginTop = 0;

        // Column calculation
        if (this.cellDay < 4) {
          marginLeft = left + width;
          this.animationFade = ANIMATION_NAME.LEFT_FADE;
        } else {
          marginLeft = left - mobileViewCompensate;
          this.animationFade = ANIMATION_NAME.RIGHT_FADE;
        }

        // Row calculation
        if (this.cellRowIndex < 2) {
          marginTop = top;
        } else if (this.cellRowIndex === 2) {
          marginTop = top - cellModalHalfHeight;
        } else {
          marginTop = top - cellModalHeight;
        }

        return { marginLeft, marginTop };
      },
      updateAndSortSlots() {
        const ids = this.filteredSlots;
        ids.sort((a, b) => new Date(this.slotMap[a].From) - new Date(this.slotMap[b].From));
        this.slots = [...ids];
      },
      getCellPosition() {
        const rect = this.$refs['calendar-cell'].getBoundingClientRect();
        return {
          top: rect.top,
          left: rect.left,
          width: rect.width,
        }
      },
    },
    computed: {
      disabledText() {
        return `必要人数が満たされているため無効です`;
      },
      isAddSlotDisabled() {
        return !(this.roleDates?.[this?.$parent?.selectedRoleID]?.[timestampSecsFromDate(this.cellProps.date)]?.ShouldShow ?? true);
      },
      disabledBtnClass() {
        return {
          "add-disabled": this.isAddSlotDisabled,
          "tooltip": this.isAddSlotDisabled,
          "tooltip-left": this.cellDay === 6 && this.isAddSlotDisabled,
          "tooltip-bottom": this.cellDay != 6 && this.isAddSlotDisabled && this.cellRowIndex === 0,
        };
      },
      filteredSlots() {
        return filterSlotsForCalendarCell(this.cellProps.slots, this.isCellCannotAddSlot, this.slotMap);
      },
      cellDate() {
        return this.cellProps?.date;
      },
      cellDay() {
        return this.cellDate.getUTCDay();
      },
      cellRowIndex() {
        return Math.floor(this.cellIndex / 7);
      },
      isCellLocked() {
        return this.cellProps?.isLocked;
      },
      isStaffLocked() {
        return this.cellProps?.isStaffLocked;
      },
      isCellClosed() {
        return this.cellProps?.isClosed;
      },
      hasSlots() {
        if (this.cellProps) {
          return Object.keys(this.cellProps.slots).length > 0
        }
        return false;
      },
      isCellCannotAddSlot() {
        return this.isCellLocked || this.isCellClosed;
      },
      todayClass() {
        return {
          "today-cell": this.cellDate.valueOf() === timestampFromDate(new Date()),
        }
      },
      holidayClass() {
        return {
          "th-holiday": this.cellDay === 0 || this.isHoliday,
          "th-saturday": this.cellDay === 6,
        }
      },
      dateLabel() {
        let label = "";
        if (this.cellProps) {
          label = this.cellDate.getUTCDate();
        }
        return label;
      },
      japanDateFormat() {
        return JPShortDateString(this.cellDate, true);
      },
      setMargin() {
        return {
          'marginTop': this.calcElementMargin().marginTop + 'px',
          'marginLeft': this.calcElementMargin().marginLeft + 'px',
        }
      },
      disabledPopup() {
        return {
          'disabled-popup': this.isCellCannotAddSlot
        }
      },
    },
    emits: [
      "emit-selected-cell",
      "emit-close-popup"
    ],
    // Chose to use watch here, as computed are cached, and wont react to changes when slots are edited
    watch: {
      cellProps: {
        handler() {
          this.updateAndSortSlots();
        },
        deep: true
      }
    },
    components: {
      ShiftSlot,
      ShiftSmallSlot
    },
    inject: [
      'slotMap',
      'roleDates',
    ],
    created() {
      this.updateAndSortSlots();
    },
  }
</script>
<template>
  <div class="day-cell-wrapper" @click="handleCellClick()" ref="calendar-cell">
    <!-- Cell Body -->
    <div class="day-cell">
      <!-- Cell Date Num -->
      <div class="date-label-wrapper" :class="holidayClass">
        <span :class="todayClass" class="day-num">{{ dateLabel }}</span>
      </div>
      <div class="day-cell-content">
        <i v-if="cellProps.isLocked" class="zmdi zmdi-lock zmdi-hc-lg text-gray"></i>
        <i v-else-if="cellProps.isClosed" class="zmdi zmdi-close zmdi-hc-lg text-gray"></i>
        <div v-if="hasSlots" class="calendar-slots-cell" :class="disabledPopup">
          <div class="calendar-slots hide-sm">
            <!-- Slots here -->
            <ShiftSlot v-for="id in slots"
              :key="id"
              :slot="slotMap[id]"
              :repeat-date="slotMap[id].Repeat ? new Date(cellProps.date) : null"
              :branch-settings="branchSettings"
              :date="date"
              :me-store="meStore"
            />
          </div>
          <div class="show-sm">
            <ShiftSmallSlot v-for="id in slots" :slot="slotMap[id]" :key="id"/>
          </div>
        </div>
      </div>
      <div class="cell-add-button">
        <div v-if="!isCellCannotAddSlot" class="selected-template-add">
          <button v-if="!isStaffLocked" class="btn btn-slot-add-primary" :class="disabledBtnClass" :data-tooltip="disabledText" @click="handleAddSlotClick($event)">
            <i class="icon icon-plus"></i>
          </button>
        </div>
      </div>

    </div>
    <!-- Popup modal -->
    <transition :name="animationFade">
      <div v-if="isSelected" class="cell-details-container">
        <div class="cell-details-overlay" @click="closePopup($event);"></div>
        <div class="cell-details-card" :style="setMargin" ref="cell-details-card">
          <span class="card-date-text" :class="holidayClass">{{ japanDateFormat }}</span>
          <div class="calendar-slots-container">
            <div v-if="hasSlots">
              <div class="calendar-slots flex-column-reverse" :class="disabledPopup">
                <!-- Slots here -->
                <ShiftSlot v-for="id in slots.slice().reverse()"
                  :key="id"
                  :slot="slotMap[id]"
                  :repeat-date="slotMap[id].Repeat ? new Date(cellProps.date) : null"
                  :date="date"
                  :branch-settings="branchSettings"
                  :me-store="meStore"
                />
              </div>
            </div>
            <div v-else>
              <span class="empty-slot-text">シフトがありません</span>
            </div>
          </div>
          <div v-if="!isStaffLocked" class="add-slot-button-wrapper edit">
            <div v-if="!isCellCannotAddSlot" class="selected-template-add">
              <button class="btn btn-slot-add-primary" :class="disabledBtnClass" :data-tooltip="disabledText" @click="handleAddSlotClick($event)">
                <i class="icon icon-plus"></i>
              </button>
            </div>
            <div v-else>
              <i v-if="isCellLocked" class="zmdi zmdi-lock zmdi-hc-lg text-gray"></i>
              <i v-else class="zmdi zmdi-close zmdi-hc-lg text-gray"></i>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>

</template>
<style scoped>
.disabled-popup {
  pointer-events: none;
  opacity: .7;
}
.btn-slot-add-primary.add-disabled {
  border-color: #BBBDBF;
  color: #BBBDBF;
  cursor: not-allowed;
}
.btn-slot-add-primary.add-disabled:hover {
  border-color: #fff;
  color: #fff;
}

.btn-slot-add-primary.add-disabled:hover {
  background-color: #BBBDBF;
}

.add-disabled.tooltip::after {
  width: 200px;
  white-space: break-spaces;
}

.day-cell {
  overflow: visible;
}

</style>
