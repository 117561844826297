<template>
<svg version="1.1" class="collab-kot" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 225 34" style="enable-background:new 0 0 225 34;" xml:space="preserve">
  <path fill="#2D9547" d="M14.751,32.845c-7.188,0-13.033-5.847-13.033-13.032S7.563,6.78,14.751,6.78
		c7.186,0,13.032,5.847,13.032,13.032S21.937,32.845,14.751,32.845z M14.751,8.812c-6.067,0-11.001,4.935-11.001,11
		c0,6.065,4.934,11,11.001,11c6.065,0,10.999-4.935,10.999-11C25.75,13.747,20.816,8.812,14.751,8.812z M120.059,31.038
		c-5.48,0-9.937-4.459-9.937-9.938c0-5.479,4.458-9.938,9.937-9.938c5.48,0,9.939,4.459,9.939,9.938
		C129.998,26.579,125.539,31.038,120.059,31.038z M120.059,14.825c-3.46,0-6.275,2.814-6.275,6.274s2.815,6.275,6.275,6.275
		c3.461,0,6.275-2.815,6.275-6.275S123.52,14.825,120.059,14.825z M45.435,17.526c3.746-2.546,6.384-6.604,7.03-11.288h-4.943
		c-1.012,5.103-5.429,8.991-10.772,9.2v-9.2h-4.88v24.725h4.88V20.346c0.237-0.014,0.478-0.021,0.721-0.021
		c6.043,0,11.003,4.704,11.419,10.638h4.882C53.493,25.193,50.198,20.2,45.435,17.526z M60.303,6.24h-4.885v24.721h4.885V6.24z
		 M176.089,6.24h-4.885v24.721h4.885V6.24z M192.051,11.327v19.634h0.007V11.327H192.051z M201.081,8.169
		c-0.135-0.19-0.283-0.366-0.444-0.529c-0.424-0.427-0.929-0.881-1.669-1.146c-0.397-0.149-0.861-0.237-1.42-0.237h-15.631
		c-0.545,0-1.003,0.088-1.4,0.224c-0.747,0.264-1.258,0.725-1.689,1.159c-0.161,0.163-0.309,0.339-0.444,0.529
		c-0.505,0.685-0.807,1.498-0.807,2.407v20.386h4.885V11.179h4.952v19.783h4.636V11.179h4.952v19.783h4.885c0,0,0-19.905,0-20.386
		C201.889,9.667,201.586,8.853,201.081,8.169z M187.409,11.179v19.783h0.007V11.179H187.409z M205.589,6.981
		c-0.188,0.137-0.363,0.287-0.525,0.451c-0.424,0.431-0.875,0.943-1.137,1.695c-0.148,0.403-0.235,0.875-0.235,1.442v15.879
		c0,0.554,0.087,1.018,0.222,1.422c0.262,0.759,0.72,1.278,1.151,1.716c0.161,0.164,0.336,0.314,0.525,0.451
		c0.68,0.513,1.487,0.82,2.389,0.82h15.52v-4.963h-14.921v-5.031h12.974v-4.71h-12.974v-5.031h14.921V6.161c0,0-15.042,0-15.52,0
		C207.076,6.161,206.269,6.468,205.589,6.981z M208.577,20.871h12.974v-0.007h-12.974V20.871z M146.186,15.97v-3.775
		c0,0-11.607,0-11.97,0c-0.7,0-1.319,0.235-1.844,0.626c-0.148,0.101-0.283,0.215-0.41,0.343c-0.323,0.33-0.673,0.713-0.875,1.285
		c-0.114,0.31-0.182,0.666-0.182,1.097v15.347h3.768v-7.529h10.012v-3.58h-10.012V15.97H146.186z M134.675,23.371h10.011v-0.005
		h-10.011V23.371z M77.623,6.24v7.24c-1.386-1.918-2.536-3.02-4.866-4.416c-2.329-1.396-4.08-1.985-5.845-2.362
		c-1.765-0.377-4.765-0.462-4.765-0.462v24.721h4.885V11.819c8.929,1.823,10.201,13.565,10.591,19.049
		c0,0.034,0.007,0.061,0.007,0.094h4.878V6.24H77.623z M77.623,30.867v0.094h0.007C77.63,30.928,77.623,30.901,77.623,30.867z
		 M148.752,6.226v4.839h8.077v19.898h4.879V11.066h8.092V6.226H148.752z M123.595,9.32V8.167V7.013V5.86l-1.758,1.568l-1.758-1.568
		l-1.758,1.568l-1.757-1.568v1.154v1.154V9.32v1.154h1.757h1.758h1.758h1.758V9.32z M11.225,12.957
		c-0.667,0-1.209,0.732-1.209,1.635c0,0.903,0.542,1.635,1.209,1.635s1.209-0.732,1.209-1.635
		C12.434,13.689,11.892,12.957,11.225,12.957z M18.214,12.957c-0.667,0-1.211,0.732-1.211,1.635c0,0.903,0.544,1.635,1.211,1.635
		c0.665,0,1.209-0.732,1.209-1.635C19.423,13.689,18.879,12.957,18.214,12.957z M96.198,30.87c5.009-0.055,9.38-3.891,9.745-8.553
		c0.12-1.54,0.008-3.538,0.003-3.622l-0.127-2.22H95.358v4.71h5.925c-0.006,0.268-0.017,0.53-0.035,0.766
		c-0.157,2.008-2.244,4.179-5.1,4.21c-0.037,0.001-0.074,0.001-0.11,0.001c-2.377,0-4.127-0.852-5.346-2.605
		c-0.617-0.886-1.158-2.952-1.159-4.419c0-1.155,0-3.558,0.854-5.167c0.841-1.584,2.555-3.182,4.491-3.182
		c2.281,0,4.138,0.857,5.678,2.621l3.547-3.099c-2.452-2.808-5.556-4.232-9.225-4.232c-3.422,0-6.817,2.231-8.651,5.683
		c-1.404,2.644-1.404,5.84-1.404,7.376c0.001,2.103,0.689,5.22,2.003,7.108c1.472,2.117,4.261,4.625,9.212,4.625
		C96.09,30.87,96.144,30.87,96.198,30.87z"/>
	<path fill="#F19320" d="M18.214,5.969h-1.758h-1.758h-1.758H11.18V4.815V3.662V2.508V1.355l1.758,1.568l1.758-1.568l1.758,1.568
		l1.758-1.568v1.153v1.154v1.153V5.969z M12.927,19.316l-0.717-0.717l-1.109,1.108l-1.107,1.108l-2.557-2.556l0.849-0.852
		l-3.328-0.178l0.178,3.327l0.863-0.863l1.755,1.754l2.24,2.241l1.824-1.825l1.829-1.825L12.927,19.316z M15.809,20.037l1.829,1.825
		l1.826,1.825l2.24-2.241l1.753-1.754l0.865,0.863l0.177-3.327l-3.326,0.178l0.847,0.852l-2.555,2.556l-1.109-1.108l-1.107-1.108
		l-0.717,0.717L15.809,20.037z M129.97,12.239l-2.878-2.585l-0.003,1.396v0.575h-0.102c-1.914,0-3.768,0.923-5.216,2.6
		c-1.133,1.31-1.996,3.327-2.29,5.248c-0.65,0.252-1.112,0.881-1.112,1.623c0,0.959,0.778,1.736,1.741,1.736
		c0.958,0,1.736-0.777,1.736-1.736c0-0.639-0.345-1.197-0.856-1.499c0.267-1.609,0.998-3.313,1.925-4.386
		c1.157-1.338,2.606-2.077,4.073-2.077h0.106v1.694L129.97,12.239z"/>
  </svg>
</template>
