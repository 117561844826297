"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PtoConfigDays = void 0;
class PtoConfigDays {
    numIndicator;
    halfYear = 0;
    oneAndHalfYear = 0;
    twoAndHalfYears = 0;
    threeAndHalfYears = 0;
    fourAndHalfYears = 0;
    fiveAndHalfYears = 0;
    sixAndHalfYears = 0;
    constructor(reqParam) {
        this.numIndicator = reqParam.numIndicator;
    }
    setConfigDays(param) {
        if (param?.halfYear !== undefined) {
            this.halfYear = param.halfYear;
        }
        if (param?.oneAndHalfYear !== undefined) {
            this.oneAndHalfYear = param.oneAndHalfYear;
        }
        if (param?.twoAndHalfYears !== undefined) {
            this.twoAndHalfYears = param.twoAndHalfYears;
        }
        if (param?.threeAndHalfYears !== undefined) {
            this.threeAndHalfYears = param.threeAndHalfYears;
        }
        if (param?.fourAndHalfYears !== undefined) {
            this.fourAndHalfYears = param.fourAndHalfYears;
        }
        if (param?.fiveAndHalfYears !== undefined) {
            this.fiveAndHalfYears = param.fiveAndHalfYears;
        }
        if (param?.sixAndHalfYears !== undefined) {
            this.sixAndHalfYears = param.sixAndHalfYears;
        }
    }
    resetConfigDays() {
        this.halfYear = 0;
        this.oneAndHalfYear = 0;
        this.twoAndHalfYears = 0;
        this.threeAndHalfYears = 0;
        this.fourAndHalfYears = 0;
        this.fiveAndHalfYears = 0;
        this.sixAndHalfYears = 0;
    }
    getConfigDays() {
        const { halfYear, oneAndHalfYear, twoAndHalfYears, threeAndHalfYears, fourAndHalfYears, fiveAndHalfYears, sixAndHalfYears, } = this;
        return {
            halfYear,
            oneAndHalfYear,
            twoAndHalfYears,
            threeAndHalfYears,
            fourAndHalfYears,
            fiveAndHalfYears,
            sixAndHalfYears,
        };
    }
    getNumIndicator() {
        return this.numIndicator;
    }
}
exports.PtoConfigDays = PtoConfigDays;
