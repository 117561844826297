<template>
  <svg version="1.1" class="money-forward" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 65 25" style="enable-background:new 0 0 60 25;" xml:space="preserve">
    <path fill="#E2740E" d="M43.49,5.19h-0.79L37.5,16.53L32.49,5.19h-0.81l-3.16,16.17h2.54l1.74-9.8l4.33,9.81h0.66l4.46-9.81l1.56,9.8
    h2.51L43.49,5.19z M56.4,7.45h-5.65v3.9h5.45v2.3h-5.45v7.72H48.3V5.15h8.1V7.45z M23.54,12.83c0,5.72-4.63,10.35-10.35,10.35
    c-5.72,0-10.35-4.63-10.35-10.35c0-5.72,4.63-10.35,10.35-10.35c3.22,0,6.1,1.47,8,3.78l-2.94,4.75l-2.16-3.19
    c-0.33-0.49-0.92-0.76-1.51-0.68c-0.59,0.08-1.09,0.48-1.28,1.05l-1.47,4.24l-1.19-1.47c-0.37-0.45-0.96-0.67-1.53-0.55
    c-0.57,0.11-1.04,0.53-1.21,1.09l-1.71,5.64c-0.25,0.83,0.22,1.71,1.05,1.97c0.15,0.05,0.31,0.07,0.46,0.07
    c0.68,0,1.3-0.44,1.51-1.12l0.84-2.79l1.11,1.36c0.36,0.44,0.93,0.66,1.5,0.56c0.56-0.1,1.03-0.49,1.22-1.04l1.37-3.94L17,14.8
    c0.3,0.44,0.8,0.71,1.34,0.69c0.53-0.01,1.03-0.29,1.31-0.75l3.31-5.34C23.33,10.48,23.54,11.63,23.54,12.83z M24.2,2.18
    c-1.16,0-2.1,0.94-2.1,2.1s0.94,2.1,2.1,2.1c1.16,0,2.1-0.94,2.1-2.1S25.36,2.18,24.2,2.18z"/>
  </svg>
</template>
