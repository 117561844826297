<script>
import GroupRoleIcon from './icons/GroupRoleIcon.vue'
import SlotBadges from './components/SlotBadges.vue';
import { SLOT_TYPES } from '../js/models';
import { isSlotTypeXOffDayString, isSlotGroupRole } from '../js/slot_helpers';
import { isClone } from '../js/slot_helpers';
import { addDaysToDate, goDateString, JPDateString } from '../js/common';
import { parseOplusTimeRaw, parseRepeatShiftTemplateOplusTimeRaw } from '../legacy/parser/shiftParser';
import { shiftName } from '../view/shift/shift';
import { BranchSettings } from '../model/v1/branchSettings';
import { makeTableDate } from '../table/v1/table';
import { fromOplusTime } from '../common/common';
import timeZones from '../common/timeZones';
import periodTypes from '../table/v1/periodTypes';
import { MeStore } from '../store/v1/meStore';
import { repeatShiftsFromTemplate } from '../employmentShiftTable/employmentShiftTable';
  // Shift Slot component, this Component is heavily copied from Slot.vue. TODO: Refactor
  // As of 2023-10-10 it is used for shift-calendar
  export default {
    props: {
      slot: Object,
      repeatDate: Date,
      branchSettings: Object,
      date: String,
      meStore: MeStore,
    },
    inject: [
      'slotLogMap',
      'useShiftBorders', // Browser setting for enabling dashed borders for unconfirmed shifts
    ],
    methods: {
      handleSlotClick(){
        if (isClone(this.slot, this.meStore.getActiveBranchId()) || this.slot.IsHelp) {
          return;
        }
        let dateUnix = fromOplusTime(new Date(this.slot.Date)).valueOf() / 1000;
        if (this.slot.Repeat) {
          dateUnix = fromOplusTime(this.repeatDate).valueOf() / 1000
        }
        openShiftSlotModal(dateUnix, this.slot.ID)
      },
    },
    computed: {
      slotName() {
        let shift = parseOplusTimeRaw(this.slot);
        const tz = timeZones.ASIA_TOKYO
        const date = makeTableDate(fromOplusTime(new Date(this.date)), periodTypes.DAY, tz);
        if (this.isRepeat) {
          const repeatTemplate = parseRepeatShiftTemplateOplusTimeRaw(this.slot, tz);
          // +- a day on either side to catch overnight slots
          const shifts = repeatShiftsFromTemplate(addDaysToDate(date.getDate(), -1), addDaysToDate(date.getEnd(), 1), repeatTemplate, tz);
          shift = shifts[0];
        }
        if (!shift) {
          return "";
        }
        return shiftName(shift, this.branchSettings, date.getDate(), date.getEnd(), tz);
      },
      hasRequestSlot() {
        return this.slot.RequestedSlotID && this.slot.RequestedSlot;
      },
      hasLinkPrev() {
        return this.slot.LinkPrev ? true : false;
      },
      hasLinkNext() {
        return this.slot.LinkNext ? true : false;
      },
      companyName() {
          return this.isClone ? this.slot.CompanyName : "";
      },
      isHelp() {
        return this.slot.IsHelp;
      },
      isAuto() {
        return this.slot.IsAuto;
      },
      isKOTSynced() {
        return this.slot.KOTSynced;
      },
      isTypeOne() {
        return this.slot.Type === 1;
      },
      isDefaultTextTypeTwo() {
        return this.isTypeTwo && this.slotName === '×';
      },
      isTypeTwo() {
        return this.slot.Type === 2;
      },
      isHolidayType() {
        return this.slot.Type > 1;
      },
      isRepeat() {
        return this.slot.Repeat;
      },
      numComments() {
        return this.slot.NumComments;
      },
      rejectedAsk() {
        return this.slot.AskStatus === 3;
      },
      isAsked() {
        return this.slot.AskStatus > 0 && !this.isLocked;
      },
      isClone() {
        return this.slot.CompanyID != this.meStore.getActiveBranchId();
      },
      isLocked() {
        if (this.isRepeat) {
          return this.isRepeatLocked;
        }

        return this.slot.Locked;
      },
      isRole() {
        if (this.slot.RoleID) {
            return true
        }
        return false
      },
      isGroupRole() {
        if (!this.isRole) {
          return false
        }
        return isSlotGroupRole(this.slot);
      },
      isRepeatLocked() {
        if (!this.isRepeat) {
            return false;
        }
        const repeatDateZeroed = new Date(this.repeatDate).setUTCHours(0, 0, 0, 0);
        const ind = this.slot.RepeatLocked?.findIndex(d => new Date(d).setUTCHours(0, 0, 0 ,0) === repeatDateZeroed);
        if (ind >= 0) {
            return true;
        }

        return false;
      },
      computeChipStyle() {
        let bg = this.slot.Color ? `#${this.slot.Color}` : '#B0ADAC40';
        return {
          'backgroundColor': bg,
          'cursor': this.isClone ? 'default' : 'pointer',
        };
      },
      lockStyle() {
        return {
          'color': this.isKOTSynced ? '#1E9038' : '',
        }
      },
      borderStyle() {
        return {
          'outline': !this.isLocked ? '1.5px dashed #646565' : '',
          'border': !this.isLocked ? 'none' : '',
        }
      },
      borderClass() {
        if (!this.useShiftBorders) {
          return {};
        }
        return {
          'slot-outline': !this.isLocked && (!this.slot.RequestedSlot || this.isClone),
          'requested-slot-outline': !this.isLocked && this.slot.RequestedSlot && !this.isClone,
        }
      },
      slotWrapperClass() {
        if (!this.useShiftBorders) {
          return {};
        }
        return {
          'slot-wrapper': !this.isLocked && this.slot.RequestedSlot && !this.isClone,
        }
      },
      chipClass() {
        return {
          'clone': this.isClone,
          'text-light': this.slot.Color,
          'main-slot': this.hasRequestSlot && !this.isClone,
        }
      },
      slotNameClass() {
        return {
          'text-success': this.isTypeOne,
          'zmdi-hc-lg': this.isDefaultTextTypeTwo,
          'zmdi': this.isTypeTwo && !this.isRole,
          'text-error': this.isHolidayType,
        }
      },
      requestSlotNameClass() {
        return {
          'zmdi zmdi-hc-lg': this.isRequestDefaultTextTypeTwo
        }
      },
      isRequestSlotTypeTwo() {
        return this.slot?.RequestedSlot.Type === SLOT_TYPES.OFF_DAY;
      },
      isRequestDefaultTextTypeTwo() {
        return this.isRequestSlotTypeTwo && isSlotTypeXOffDayString(this.slot.RequestedSlot.Text);
      },
      numUnreadComments() {
        if (this.slotLogMap) {
          const slotLogs = this.slotLogMap[this.slot.ID];
          return slotLogs ? slotLogs.length : 0;
        }
        return 0;
      },
    },
    components: {
      GroupRoleIcon,
      SlotBadges,
    }
  }
</script>

<template>
  <div class="shift-slot-container">
    <div class="shift-slot-wrapper slot-margin" :class="slotWrapperClass">
      <span v-if="slot.RequestedSlot && !isClone" class="chip shift-slot requested-slot" :class="borderClass">

        <SlotBadges
          :numReadComments="numComments"
          :numUnreadComments="numUnreadComments"
          :isClone="isClone"
          :shouldShowAlert="rejectedAsk"
          alertDescription="要請が承認されませんでした"
          :isAsked="isAsked"
          :tooltipBottom="true"
        />

        <i v-if="slot.RequestedSlot.LinkPrev" class="icon icon-link"></i>
        <span :class="requestSlotNameClass" class="slot-name transparent">
          {{ slot.RequestedSlot.Text }}
        </span>
        <i v-if="slot.RequestedSlot.LinkNext" class="icon icon-link"></i>
      </span>
      <span
        @click="handleSlotClick()"
        class="chip shift-slot main-shift-slot"
        :style="computeChipStyle"
        :class="[chipClass, borderClass]"
        :id="`slot-${slot.ID}`"
      >

      <SlotBadges
          v-if="!slot.RequestedSlot || isClone"
          :numReadComments="numComments"
          :numUnreadComments="numUnreadComments"
          :isClone="isClone"
          :shouldShowAlert="rejectedAsk"
          alertDescription="要請が承認されませんでした"
          :isAsked="isAsked"
          :tooltipBottom="true"
      />

        <div class="left-side-icons">
          <GroupRoleIcon v-if="isGroupRole"/>
          <i v-if="isHelp" class="zmdi zmdi-assignment-return"></i>
          <small v-if="companyName">{{ companyName }}</small>
          <i v-if="hasLinkPrev" class="icon icon-link"></i>
          <i v-if="isLocked && !isKOTSynced && !useShiftBorders" class="icon icon-check"></i>
          <i v-if="isLocked && isKOTSynced" class="icon icon-check" :style="lockStyle"></i>
          <span v-if="isRepeat">∞</span>
        </div>
        <span :class="slotNameClass" class="slot-name">{{ slotName }}</span>
        <div class="right-side-icons">
          <i v-if="hasLinkNext" class="icon icon-link"></i>
        </div>
      </span>
    </div>
  </div>
</template>

<style scoped>
  .shift-slot-container {
    display: flex;
    width: 99%;
    max-width: 320px;
  }
  .shift-slot-wrapper {
    min-width: 0;
    width: 100%;
  }

  .shift-slot.main-shift-slot:hover,
  .shift-slot.main-shift-slot:focus {
    border: .05rem solid #52a3d5;
  }

  .shift-slot {
    cursor: pointer;
    width: 100%;
    transition: .2s;
    justify-content: space-evenly;
    gap: 5px;
    position: relative;
    overflow: visible;
  }

  .left-side-icons, .right-side-icons {
    display: flex;
    gap: 3px;
  }
  .slot-name {
    min-width: 0;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .alert-icon-wrapper {
    margin: auto 0;
    gap: 3px;
  }

  .rejected-ask-icon {
    margin: auto;
  }
  .asked-icon::before {
    height: 2em;
    width: 2em;
    border: .05rem solid transparent;
    padding: 5px;
    border-radius: 50%;
    background: #ffb700;
    color: #fff;
  }

  .transparent {
    opacity: 0.5;
  }

  .requested-slot {
    opacity: 1;
  }

  .slot-wrapper {
    border-radius: 5px;
    outline: 1.5px dashed #646565;
  }

  .slot-outline {
    outline: 1.5px dashed #646565;
    border: none;
  }

  .slot-margin {
    margin-top: 0.35rem;
  }

  .requested-slot-outline {
    margin: 0;
    width: 100%;
    border: none;
  }
</style>
