<template>
  <svg class="settings-icon-title" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 34 34" width="30px" style="enable-background:new 0 0 34 34;" xml:space="preserve">
    <path fill="" d="M22.8,11.8L22.8,11.8L22.8,11.8L22.8,11.8z"/>
    <path fill="currentColor" d="M22.8,11.8l-9-9.1v0H5.2c-1.9,0-3.4,1.5-3.4,3.4v21.5c0,1.9,1.5,3.4,3.4,3.4h14.1c1.9,0,3.4-1.5,3.4-3.4
    L22.8,11.8L22.8,11.8z M5.4,21.7l0.8,1.7c0.2,0.4,0.3,0.4,0.7,0.4h1.5c0.2,0,0.5,0.1,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5H6.8
    c-0.7,0-1.1-0.3-1.4-0.9L4.5,22c-0.2-0.3-0.2-0.6-0.2-1c0-0.4,0.1-0.7,0.3-1.1l0.9-1.8c0.3-0.6,0.7-0.9,1.5-0.9h1.6
    c0.2,0,0.5,0.1,0.5,0.5c0,0.3-0.3,0.5-0.5,0.5H6.8c-0.4,0-0.5,0-0.7,0.4l-0.8,1.6c-0.1,0.3-0.2,0.4-0.2,0.7
    C5.2,21.3,5.2,21.4,5.4,21.7z M11.1,18.6l3.3,4.2c0.2,0.2,0.3,0.5,0.3,0.8c0,0.7-0.5,1.1-1.2,1.1h-2.1c-0.4,0-0.7-0.1-1-0.3
    c-0.3-0.2-0.4-0.5-0.4-0.9c0-0.3,0.2-0.6,0.5-0.6c0.3,0,0.5,0.2,0.5,0.5c0,0.3,0.1,0.3,0.4,0.3h2.1c0.1,0,0.3,0,0.3-0.1
    c0-0.1-0.1-0.2-0.2-0.3l-3.3-4.2c-0.2-0.2-0.3-0.4-0.3-0.8c0-0.4,0.2-0.7,0.5-0.9c0.3-0.2,0.5-0.2,0.8-0.2h2
    c0.4,0,0.7,0.1,0.9,0.3c0.2,0.2,0.4,0.6,0.4,0.9c0,0.3-0.1,0.6-0.5,0.6c-0.3,0-0.5-0.2-0.5-0.5c0-0.3-0.2-0.4-0.5-0.4h-2
    c-0.1,0-0.3,0-0.3,0.1C10.9,18.4,11,18.6,11.1,18.6z M20.5,19.3c0,0.1,0,0.3-0.1,0.4l-1.8,4.6c-0.1,0.2-0.2,0.4-0.5,0.4
    c-0.3,0-0.4-0.2-0.5-0.4l-1.8-4.6c0-0.1-0.1-0.2-0.1-0.4v-1.5c0-0.3,0.1-0.5,0.5-0.5c0.3,0,0.5,0.2,0.5,0.5v1.5l1.4,3.6l1.4-3.6
    v-1.5c0-0.3,0.1-0.5,0.5-0.5c0.4,0,0.5,0.2,0.5,0.5V19.3z M15.5,11.8c-0.9,0-1.7-0.8-1.7-1.7V3.7l8,8.1H15.5z"/>
  </svg>
</template>
