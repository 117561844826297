<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    width="30px" viewBox="0 0 18.698 19.533" style="enable-background:new 0 0 18.698 19.533;"
    xml:space="preserve">
    <path fill="currentColor" d="M9.572,10.759c-1.057,0-1.917-0.86-1.917-1.917c0-1.057,0.86-1.917,1.917-1.917
    c1.057,0,1.917,0.86,1.917,1.917C11.489,9.899,10.629,10.759,9.572,10.759z M9.572,7.675c-0.643,0-1.167,0.523-1.167,1.167
    c0,0.643,0.523,1.167,1.167,1.167c0.644,0,1.167-0.523,1.167-1.167C10.739,8.199,10.215,7.675,9.572,7.675z"/>
    <path fill="currentColor" d="M11.919,15.205H7.313c-0.114-0.003-1.043-0.041-1.573-0.498c-0.211-0.183-0.324-0.429-0.325-0.712
    c-0.001-0.596,0.512-1.259,0.994-1.681c0.637-0.558,1.84-1.209,3.138-1.209c1.525,0,2.61,0.667,3.121,1.064
    c0.196,0.153,1.179,0.958,1.219,1.784c0.014,0.295-0.093,0.56-0.309,0.763c-0.442,0.417-1.453,0.48-1.651,0.488L11.919,15.205z
    M9.547,11.855c-1.434,0-2.524,0.919-2.644,1.024c-0.428,0.375-0.738,0.843-0.738,1.114c0,0.065,0.019,0.107,0.065,0.147
    c0.279,0.241,0.897,0.311,1.092,0.315h4.581c0.367-0.017,0.984-0.118,1.16-0.284c0.056-0.053,0.078-0.105,0.074-0.182
    c-0.015-0.298-0.415-0.826-0.931-1.228C11.772,12.423,10.848,11.855,9.547,11.855z"/>
    <path fill="currentColor" d="M11.745,4.721H7.512c-0.329,0-0.597-0.221-0.597-0.494c0-0.271,0.268-0.492,0.597-0.492h4.232
    c0.328,0,0.594,0.22,0.594,0.492C12.338,4.5,12.073,4.721,11.745,4.721z"/>
    <path fill="currentColor" d="M15.25,18.453H3.621c-1.307,0-2.37-1.063-2.37-2.37V3.595c0-1.307,1.063-2.37,2.37-2.37H15.25
    c1.307,0,2.37,1.063,2.37,2.37v12.488C17.62,17.39,16.557,18.453,15.25,18.453z M3.621,2.173c-0.784,0-1.422,0.638-1.422,1.422
    v12.488c0,0.784,0.638,1.422,1.422,1.422H15.25c0.784,0,1.422-0.638,1.422-1.422V3.595c0-0.784-0.638-1.422-1.422-1.422H3.621z"/>
  </svg>
</template>
