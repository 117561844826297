"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ptoConfigNum = void 0;
// this is to indicate the number of days in a week
exports.ptoConfigNum = Object.freeze({
    "onePerWeek": 1,
    "twoPerWeek": 2,
    "threePerWeek": 3,
    "fourPerWeek": 4,
    "fivePerWeek": 5,
});
