<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 28 20" style="enable-background:new 0 0 22 22;" xml:space="preserve">
  <path fill="#2864F0"  d="M18.1,9.96c-0.02,0-2.55,0.39-4.43,0.39c-1.56,0-3.04-0.34-3.5-0.46c0.51-0.29,2.37-1.39,3.53-2.69
  c1.35-1.51,2.25-4.66,2.26-4.69c0-0.03,0-0.06-0.03-0.07c-0.02-0.01-0.05-0.01-0.07,0L6.91,9.11C6.76,9.09,5.93,9.02,5.17,9.28
  c-0.75,0.25-1.09,0.76-1.16,0.87l-0.79,0.21c-0.03,0.01-0.05,0.03-0.04,0.07c0,0.03,0.03,0.05,0.06,0.05
  c0.01,0,1.54,0.05,2.22,0.56c0.48,0.36,0.87,1.01,0.87,1.02c0,0.01,0,0.03,0.01,0.03l6.19,7.82c0.01,0.01,0.03,0.03,0.04,0.03
  c0.01,0,0.01,0,0.02,0c0.03,0,0.04-0.03,0.04-0.05c0-0.02,0.03-2.04-0.55-3.88c-0.47-1.51-1.47-2.55-1.84-2.89
  c0.28,0.01,0.81,0.03,1.33-0.09c0.59-0.13,0.91-0.3,0.99-0.34c0.77,0.01,4.82-0.05,5.1-0.33c0.03-0.03,0.03-0.05,0.03-0.07
  c0-0.19-0.91-0.19-2.04-0.36c-0.53-0.08-1.27-0.2-1.33-0.28c0-0.21,0.25-0.29,1.51-0.59c1.51-0.36,2.3-0.96,2.31-0.99
  c0.01-0.02,0.01-0.04,0-0.06C18.14,9.97,18.12,9.96,18.1,9.96z M9.98,12.55c-2.02-0.08-3.44-1.46-3.45-1.48
  c-0.01-0.01-0.02-0.04-0.01-0.06c0.01-0.02,0.02-0.04,0.05-0.04c0.01,0,1.26-0.2,2.58-0.15c1.31,0.05,4.31,0.37,4.34,0.38
  c0.02,0,0.04,0.02,0.05,0.04c0,0.02,0,0.04-0.02,0.06c-0.01,0.01-1.42,1.25-3.35,1.25C10.1,12.55,10.04,12.55,9.98,12.55z
  M5.6,10.07c0-0.2,0.17-0.36,0.36-0.36c0.2,0,0.37,0.16,0.37,0.36s-0.17,0.36-0.37,0.36C5.77,10.43,5.6,10.27,5.6,10.07z"/>
  </svg>
</template>
