<template>
    <svg class="minajin-logo" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	    width="120px" height="26px" viewBox="0 0 245 40" style="enable-background:new 0 0 245 40;" xml:space="preserve">
        <path fill="#191919" d="M4.3,4h8.5l7.6,11.8L28,4h8.5V36h-7.1V14.4l-9,13.3l-9.1-13.3V36h-7V4z M45.7,4h7.2V36h-7.2V4z M60.4,4h8.5
		l13.2,22.9h0.2V15.3V4h6.9V36h-8.5L67.5,13.1h-0.2v11.6V36h-6.9V4z M115.9,4h-9.1L96.3,36h7.7l2.1-7.6h10.2l2.1,7.6h8L115.9,4z
		 M107.6,22.7l0.8-3c0.9-3.2,1.8-6.9,2.6-10.3h0.2c0.9,3.3,1.8,7.1,2.7,10.3l0.8,3H107.6z M129.9,20.2c0-10.2,7.1-16.4,16.8-16.4
		c5.1,0,8.4,2,10.6,3.9l-2.6,2.6c-1.9-1.7-4.2-3-7.9-3c-7.4,0-13.3,4.9-13.3,12.8c0,7.9,5.5,12.9,13.3,12.9c2.6,0,5.8-0.7,7.3-1.9
		v-8.2h-6.7v-3.3h10.3v13.3c-2.4,2.1-6.4,3.7-11.3,3.7C136.8,36.5,129.9,30.5,129.9,20.2z M166.7,3.8h4.1V36h-4.1V3.8z M181.9,3.8
		h4.2L206,30.7h0.2c-0.2-3.2-0.5-26.9-0.5-26.9h3.9V36h-4.2L185.5,9.1h-0.2c0.2,3.3,0.5,26.9,0.5,26.9h-3.9V3.8z M219.6,3.8h19.7
		v3.5h-15.6v10.1h12.2v3.5h-12.2v11.7h16.1V36h-20.2V3.8z"/>
    </svg>
</template>
