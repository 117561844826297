<template>
  <svg version="1.1" class="collab-ccbiz" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 165 34" style="enable-background:new 0 0 165 34;" xml:space="preserve">
    <path fill="#03A04A" d="M14.074,11.035v-2.6c0-1.079-0.871-1.95-1.95-1.95h-3.12c-1.079,0-1.95,0.871-1.95,1.95v17.849
    c0,1.079,0.871,1.95,1.95,1.95h3.12c1.079,0,1.95-0.871,1.95-1.95v-2.6h3.9v2.6c0,3.224-2.626,5.85-5.85,5.85h-3.12
    c-3.224,0-5.85-2.626-5.85-5.85V8.435c0-3.237,2.626-5.85,5.85-5.85h3.12c3.224,0,5.85,2.613,5.85,5.85v2.6H14.074z M36.681,11.035
    v-2.6c0-3.237-2.626-5.85-5.85-5.85h-3.12c-3.224,0-5.85,2.613-5.85,5.85v17.849c0,3.224,2.626,5.85,5.85,5.85h3.12
    c3.224,0,5.85-2.626,5.85-5.85v-2.6h-3.9v2.6c0,1.079-0.871,1.95-1.95,1.95h-3.12c-1.066,0-1.95-0.871-1.95-1.95V8.435
    c0-1.079,0.884-1.95,1.95-1.95h3.12c1.079,0,1.95,0.871,1.95,1.95v2.6H36.681z M43.029,14.236c-1.725,0-3.123,1.398-3.123,3.123
    s1.398,3.123,3.123,3.123c1.725,0,3.123-1.398,3.123-3.123S44.754,14.236,43.029,14.236z M70.558,1.297
    c-1.725,0-3.123,1.398-3.123,3.123s1.398,3.123,3.123,3.123c1.725,0,3.123-1.398,3.123-3.123S72.283,1.297,70.558,1.297z"/>
    <path d="M161.923,16.82v-1.183c0-3.224-2.613-5.85-5.85-5.85h-4.42c-3.224,0-5.85,2.626-5.85,5.85v10.751
    c0,3.224,2.626,5.85,5.85,5.85h4.42c3.237,0,5.85-2.626,5.85-5.85v-0.273h-3.9v0.273c0,1.079-0.871,1.95-1.95,1.95h-4.42
    c-0.884,0-1.625-0.585-1.859-1.391l8.229-6.877L161.923,16.82z M149.703,21.942v-6.305c0-1.079,0.871-1.95,1.95-1.95h4.42
    c0.884,0,1.625,0.585,1.859,1.391L149.703,21.942z M64.748,8.435c0-3.237-2.626-5.85-5.85-5.85h-8.97v29.549h8.97
    c3.224,0,5.85-2.626,5.85-5.85v-5.031c0-1.495-0.572-2.873-1.508-3.9c0.936-1.027,1.508-2.405,1.508-3.9V8.435z M60.848,26.284
    c0,1.066-0.884,1.95-1.95,1.95h-5.07v-8.931h5.07c1.066,0,1.95,0.871,1.95,1.95V26.284z M60.848,13.453
    c0,1.079-0.884,1.95-1.95,1.95h-5.07V6.485h5.07c1.066,0,1.95,0.871,1.95,1.95V13.453z M106.959,2.585h-0.234
    c-1.495,0-2.86,0.559-3.9,1.508c-1.027-0.949-2.405-1.508-3.9-1.508h-0.221c-3.224,0-5.85,2.613-5.85,5.85v23.946h3.9V8.435
    c0-1.079,0.871-1.95,1.95-1.95h0.221c1.079,0,1.95,0.871,1.95,1.95v23.946h3.9V8.435c0-1.079,0.884-1.95,1.95-1.95h0.234
    c1.079,0,1.95,0.871,1.95,1.95v23.946h3.9V8.435C112.809,5.198,110.183,2.585,106.959,2.585z M139.836,13.7v12.584
    c0,1.079,0.871,1.95,1.95,1.95h0.234c0.247,0,0.494-0.052,0.715-0.143v4.004c-0.234,0.026-0.468,0.039-0.715,0.039h-0.234
    c-3.224,0-5.85-2.626-5.85-5.85V13.7h-2.99V9.8h2.99V2.325h3.9V9.8h2.99v3.9H139.836z M72.47,10.034v22.347h-3.9V10.034H72.47z
    M88.878,32.35H75.569l7.893-18.719h-6.697v-3.9H89.34L81.447,28.45h7.431V32.35z M131.529,28.234
    c-0.052-0.013-1.04-0.039-1.833-0.806V15.637c0-3.224-2.626-5.85-5.85-5.85h-6.89v3.9h6.89c1.079,0,1.95,0.884,1.95,1.95v0.572
    l-5.408,2.054c-0.208,0.091-4.927,2.158-4.927,7.124c0,5.044,2.977,6.838,5.746,6.838h3.25c0.442,0,0.897-0.052,1.339-0.169
    c0.767-0.195,1.508-0.546,2.145-1.053c0.585,0.39,1.196,0.65,1.755,0.819c0.715,0.221,1.378,0.299,1.859,0.312L131.529,28.234z
    M125.796,27.389l-0.039,0.182c-0.13,0.533-0.91,0.754-1.3,0.754h-3.25c-0.546,0-1.846,0-1.846-2.938
    c0-2.366,2.457-3.497,2.483-3.497l3.952-1.495V27.389z"/>
  </svg>
</template>
