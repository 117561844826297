"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingsApp = void 0;
const vue_1 = require("vue");
const chatSettingsImpl_1 = require("../controller/chatSettingsImpl");
const chatSettingsImpl_2 = require("../subject/chatSettingsImpl");
const chatSettings_1 = require("../webappApi/chatSettings");
const chatSettingImpl_1 = require("../observer/chatSettingImpl");
const chatSettingsPageImpl_1 = require("../presenter/chatSettingsPageImpl");
const provider_1 = require("../provider/provider");
class ChatSettingsApp {
    constructor(app, group, permissions) {
        const subject = new chatSettingsImpl_2.ChatSettingsSubjectImpl();
        const webAppApi = new chatSettings_1.ChatSettingsWebAppApiImpl();
        const controller = new chatSettingsImpl_1.ChatSettingsControllerImpl(subject, webAppApi);
        app.provide(provider_1.chatSettingsControllerInjectKey, controller);
        const viewModel = (0, vue_1.ref)({
            id: group.getOriginalId(),
            showUpgradeMessage: true,
            showSettings: false,
        });
        app.provide(provider_1.chatSettingsViewModelInjectKey, (0, vue_1.readonly)(viewModel));
        const obs = new chatSettingImpl_1.ChatSettingObserverImpl(subject);
        new chatSettingsPageImpl_1.ChatSettingsPagePresenterImpl(viewModel.value, obs, group, permissions);
    }
}
exports.ChatSettingsApp = ChatSettingsApp;
