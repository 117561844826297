<script>
import { parseOplusTimeRaw, parseRepeatShiftTemplateOplusTimeRaw } from "../legacy/parser/shiftParser.ts";
import { shiftName, shiftHasLinkPrev, shiftHasLinkNext } from '../view/shift/shift';
import { SLOT_TYPES } from '../js/models';
import GroupRoleIcon from './icons/GroupRoleIcon.vue';
import SlotBadges from "./components/SlotBadges.vue";
import { addDaysToDate, goDateString } from '../js/common';
import { isSlotTypeXOffDayString, isSlotGroupRole } from '../js/slot_helpers';
import { updateSelectedSlotsCount, findRoleById, findUserById } from '../js/summary_lib';
import { dateFromString } from "../common/common";
import timeZones from "../common/timeZones";
import { makeTableDate } from "../table/v1/table";
import periodTypes from "../table/v1/periodTypes";
import { repeatShiftsFromTemplate } from "../employmentShiftTable/employmentShiftTable";
import alertDescriptions from "../model/v1/slotAlerts";
import { MeStore } from "../store/v1/meStore";

export default {
    props: {
        slot: Object,
        branchSettings: Object,
        date: String,
        repeatDate: Date,
        meStore: MeStore,
        isCellDisabled: {
          type: Boolean,
          default: false,
        },
        // used in shift page, always false in summary page
        isUsedInShiftPage: {
          type: Boolean,
          default: false,
        }
    },
    inject: [
        'deleteMode',
        'cellEditMode',
        'slotLogMap',
        'useShiftBorders', // Browser setting for enabling dashed borders for unconfirmed shifts
    ],
    methods: {
        handleSlotClick() {
            if (this.isClone || this.cellEditMode.isOn) {
                return;
            }

            if (!this.isUsedInShiftPage && !this.meStore.getIsLeader()) {
              return;
            }

            if (this.deleteMode.isOn) {
                if (this.slot.Repeat) {
                    alertModal.alert("リピートシフトは削除できません。");
                    return;
                }
                this.toggleSelectedDelete();
                return;
            }

            let dateUnix = this.slot.DateUnix;
            if (this.isRepeat) {
                dateUnix = this.repeatDate / 1000;
            };

            if (this.isUsedInShiftPage) {
              openShiftSlotModal(dateUnix, this.slot.ID);
              return
            }
            openSlotModal(dateUnix, this.slot.UserID, this.slot.ID)
        },
        toggleSelectedDelete() {
            const selectedSlots = this.deleteMode.selectedSlots
            if (this.isSlotSelectedForDelete) {
                const slotIndex = selectedSlots.indexOf(this.slot.ID);
                selectedSlots.splice(slotIndex, 1);
            } else {
                selectedSlots.push(this.slot.ID)
            }
            updateSelectedSlotsCount();
        },
    },
    created() {

    },
    mounted() {

    },
    updated() {

    },
    beforeUnmount() {

    },
    computed: {
        computedSlot() {
          let slot = this.slot
          if (this.showRequestSlotOnly && this.slot.RequestedSlot) {
            slot = this.slot.RequestedSlot
          }
          return slot;
        },
        isSlotSelectedForDelete() {
            return this.deleteMode?.isOn && this.deleteMode?.selectedSlots?.includes(this.slot.ID);
        },
        computeSlotClass() {
            return {
                'slot-margin': !this.isUsedInShiftPage,
                'slots-cont': true,
                'draggable': !this.isRepeat && !this.companyName  && !this.cellEditMode.isOn && !this.isUsedInShiftPage,
            }
        },
        computeChipStyle() {
            let bg = this.computedSlot.Color ? `#${this.computedSlot.Color}` : '#B0ADAC40';
            if (this.isSlotSelectedForDelete) {
                bg = "#e85600"
            }
            return {
                'backgroundColor': bg,
                'cursor': this.isClone ? 'default' : 'pointer',
            };
        },
        lockStyle() {
            return {
                'color': this.isKOTSynced ? '#1E9038' : '',
            }
        },
        borderClass() {
            if (!this.useShiftBorders) {
                return {};
            }
            return {
                'slot-outline': !this.isLocked && (!this.slot.RequestedSlot || this.isClone),
                'requested-slot-outline': !this.isLocked && this.slot.RequestedSlot && !this.isClone,
            }
        },
        slotWrapperClass() {
            if (!this.useShiftBorders) {
                return {};
            }
            return {
                'slot-wrapper': !this.isLocked && this.slot.RequestedSlot && !this.isClone,
            }
        },
        chipClass() {
            return {
                clone: this.isClone || this.isCellDisabled,
                'text-light': this.computedSlot.Color || this.isSlotSelectedForDelete,
                'main-slot': this.hasRequestSlot && !this.isClone && !this.showRequestSlotOnly,
            }
        },
        slotNameClass() {
            return {
                'text-success': this.isTypeOne && !this.isSlotSelectedForDelete,
                'zmdi-hc-lg': this.isDefaultTextTypeTwo,
                'zmdi': this.isTypeTwo && !this.isRole,
                'text-error': this.isHolidayType && !this.isSlotSelectedForDelete,
            }
        },
        requestSlotNameClass() {
            return {
                'zmdi zmdi-hc-lg': this.isRequestDefaultTextTypeTwo
            }
        },
        isRequestSlotTypeTwo() {
            return this.slot?.RequestedSlot.Type === SLOT_TYPES.OFF_DAY;
        },
        isRequestDefaultTextTypeTwo() {
            return this.isRequestSlotTypeTwo && isSlotTypeXOffDayString(this.slot.RequestedSlot.Text);
        },
        isNumWorkerEnabled() {
            return !this.isClone && !this.isHolidayType;
        },
        isTransportCostEnabled() {
            return this.isNumWorkerEnabled && !this.linkPrev;
        },
        companyName() {
            return this.isClone ? this.computedSlot.CompanyName : "";
        },
        isHelp() {
            return this.computedSlot.IsHelp;
        },
        isAuto() {
            return this.computedSlot.IsAuto;
        },
        isKOTSynced() {
            return this.computedSlot.KOTSynced;
        },
        isTypeOne() {
            return this.computedSlot.Type === 1;
        },
        isDefaultTextTypeTwo() {
            return this.isTypeTwo && this.slotName === '×';
        },
        isTypeTwo() {
            return this.computedSlot.Type === 2;
        },
        isHolidayType() {
            return this.computedSlot.Type > 1;
        },
        isRepeat() {
            return this.computedSlot.Repeat;
        },
        numComments() {
            return this.computedSlot.NumComments > 0 ? this.computedSlot.NumComments : 0;
        },
        rejectedAsk() {
            return this.computedSlot.AskStatus === 3 && this.meStore.getIsLeader();
        },
        isAsked() {
            return this.computedSlot.AskStatus > 0 && !this.isLocked;
        },
        isClone() {
            return this.computedSlot.CompanyID != this.meStore.getActiveBranchId()
        },
        isLocked() {
            if (this.isRepeat) {
                return this.isRepeatLocked;
            }

            return this.computedSlot.Locked;
        },
        isRole() {
            if (this.computedSlot.RoleID) {
                return true
            }
            return false
        },
        isGroupRole() {
          if (!this.isRole) {
          return false
          }
          return isSlotGroupRole(this.computedSlot);
        },
        isRepeatLocked() {
          if (!this.isRepeat) {
              return false;
          }
          const repeatDateZeroed = new Date(this.repeatDate).setUTCHours(0, 0, 0, 0);
          const ind = this.computedSlot.RepeatLocked?.findIndex(d => new Date(d).setUTCHours(0, 0, 0 ,0) === repeatDateZeroed);
          if (ind >= 0) {
              return true;
          }

          return false;
        },
        hasRequestSlot() {
            return this.slot.RequestedSlotID && this.slot.RequestedSlot;
        },
        hasLinkPrev() {
          const userTz = timeZones.ASIA_TOKYO;
          const dateInTz = dateFromString(this.date, userTz);
          const shift = parseOplusTimeRaw(this.slot);
          let repeatDate = null;
          if (this.repeatDate) [
            repeatDate = new Date(this.repeatDate)
          ]
			    return shiftHasLinkPrev(shift, this.branchSettings, dateInTz, userTz, repeatDate);
        },
        hasLinkNext() {
          const userTz = timeZones.ASIA_TOKYO;
          const dateInTz = dateFromString(this.date, userTz);
          const shift = parseOplusTimeRaw(this.slot);
          let repeatDate = null;
          if (this.repeatDate) [
            repeatDate = new Date(this.repeatDate)
          ]
			    return shiftHasLinkNext(shift, this.branchSettings, dateInTz, userTz, repeatDate);
        },
        showAuto() {
          return !this.isLocked && this.isAuto;
        },
        slotName() {
          // No access to user display settings, so hardcode only supported tz for now.
          const userTz = timeZones.ASIA_TOKYO;
          const dateInTz = dateFromString(this.date, userTz);
          const tableDate = makeTableDate(dateInTz, periodTypes.DAY, userTz);
          let shift = parseOplusTimeRaw(this.computedSlot);
          if (this.isRepeat) {
            const repeatTemplate = parseRepeatShiftTemplateOplusTimeRaw(this.computedSlot, timeZones.ASIA_TOKYO);
        // +- a day on either side to catch overnight slots
            const shifts = repeatShiftsFromTemplate(addDaysToDate(tableDate.getDate(), -1), addDaysToDate(tableDate.getEnd(), 1), repeatTemplate, userTz);
            shift = shifts[0];
          }
          if (!shift) {
            return "";
          }
          return shiftName(shift, this.branchSettings, tableDate.getDate(), tableDate.getEnd(), userTz);
        },
        shouldShowAlert() {
          return this.meStore.getIsLeader() && this.slot.Alert && !this.rejectedAsk;
        },
        alertDescription() {
          return alertDescriptions[this.slot.Alert];
        },
        mountId() {
          return `${this.slot.ID}_${this.isRepeat ? goDateString(this.repeatDate) : this.date}`
        },
        fromUnix() {
          let fromUnix = this.slot.FromUnix;
          if (this.isRepeat) {
              fromUnix = this.repeatDate / 1000;
          }
          return fromUnix;
        },
        toUnix() {
          let toUnix = this.slot.ToUnix;
          if (this.isRepeat) {
              toUnix = this.fromUnix + (this.slot.ToUnix - this.slot.FromUnix);
          }
          return toUnix;
        },
        slotWage() {
          let wage = this.slotUserInfo.wage;
          if (this.slotRole && this.slotRole.Wage) {
              wage = this.slotRole.Wage;
          }
          return wage || 0;
        },
        slotUserInfo() {
          return findUserById(this.slot.UserID) || {};
        },
        slotRole() {
          return findRoleById(this.slot.RoleID);
        },
        numUnreadComments() {
            if (this.slotLogMap) {
                const slotLogs = this.slotLogMap[this.slot.ID];
                return slotLogs ? slotLogs.length : 0;
            }
            return 0;
        },
        showRequestSlotOnly() {
          return this.isCellDisabled && this.branchSettings.stackedRequest && !this.meStore.getIsLeader()
        }
    },
    components: {
        GroupRoleIcon,
        SlotBadges,
    }
}
</script>

<template>
<div
    :id="`vue-slot-${mountId}`"
    :class="[computeSlotClass, slotWrapperClass]"
    :data-slot-id="slot.ID"
    :data-from="fromUnix"
    :data-to="toUnix"
    :data-date="date"
    :data-user="slot.UserID"
    :data-type="slot.Type"
    :data-rest="slot.Rest"
    :data-label="slot.Label"
    :data-is-counted="!isHolidayType"
    :data-hide="slot.HideFrom || slot.HideTo"
    :data-hours="slot.TotalHours"
    :data-role-id="slot.RoleID"
    :data-is-clone="isClone"
>
    <!-- (for staff level perm) If cell is locked/closed and Request slot settings is ON, we only show slots submitted by staff -->
    <span v-if="slot.RequestedSlot && !isClone && !showRequestSlotOnly" class="vue-chip chip requested-slot" :class="borderClass" @click="handleSlotClick">
        <SlotBadges
            :numReadComments="numComments"
            :numUnreadComments="numUnreadComments"
            :isClone="isClone"
            :shouldShowAlert="shouldShowAlert || rejectedAsk"
            :alertDescription="shouldShowAlert ? alertDescription : '要請が承認されませんでした'"
            :isAsked="isAsked"
            :tooltipBottom="false"
        />

        <i v-if="slot.RequestedSlot.LinkPrev" class="icon icon-link"></i>
        <span class="text-ellipsis transparent" :class="requestSlotNameClass">
            {{ slot.RequestedSlot.Text }}
        </span>
        <i v-if="slot.RequestedSlot.LinkNext" class="icon icon-link"></i>
    </span>

    <span
        class="chip vue-chip slot"
        :style="computeChipStyle"
        :class="[chipClass, borderClass]"
        @click="handleSlotClick"
        :id="`slot-${slot.ID}`"
    >
        <SlotBadges
            v-if="!slot.RequestedSlot || isClone"
            :numReadComments="numComments"
            :isClone="isClone"
            :numUnreadComments="numUnreadComments"
            :shouldShowAlert="shouldShowAlert || rejectedAsk"
            :alertDescription="shouldShowAlert ? alertDescription : '要請が承認されませんでした'"
            :isAsked="isAsked"
            :tooltipBottom="false"
        />

        <GroupRoleIcon v-if="isGroupRole"/>
        <i v-if="isHelp" class="zmdi zmdi-assignment-return"></i>
        <small v-if="companyName">{{ companyName }}</small>
        <i v-if="hasLinkPrev" class="icon icon-link"></i>
        <i v-if="isLocked && !isKOTSynced && !useShiftBorders" class="icon icon-check"></i>
        <i v-if="isLocked && isKOTSynced" class="icon icon-check" :style="lockStyle"></i>
        <i v-if="showAuto" class="zmdi zmdi-flower"></i>
        <span v-if="isRepeat">∞</span>
        <span class="text-ellipsis" :class="slotNameClass">{{ slotName }}</span>
        <i v-if="hasLinkNext" class="icon icon-link"></i>
        <i v-if="isSlotSelectedForDelete" class="zmdi zmdi-delete"></i>
    </span>
</div>
</template>

<style scoped>

  .vue-chip > * {
    margin: auto 2px;
  }

  .clone {
    opacity: 0.5;
  }

  .chip {
    padding: 0 0.4rem;
    position: relative;
    overflow: visible;
    margin: 0;
  }

  .requested-slot {
    opacity: 1;
  }

  .transparent {
    opacity: 0.5;
  }

  .slot-wrapper {
    border-radius: 5px;
    outline: 1.5px dashed #646565;
    width: 94%;
    max-width: 200px;
  }

  .slot-outline {
    outline: 1.5px dashed #646565;
    border: none;
  }

  .requested-slot-outline {
    margin: 0;
    width: 100%;
    border: none;
  }

  .slots-cont {
    width: fit-content;
    max-width: 100%;
  }

  .vue-chip {
    width: 100%;
    max-width: 100%;
  }

  .slot-margin {
    margin: 0.35rem 0.2rem 0.1rem 0.15rem;
  }
</style>
