"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateGroup = void 0;
async function updateGroup(activeUserId, groupUpdatePayload) {
    const url = `/dashboard/${activeUserId}/group/${groupUpdatePayload.id}`;
    const data = new URLSearchParams();
    data.append("name", groupUpdatePayload.name);
    data.append("address", groupUpdatePayload.address);
    if (groupUpdatePayload.kotAccessToken) {
        data.append("kot-access-token", groupUpdatePayload.kotAccessToken);
    }
    if (groupUpdatePayload.ccBizCompanyId) {
        data.append("ccbizmate-id", groupUpdatePayload.ccBizCompanyId);
    }
    if (groupUpdatePayload.ccBizAPIPassword) {
        data.append("ccbizmate-api-password", groupUpdatePayload.ccBizAPIPassword);
    }
    if (groupUpdatePayload.smartHRSubdomain) {
        data.append("smarthr-subdomain", groupUpdatePayload.smartHRSubdomain);
    }
    if (groupUpdatePayload.minajinCompanyCode) {
        data.append("minajin-company-code", groupUpdatePayload.minajinCompanyCode);
    }
    if (groupUpdatePayload.selectedOem !== undefined) {
        data.append("kot-selected-oem", groupUpdatePayload.selectedOem.toString());
    }
    if (groupUpdatePayload.kotEnabled !== undefined) {
        data.append("kot-enabled", groupUpdatePayload.kotEnabled.toString());
    }
    try {
        const response = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: data.toString(),
        });
        const json = await response.json();
        if (!response.ok || !json.success) {
            throw new Error("Failed to update group");
        }
        return json;
    }
    catch (err) {
        throw err;
    }
}
exports.updateGroup = updateGroup;
