<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 28 20" height="60px" style="enable-background:new 0 0 28 20;" xml:space="preserve">
  <path fill="#23949C" d="M13.89,4.32c0.15,0.57-0.2,1.15-0.77,1.29c-0.57,0.15-1.15-0.2-1.29-0.77s0.2-1.15,0.77-1.29
  C13.17,3.4,13.75,3.75,13.89,4.32z"/>
  <path fill="#1B9EA4" d="M10.09,2.77c0.41,0.42,0.4,1.09-0.02,1.5c-0.42,0.41-1.09,0.4-1.5-0.02s-0.4-1.09,0.02-1.5
  S9.68,2.35,10.09,2.77z"/>
  <path fill="#17A6AA" d="M6.02,3.34c0.57,0.16,0.9,0.75,0.74,1.31s-0.75,0.9-1.31,0.74s-0.9-0.75-0.74-1.31S5.45,3.18,6.02,3.34z"/>
  <path fill="#21B2B5" d="M2.78,5.86c0.57-0.15,1.15,0.2,1.29,0.77c0.15,0.57-0.2,1.15-0.77,1.29c-0.57,0.15-1.15-0.2-1.29-0.77
  S2.21,6.01,2.78,5.86z"/>
  <path fill="#44BDBD" d="M1.24,9.67c0.42-0.41,1.09-0.4,1.5,0.02s0.4,1.09-0.02,1.5s-1.09,0.4-1.5-0.02S0.82,10.08,1.24,9.67z"/>
  <path fill="#44BDBD" d="M1.81,13.74c0.16-0.57,0.75-0.9,1.31-0.74c0.57,0.16,0.9,0.75,0.74,1.31c-0.16,0.57-0.75,0.9-1.31,0.74
  S1.65,14.3,1.81,13.74z"/>
  <path fill="#69C6C4" d="M4.33,16.98c-0.15-0.57,0.2-1.15,0.77-1.29s1.15,0.2,1.29,0.77c0.15,0.57-0.2,1.15-0.77,1.29
  C5.06,17.89,4.48,17.55,4.33,16.98z"/>
  <path fill="#91D1DD" d="M8.14,18.52c-0.41-0.42-0.4-1.09,0.02-1.5s1.09-0.4,1.5,0.02s0.4,1.09-0.02,1.5
  C9.22,18.95,8.55,18.94,8.14,18.52z"/>
  <path fill="#B2DFE2" d="M12.2,17.95c-0.57-0.16-0.9-0.75-0.74-1.31c0.16-0.57,0.75-0.9,1.31-0.74c0.57,0.16,0.9,0.75,0.74,1.31
  S12.77,18.11,12.2,17.95z"/>
  <path fill="#09498C" d="M10.05,9.57c-0.07,0.1-0.08,0.12-0.16,0.28c-0.33,0.7-0.74,1.19-1.41,1.69c-0.23-0.25-0.31-0.31-0.58-0.47
  c0.4-0.22,0.7-0.48,0.95-0.81c0.08-0.12,0.12-0.17,0.22-0.34C8.98,9.94,8.94,9.94,8.85,9.96c-0.58,0.07-1.18,0.14-1.78,0.23v1.3
  c0.01,0.4,0.01,0.4,0.02,0.5c0.04,0.23,0.23,0.29,0.81,0.29c0.66,0,1.29-0.08,1.77-0.23l0.03,0.78c-0.16,0.01-0.2,0.01-0.35,0.02
  c-0.62,0.07-1.05,0.1-1.43,0.1c-0.47,0-0.81-0.03-1.03-0.1c-0.24-0.07-0.43-0.28-0.5-0.53c-0.05-0.18-0.06-0.27-0.06-0.73v-1.32
  l-0.54,0.07c-0.45,0.06-0.45,0.06-0.64,0.11L5.09,9.73C5.16,9.74,5.2,9.74,5.23,9.74c0.1,0,0.35-0.02,0.51-0.04l0.61-0.07V8.83
  c0-0.29-0.01-0.35-0.03-0.55h0.8C7.08,8.45,7.08,8.57,7.08,8.82v0.73l2.1-0.25c0.31-0.03,0.31-0.04,0.44-0.1L10.05,9.57z
  M11.65,12.59c-0.4,0-0.54,0.01-0.71,0.02v-0.74c0.21,0.03,0.32,0.04,0.72,0.04h2.63V9.39h-2.53c-0.41,0-0.53,0.01-0.74,0.03V8.67
  c0.21,0.03,0.32,0.04,0.74,0.04h2.78c0.3,0,0.3,0,0.5-0.01c-0.02,0.16-0.02,0.21-0.02,0.48v3.17c0,0.29,0.01,0.45,0.03,0.59h-0.77
  v-0.35H11.65z M18.77,8.35c-0.06,0.13-0.07,0.14-0.15,0.42c-0.44,1.44-0.77,2.4-1.14,3.29c0.91-0.07,1.9-0.19,2.4-0.29
  c-0.06-0.14-0.13-0.27-0.27-0.53c-0.25-0.48-0.36-0.65-0.55-0.92l0.65-0.25c0.54,0.79,1.04,1.75,1.4,2.67l-0.7,0.36
  c-0.08-0.24-0.08-0.24-0.28-0.72c-0.08,0.02-0.08,0.02-0.21,0.05c-0.58,0.12-1.54,0.24-3.27,0.41c-0.32,0.03-0.41,0.04-0.53,0.07
  l-0.1-0.78c0.08,0,0.08,0,0.15,0c0.12,0,0.25-0.01,0.5-0.02c0.31-0.73,0.54-1.38,0.84-2.33c0.27-0.85,0.38-1.26,0.42-1.6
  L18.77,8.35z"/>
 </svg>
</template>
