<template>
<svg version="1.1" class="smartHR-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 122 22" style="enable-background:new 0 0 122 22;" xml:space="preserve">
  <path fill="#00C4CC" d="M15,13.5c-0.1,1-0.7,2-1.6,2.6c-0.7,0.5-1.6,0.8-2.5,0.8c-0.2,0-0.4,0-0.5,0c-1.7-0.2-3-1.4-3.4-3
  c-0.1-0.4,0.1-0.8,0.6-0.9C8,13,8.4,13.2,8.5,13.6c0.2,1,1.1,1.7,2.1,1.9c0.7,0.1,1.3-0.1,1.9-0.5c0.5-0.4,0.9-1,1-1.6
  c0.2-1.1-0.5-2.2-1.5-2.6c-0.4-0.2-0.6-0.6-0.4-1c0.2-0.4,0.6-0.5,1-0.4C14.3,10.1,15.3,11.8,15,13.5z M15,7.7
  c-0.6-1.5-2.1-2.5-3.8-2.5C8.9,5.2,7,7,7,9.2c0,1.8,1.2,3.4,3,3.8c0.1,0,0.1,0,0.2,0c0.3,0,0.6-0.2,0.7-0.5
  c0.1-0.4-0.1-0.8-0.5-0.9c-1.1-0.3-1.9-1.3-1.9-2.5c0-1.4,1.2-2.5,2.6-2.5c1.1,0,2,0.6,2.4,1.6c0.2,0.4,0.6,0.5,1,0.4
  C15,8.5,15.2,8.1,15,7.7z M21.5,7.3v7.3c0,3.5-3,6.3-6.7,6.3H7.1c-3.7,0-6.7-2.8-6.7-6.3V7.3c0-3.5,3-6.3,6.7-6.3h7.7
  C18.5,1,21.5,3.8,21.5,7.3z M19.9,7.3c0-2.7-2.3-4.9-5.1-4.9H7.1C4.3,2.4,2,4.6,2,7.3v7.3c0,2.7,2.3,4.9,5.1,4.9h7.7
  c2.8,0,5.1-2.2,5.1-4.9V7.3z M84,19.1h3.6v-6.4h5.5v6.4h3.6V3.8h-3.6v5.9h-5.5V3.8H84V19.1z M111.2,19.1h-4l-3.1-5.5h-1.9v5.5h-3.6
  V3.8h5.7c3.4,0,6.1,1.1,6.1,4.8c0,2.3-1.1,3.7-2.8,4.4L111.2,19.1z M102.3,10.8h1.8c1.9,0,2.9-0.8,2.9-2.3c0-1.5-1-2-2.9-2h-1.8
  V10.8z M30.5,19.4c3.8,0,6-2.3,6-4.9c0-2.2-1.2-3.5-3.1-4.3l-2-0.8c-1.4-0.6-2.4-0.9-2.4-1.9c0-0.9,0.8-1.4,2-1.4
  c1.2,0,2.2,0.4,3.3,1.2L36,5c-1.4-1.4-3.3-2.1-5.1-2.1c-3.3,0-5.6,2.1-5.6,4.7c0,2.3,1.6,3.6,3.2,4.3l2.1,0.9
  c1.4,0.6,2.3,0.9,2.3,1.9c0,0.9-0.7,1.5-2.2,1.5c-1.3,0-2.8-0.7-3.9-1.7l-2.1,2.5C26.3,18.6,28.5,19.4,30.5,19.4z M37.9,19.1h3.2
  v-7.6c0.7-0.7,1.3-1.1,1.9-1.1c0.9,0,1.4,0.5,1.4,2.1v6.6h3.2v-7.6c0.7-0.7,1.3-1.1,1.9-1.1c0.9,0,1.4,0.5,1.4,2.1v6.6h3.3v-7
  c0-2.8-1.1-4.6-3.4-4.6c-1.4,0-2.5,0.9-3.4,1.9c-0.6-1.2-1.5-1.9-3-1.9c-1.4,0-2.4,0.8-3.3,1.7h-0.1l-0.2-1.5h-2.7V19.1z
  M59.2,19.1c1.3,0,2.1-0.4,3.1-1.2h0.1l0,1.2h2.9v-6.5c0-3.5-1.6-5.1-4.4-5.1c-1.8,0-4.8,0.9-4.9,3.5l3,0c0.7-1,0.9-1.1,1.8-1.1
  c1.2,0,1.1,0.9,1.2,1.9c-4.4,0.5-6.3,1.2-6.3,3.6C55.6,17.3,57.1,19.1,59.2,19.1z M60.1,16.8c-0.8,0-1.3-0.4-1.3-1.1
  c0-0.9,0.8-1.5,3.1-1.9v2C61.4,16.4,60.9,16.8,60.1,16.8z M67.3,19.1h3.2v-6.5c0-1.7,0.9-2.3,1.7-2.3c0.5,0,1,0,1.5,0l0-2.7
  c-0.4,0-1,0-1.6,0c-1.1,0-1.4,0.5-1.7,1.1h-0.1l0-1l-3,0V19.1z M79.1,19c1.2,0.1,1.8,0.1,2.5,0l0-2.6c-0.3,0-0.5,0.1-0.9,0.1
  c-0.8,0-1.1-0.4-1.1-1.7v-4.5l2,0l0-2.7h-1.9V4.9l-3.2,0l0,2.6l-1.6,0l0,2.5h1.6l0,5.9C76.3,17.8,77.6,18.8,79.1,19z"/>
</svg>
</template>
