<template>
  <svg version="1.1" class="settings-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 18.698 19.533" width="20px" style="enable-background:new 0 0 18.698 19.533;" xml:space="preserve">
    <path fill="currentColor" d="M9.21,18.6c-4.81,0-8.73-3.92-8.73-8.73c0-4.81,3.92-8.73,8.73-8.73s8.73,3.92,8.73,8.73
    C17.94,14.69,14.02,18.6,9.21,18.6z M9.21,2.09c-4.29,0-7.78,3.49-7.78,7.78c0,4.29,3.49,7.78,7.78,7.78s7.78-3.49,7.78-7.78
    C16.99,5.58,13.5,2.09,9.21,2.09z M10.03,5.61c0,0.47-0.36,0.82-0.8,0.82c-0.46,0-0.82-0.35-0.82-0.82c0-0.46,0.36-0.8,0.82-0.8
    C9.67,4.81,10.03,5.16,10.03,5.61z M8.67,7.99c0-0.31,0.2-0.47,0.54-0.47c0.32,0,0.55,0.16,0.55,0.47v5.84
    c0,0.32-0.23,0.48-0.55,0.48c-0.34,0-0.54-0.16-0.54-0.48V7.99z"/>
  </svg>
</template>
