<template>
<svg version="1.1" class="-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 50 22" height="50px" style="enable-background:new 0 0 50 22;" xml:space="preserve">
  <path fill="#2864F0" d="M16.61,10.49c-0.02,0-2.55,0.39-4.43,0.39c-1.56,0-3.04-0.34-3.5-0.46c0.51-0.29,2.37-1.39,3.53-2.69
  c1.35-1.51,2.25-4.66,2.26-4.69c0-0.03,0-0.06-0.03-0.07c-0.02-0.01-0.05-0.01-0.07,0L5.43,9.64C5.28,9.62,4.45,9.55,3.69,9.8
  c-0.75,0.25-1.09,0.76-1.16,0.87l-0.79,0.21c-0.03,0.01-0.05,0.03-0.04,0.07c0,0.03,0.03,0.05,0.06,0.05
  c0.01,0,1.54,0.05,2.22,0.56c0.48,0.36,0.87,1.01,0.87,1.02c0,0.01,0,0.03,0.01,0.03l6.19,7.82c0.01,0.01,0.03,0.03,0.04,0.03
  c0.01,0,0.01,0,0.02,0c0.03,0,0.04-0.03,0.04-0.05c0-0.02,0.03-2.04-0.55-3.88c-0.47-1.51-1.47-2.55-1.84-2.89
  c0.28,0.01,0.81,0.03,1.33-0.09c0.59-0.13,0.91-0.3,0.99-0.34c0.77,0.01,4.82-0.05,5.1-0.33c0.03-0.03,0.03-0.05,0.03-0.07
  c0-0.19-0.91-0.19-2.04-0.36c-0.53-0.08-1.27-0.2-1.33-0.28c0-0.21,0.25-0.29,1.51-0.59c1.51-0.36,2.3-0.96,2.31-0.99
  c0.01-0.02,0.01-0.04,0-0.06C16.66,10.5,16.64,10.49,16.61,10.49z M8.5,13.07C6.48,13,5.06,11.61,5.05,11.6
  c-0.01-0.01-0.02-0.04-0.01-0.06c0.01-0.02,0.02-0.04,0.05-0.04c0.01,0,1.26-0.2,2.58-0.15c1.31,0.05,4.31,0.37,4.34,0.38
  c0.02,0,0.04,0.02,0.05,0.04c0,0.02,0,0.04-0.02,0.06c-0.01,0.01-1.42,1.25-3.35,1.25C8.62,13.08,8.56,13.08,8.5,13.07z M4.12,10.6
  c0-0.2,0.17-0.36,0.36-0.36c0.2,0,0.37,0.16,0.37,0.36s-0.17,0.36-0.37,0.36C4.29,10.96,4.12,10.8,4.12,10.6z M48.84,11.32
  c0,0-1.23,1.28-1.82,1.64c-0.72,0.44-1.5,0.38-1.77,0.19c-0.71-0.49-0.62-1.17-0.62-1.17s1.04,0.1,1.91-0.62
  c0.81-0.67,0.98-1.5,0.59-2.03c-0.4-0.55-1.87-0.62-2.73,0.22c-0.71,0.68-0.86,1.61-0.89,1.92c-0.35,0.35-1.2,1.2-1.67,1.49
  c-0.72,0.44-1.5,0.38-1.77,0.19c-0.71-0.49-0.62-1.17-0.62-1.17s1.04,0.1,1.91-0.62c0.81-0.67,0.98-1.5,0.59-2.03
  c-0.4-0.55-1.87-0.62-2.73,0.22c-0.88,0.84-0.9,2.04-0.9,2.04v0.08c-0.41,0.4-1.09,1.05-1.48,1.29c-0.72,0.44-1.5,0.38-1.77,0.19
  c-0.71-0.49-0.62-1.17-0.62-1.17s1.04,0.1,1.91-0.62c0.81-0.67,0.98-1.5,0.59-2.03c-0.4-0.55-1.87-0.62-2.73,0.22
  c-0.88,0.84-0.9,2.04-0.9,2.04h-1.4c0,0,0.07-1.22,0.01-1.6c-0.06-0.38-0.11-1.01-0.77-1.21c-0.65-0.2-1.16,0-1.59,0.46
  c-0.43,0.46-1.12,1.35-1.12,1.35s0.17-0.95-0.14-1.25c-0.53-0.52-1.7-0.54-2.54-0.28c-0.42,0.14-0.89,0.33-1.4,0.54
  c0.15-0.68,0.35-1.59,0.47-2.1c0.19-0.79,0.28-1.2,0.5-1.49c0.29-0.38,0.77-0.51,1.04-0.5c0.41,0.01,0.7,0.17,0.9,0.35
  c0.2,0.17,0.56,0.24,0.66,0.14c0.1-0.1,0.21-0.38-0.09-0.7c-0.25-0.26-0.62-0.47-1.33-0.6c-0.79-0.15-2.07,0.13-2.56,1.4
  c-0.34,0.86-0.69,2.86-0.88,4.04c-0.72,0.28-1.5,0.55-2.35,0.75c-1.42,0.33-3.67,0.57-3.72,0.86c-0.05,0.31,2.04-0.01,3.19-0.3
  c0.44-0.11,1.88-0.39,2.38-0.56c0.14-0.05,0.27-0.09,0.4-0.14c-0.03,0.2-0.05,0.31-0.05,0.31s-0.25,1.8-0.45,2.77
  c-0.19,0.92-0.71,2.81-1.21,3.23c-0.25,0.21-0.77,0.21-1.11,0.07c-0.73-0.3-0.69-0.83-0.89-0.71c-0.2,0.11-0.5,0.87-0.03,1.25
  c0.47,0.37,0.78,0.36,1.26,0.36c0.47,0,1.04-0.08,1.6-0.53c0.56-0.45,1.06-2.09,1.28-2.91c0.22-0.81,0.62-3.02,0.81-4.17
  c0.01-0.03,0.01-0.06,0.02-0.1v0c0.64-0.23,1.06-0.38,1.35-0.46c0.76-0.21,1.43-0.19,1.6,0.03c0.24,0.31,0.21,0.8,0.02,1.53
  c-0.2,0.76-0.49,2.66-0.49,2.66h1.43c0,0,0.14-1.82,0.6-2.58s1.17-1.67,1.6-1.67c0.43,0,0.31,0.43,0.31,0.55
  c0,0.12-0.1,1.22,0.05,1.38c0.14,0.17,0.24,0.38,0.55,0.46c0.31,0.07,2.1,0.02,2.1,0.02s0.22,1.1,0.74,1.43
  c0.53,0.33,1.33,0.57,2.05,0.38c1.15-0.31,1.61-1.01,2.25-1.63c0.09,0.33,0.31,1,0.7,1.25c0.53,0.33,1.33,0.57,2.05,0.38
  c1.16-0.31,1.62-1.03,2.27-1.65c0.05-0.05,0.09-0.08,0.13-0.12c0.05,0.21,0.27,1.1,0.74,1.39c0.53,0.33,1.33,0.57,2.05,0.38
  c1.16-0.31,1.62-1.03,2.27-1.65c0.64-0.62,0.64-0.79,0.64-0.79L48.84,11.32z M45.08,10.15c0.36-0.39,0.95-0.58,1.17-0.38
  c0.2,0.18-0.03,0.74-0.39,1.12c-0.37,0.38-1.22,0.5-1.22,0.5S44.72,10.54,45.08,10.15z M39.9,10.15c0.36-0.39,0.95-0.58,1.17-0.38
  c0.2,0.18-0.03,0.74-0.39,1.12c-0.37,0.38-1.22,0.5-1.22,0.5S39.54,10.54,39.9,10.15z M34.9,10.15c0.36-0.39,0.95-0.58,1.17-0.38
  c0.2,0.18-0.03,0.74-0.39,1.12c-0.37,0.38-1.22,0.5-1.22,0.5S34.54,10.54,34.9,10.15z"/>
 </svg>
</template>
