import { mountDateNavComponent } from './dateNav.js';
import { isZeroDate, timestampFromDate, dateFromSecondsTimestamp, createStartAndEndDatesFromArray, JPShortDateString } from './common.js'
import { isClone, isNonWorkingSlotType, isLocked, isCreatedByActiveUser } from './slot_helpers.js';
import { calcDiffCurrentAndPrevSlots } from './stats.js'
import { addRequestSlotsToSlotMap, mountShiftCalendarComponent, mountShiftViewToggleButtonComponent } from './shift_calendar.js'
import { reactive } from 'vue';
import { initSlotModalRoleListComponent, updateCurrentDate } from '../slotModal/roleList'
import { getDateStringsForSlot } from './summary_lib.js';
import timeZones from '../common/timeZones';
import { fromOplusTime, jpDateString, yearMonthDateString } from '../common/common';
import { parseFromShiftRaw as branchSettingsParser } from '../legacy/parser/branchSettingsParser'
import { initSlotLogMap, updateSlotUnreadCommentBadge, reactiveSlotLogMap } from './slotLog.js';
import Cell from '../vue/Cell.vue';
import { createApp, computed } from 'vue';
import { MeStore } from '../store/v1/meStore';
import { User } from '../model/v1/user';
import { getShiftBordersSetting } from './settings.js';

// nonWorkingSlotCount is an object which tracks how many non-working (type 2, 6, 7) type slots the user used.
const nonWorkingSlotCount = {
  count: 0,
  limit: 0,
}

const CALENDAR_MOUNT_ID = {
  CALENDAR: "#shift-calendar-mount",
  TOGGLE_BUTTON: "#calendar-toggle-button",
}

const TIME_PERIOD_TYPE = Object.freeze({
  MONTHLY: "month",
  WEEKlY: "week",
})

let lockedDateUntil = null;
let timePeriodType = "";

let branchSettings = null;
let meStore;

export const calendarReactiveObject = reactive({
  isCalendarView: isCalendarViewActive(),
  cellsMap: {},
})

export function handleInitSlotLogMap(newSlotLogMap) {
  initSlotLogMap(newSlotLogMap);
}

export function handleUpdateSlotUnreadCommentBadge(slotId, numComments = 0) {
  updateSlotUnreadCommentBadge(slotId, numComments);
}

function initMeStore(props) {
  const meStoreReq = {
    user: new User({id: props.activeUserID})
  }
  const meStoreOpt = {
    activeBranchId: props.activeCompanyID.toString(),
    isLeader: props.canEdit,
    timeZone: timeZones.ASIA_TOKYO,
  }
  return new MeStore(meStoreReq, meStoreOpt);
}

export function initPage(props) {
  meStore = initMeStore(props)
  branchSettings = branchSettingsParser(props.rawBranchSettings);

  lockedDateUntil = new Date(props.lockedDateUntil);
  timePeriodType = props.timePeriodType;

  addRequestSlotsToSlotMap(SlotMap);
  buildShiftCellsMap(props);
  const {startDate, endDate} = createStartAndEndDatesFromArray(props.dates);
  renderTableDateColumn(props.dates);
  mountDateNavComponent("#date-nav-mount", {
    activeCompanyID: meStore.getActiveBranchId(),
    startDate: startDate,
    endDate: endDate,
    timePeriodType: props.timePeriodType,
    currentPage: props.currentPage,
  });
  mountShiftViewToggleButtonComponent(CALENDAR_MOUNT_ID.TOGGLE_BUTTON, calendarReactiveObject);
  const calendarProps = {
    dates: props.dates,
    branchSettings: branchSettings,
    meStore: meStore,
  }
  mountShiftCalendarComponent(CALENDAR_MOUNT_ID.CALENDAR, calendarReactiveObject, SlotMap, calendarProps, props.roleDates)
  initSlotModalRoleListComponent(meStore.getActiveBranchId(), meStore.getActiveUserId(), true, props.roleDates);
  slot_crud.initSlotCrud(true, props.slotURL)
  // Count Off-slots if they are a Staff, Manager-level users will be defaulted to 0 limit (unlimited)
  if (!meStore.getIsLeader()) {
    initSlotUsedObj(props.monthlyVacationLimit);
  }
  toggleShiftView();

  renderVueCells(calendarReactiveObject.cellsMap, props.dates, branchSettings, SlotMap)
}

function renderVueCells(cellsMap, dates, branchSettings, slotMap) {
  dates.forEach(date => {
    const ds = date.String;
    const sel = `#shift-cell-mount-${ds}`;
    if (!$(sel).length) {
      return;
    }
    const props = {
      date: ds,
      userId: parseInt(meStore.getActiveUserId()),
      slotProps: cellsMap?.[ds]?.slots,
      branchSettings,
      slotMap,
      meStore,
      isStaffLocked: isLockedDateUntil(new Date(ds)),
      isUsedInShiftPage: true,
    };
    mountVueComponent(sel, props, Cell, cellsMap);
  })
}

function mountVueComponent(mountPoint, props, component, cellsMap) {
  const app = createApp(component, props);
  const useShiftBorders = getShiftBordersSetting();
  app.config.unwrapInjectedRef = true;
  app.provide('cellsMap', cellsMap);
  app.provide('cellEditMode', { isOn: false }); // not used in this page
  app.provide('deleteMode', { isOn: false }); // not used in this page
  app.provide('slotLogMap', computed(() => reactiveSlotLogMap));
  app.provide('useShiftBorders', useShiftBorders);

  window[mountPoint] = app.mount(mountPoint);
}

function renderTableDateColumn(dates) {
  dates.forEach((date) => {
    const dateText = JPShortDateString(dateFromSecondsTimestamp(date.TimeStamp), true);
    $(`.date-cell-label[data-timestamp=${date.TimeStamp}]`).text(dateText);
  })
}

function initSlotUsedObj(monthlyVacationLimit) {
  nonWorkingSlotCount.limit = monthlyVacationLimit;
  nonWorkingSlotCount.count = countOffTypeSlots();
}

function countOffTypeSlots() {
  let count = 0
  Object.keys(SlotMap).forEach(slotID => {
    const slot = SlotMap[slotID];
    if (!slot) {
      return;
    }
    if (isNonWorkingSlotType(slot) && !slot.SectionParent && !slot.IsTemplate && !slot.IsHelp && !isClone(slot, meStore.getActiveBranchId()) && !isLocked(slot) && isCreatedByActiveUser(slot, meStore.getActiveUserId())) {
      count++;
    }
  })
  return count;
}

// Getter for shift page
export function isVacationLimitMaxedOut() {
  const { count, limit } = nonWorkingSlotCount;
  return limit !== 0 && count >= limit;
}

// Setter function to update nonWorkingSlotCount object
export function calculateOffTypeSlotCount(slot, oldSlot = null) {
  // 0 means no limit
  if (nonWorkingSlotCount.limit === 0) {
    return;
  }

  const diffCount = calcDiffCurrentAndPrevSlots(slot, oldSlot);

  nonWorkingSlotCount.count += diffCount

  // Make sure the count doesn't go below 0
  nonWorkingSlotCount.count = Math.max(nonWorkingSlotCount.count, 0);
  isVacationLimitMaxedOut();
}

export function decrementOffTypeSlotCount(slot) {
  if (!isNonWorkingSlotType(slot)) {
    return;
  }

  return nonWorkingSlotCount.count--
}

export function isOffSlotBtnDisabled(slotCreatorUserID, isTemplate) {
  const commonConditions = !meStore.getIsLeader() && isVacationLimitMaxedOut() && !isTemplate;
  if (commonConditions) {
    if ((branchSettings.stackedRequest && (!slotCreatorUserID || slotCreatorUserID == meStore.getActiveUserId())) || !branchSettings.stackedRequest) {
      return true;
    }
  }
  return false;
}
// As of 2024-05-09, we don't support creating request slots on a repeat slot, this is to block staff users and enforce to create a new slot instead
export function isStaffMakingRequestSlotOnRepeat(isSlotRepeat) {
  return !meStore.getIsLeader() && branchSettings.stackedRequest && isSlotRepeat;
}

function buildShiftCellsMap(props) {
  const oplusTimeViewTo = new Date(props.dates[props.dates.length - 1].Time);

  props.dates.forEach(date => {
    const oplusDateObject = dateFromSecondsTimestamp(date.TimeStamp);
    const timestamp = oplusDateObject.valueOf();
    const dateStrKey = yearMonthDateString(fromOplusTime(oplusDateObject));
    const shiftTimeStart = addHourToTimestamp(timestamp, branchSettings.summaryDayStart);
    // force timeEnd to not go greater than 24
    const currentTimeEnd = branchSettings.summaryDayEnd > 24 ? 24 : branchSettings.summaryDayEnd;

    const shiftTimeEnd = addHourToTimestamp(timestamp, currentTimeEnd);
    calendarReactiveObject.cellsMap[dateStrKey] = {
      date: oplusDateObject,
      shiftTimeStart: shiftTimeStart,
      shiftTimeEnd: shiftTimeEnd,
      isLocked: isLockedDateFrom(new Date(date.Time)),
      isStaffLocked: isLockedDateUntil(new Date(date.Time)),
      isClosed: isClosed(new Date(date.Time)),
      slots: {},
    }
  })
  getSlotByDate(oplusTimeViewTo);
}

function getSlotByDate(oplusTimeViewTo) {
   Object.keys(SlotMap).forEach(slotID => {
    const slot = SlotMap[slotID];
    if (!slot) {
      return;
    }
    if (slot.IsTemplate) {
      return;
    }
    else if (slot.Repeat) {
      return addRepeatSlotsToCellsMap(slot, oplusTimeViewTo);
    }
    else {
      return addSlotToShiftCellsMap(slot);
    }
  })
}

function isLockedDateUntil(date) {
  if (!branchSettings.staffLock) {
    return false;
  }
  return !isZeroDate(lockedDateUntil) && lockedDateUntil >= date;
}

function isLockedDateFrom(date) {
  let out = false;
  if (branchSettings.shiftLockDate && !isZeroDate(new Date(branchSettings.shiftLockDate))) {
    const shiftLockDate = new Date(branchSettings.shiftLockDate);

    out = shiftLockDate <= new Date(date);
  }
  return out;
}

function isClosed(date) {
  return branchSettings.closedWeekdays?.[date.getUTCDay()];
}

function addRepeatSlotsToCellsMap(slot, viewTo) {
  const repeatUntil = new Date(slot.RepeatUntil);
  viewTo.setUTCHours(23, 59, 59, 999);
  const curFrom = new Date(slot.From);
  curFrom.setUTCHours(curFrom.getUTCHours(), curFrom.getUTCMinutes(), curFrom.getUTCSeconds(), curFrom.getUTCMilliseconds());
  const repeatExcludeMap = {};

  slot.RepeatExclude?.forEach(d => {
    repeatExcludeMap[timestampFromDate(d)] = true;
  });

  do {
    const d = timestampFromDate(curFrom);
    if (!repeatExcludeMap[d]) {
      // if not locked only, just add
      addSlotToShiftCellsMap(slot, d);
    }
    curFrom.setUTCDate(curFrom.getUTCDate() + 7)
  } while (curFrom <= viewTo && curFrom <= repeatUntil)
}


function addSlotToShiftCellsMap(slot, repeatDate) {
  if (slot.ManagedSlotKey) {
    return;
  }
  const repDate = repeatDate ? new Date(repeatDate) : null;
  const dateStrKeys = getDateStringsForSlot(slot, meStore.getTimeZone(), repDate);

  if (!branchSettings.splitNightShift && dateStrKeys.length > 1) {
    dateStrKeys.pop();
  }
  dateStrKeys.forEach(dateStr => {
    if (!calendarReactiveObject.cellsMap[dateStr]) {
      calendarReactiveObject.cellsMap[dateStr] = { slots: {} }
    }


    calendarReactiveObject.cellsMap[dateStr].slots[slot.ID] = {
      sections: {}
    };

    if (slot.SectionSlots) {
      slot.SectionSlots.forEach(s => {
        calendarReactiveObject.cellsMap[dateStr].slots[slot.ID].sections[s.ID] = {}
      })
    }
  })
}

function renderSlotInShiftTable(cellDateStr) {
  const cellApp = window[`#shift-cell-mount-${cellDateStr}`];
  if (!cellApp) {
    return;
  }
  cellApp.updateSlots(calendarReactiveObject.cellsMap[cellDateStr].slots);
}

export function addAndRenderSlotInTable(slot, oldSlot = null) {
  const dateStrKeys = getDateStringsForSlot(slot, meStore.getTimeZone());
  let oldDateStrKeys = [];

  if (oldSlot) {
    removeSlotFromShiftCellsMap(oldSlot)
    oldDateStrKeys = getDateStringsForSlot(oldSlot, meStore.getTimeZone());
  }
  const allKeys = [...new Set([...dateStrKeys, ...oldDateStrKeys])] // remove dups
  addSlotToShiftCellsMap(slot);
  allKeys.forEach(dateStr => {
    renderSlotInShiftTable(dateStr)
  })
}


export function removeSlotFromShiftCellsMap(slot) {
  const dateStrKeys = getDateStringsForSlot(slot, meStore.getTimeZone())
  dateStrKeys.forEach(dateStr => {
    delete calendarReactiveObject.cellsMap[dateStr].slots[slot.ID];
  })
}

export function removeSlotAndRerender(slot) {
  const dateStr = getDateStringsForSlot(slot, meStore.getTimeZone());
  dateStr.forEach(dStr => {
    removeSlotFromShiftCellsMap(slot);
    renderSlotInShiftTable(dStr);
  })
}

// Shift table slot render function ** END **

export function makeDateFromDateUnix(dateUnix) {
  const date = dateFromSecondsTimestamp(dateUnix);

  return yearMonthDateString(date);
}

export function makeDateLabel(dateUnix) {
  const date = dateFromSecondsTimestamp(dateUnix);

  return jpDateString(date, meStore.getTimeZone(), true)
}

function toggleShiftView() {
  if (calendarReactiveObject.isCalendarView) {
    $("#shift-table").addClass("d-none");
    $("#shift-calendar-mount").removeClass("hidden");
  } else {
    calendarReactiveObject.isCalendarView = false;
    $("#shift-table").removeClass("d-none");
    // this is to make the mounted component's height become 0
    // we can't use display: none here since table and calendar shares a common data, which is need for reactivity in vue
    $("#shift-calendar-mount").addClass("hidden");
  }
}

export function isMonthlyView() {
  return timePeriodType === TIME_PERIOD_TYPE.MONTHLY;
}

export function setShiftViewLocalStorage() {
  if (calendarReactiveObject.isCalendarView) {
    window.localStorage.removeItem("isShiftTableActive");
  } else {
    window.localStorage.setItem("isShiftTableActive", true);
  }
  toggleShiftView();
}

function isCalendarViewActive() {
  return window.localStorage.getItem("isShiftTableActive") === null;
}

function convertHourDurationToMilliseconds(hour) {
  return hour * 3600000
}

function addHourToTimestamp(ts, hour) {
  return convertHourDurationToMilliseconds(hour) + ts;
}

export function filterSlotsForCalendarCell(cellSlots, isCellDisabled, slotMap) {
  const slotIds = Object.keys(cellSlots);
  const isStackSlotModeOn = branchSettings.stackedRequest;
  const canEdit = meStore.getIsLeader();
  const userID = meStore.getActiveUserId();
  if (!canEdit && isCellDisabled) {
    // Staff-level permission filtering
    // If stackSlots settings is off and cell is locked, staff will not be shown any slots (even the one they created);
    if (!isStackSlotModeOn) {
      return [];
    }
    // Otherwise just show the one they created
    return slotIds.filter(slotId => {
      const slot = slotMap?.[slotId];
      // Slots with ManagedSlotKey indicates they are Requested slots (slot created by non-manager level)
      if (!slot || slot?.ManagedSlotKey) {
        return false
      }
      // Slots with RequestedSlotKey are slots that is created by manager in response to staff's slot (Requested by Manager)
      if (slot.RequestedSlotKey && slot.RequestedSlotID !== null || isCreatedByActiveUser(slot, userID)) {
        return true
      }

      return false;
    }).map(slotId => {
      const slot = slotMap?.[slotId];
      return slot.RequestedSlotKey ? slot.RequestedSlotID : slotId;
    });
  }

  // General case for editable accounts, (don't include requested slots)
  return slotIds.filter(slotId => {
    const slot = slotMap?.[slotId];
    if (!slot) {
      return false;
    }
    return !slot.ManagedSlotKey;
  });
}

export const updateRoleListDate = updateCurrentDate;
