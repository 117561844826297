"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _hoisted_1 = { class: "tab dashboard-tab" };
const _hoisted_2 = ["onClick"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'DashboardContentTab',
    props: {
        tabItemTexts: {
            type: Array,
            required: true,
        },
        activeTabIndex: {
            type: Number,
            default: 0,
            required: true,
        }
    },
    emits: ["emit-tab-index"],
    setup(__props, { emit: __emit }) {
        const props = __props;
        const emit = __emit;
        const activeClass = (index) => {
            return index === props.activeTabIndex ? "active" : "";
        };
        function handleTabClick(index) {
            emit("emit-tab-index", index);
        }
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("ul", _hoisted_1, [
                ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(__props.tabItemTexts, (text, index) => {
                    return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("li", {
                        key: `${index}-${text}`,
                        class: "tab-item",
                        onClick: ($event) => (handleTabClick(index))
                    }, [
                        (0, vue_2.createElementVNode)("a", {
                            class: (0, vue_2.normalizeClass)(activeClass(index))
                        }, (0, vue_2.toDisplayString)(text), 3 /* TEXT, CLASS */)
                    ], 8 /* PROPS */, _hoisted_2));
                }), 128 /* KEYED_FRAGMENT */))
            ]));
        };
    }
});
