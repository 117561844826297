"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Group = void 0;
const groupSettings_1 = require("./groupSettings");
class Group {
    id;
    name = "";
    address = "";
    groupSettings = new groupSettings_1.GroupSettings();
    constructor(req, opt) {
        this.id = req.id;
        this.name = req.name;
        this.address = req.address;
        if (opt?.groupSettings) {
            this.groupSettings = opt.groupSettings;
        }
    }
    getOriginalId() {
        return this.id;
    }
    getId() {
        return this.id.toString();
    }
    getGroupName() {
        return this.name;
    }
    getGroupAddress() {
        return this.address;
    }
    getGroupSettings() {
        return this.groupSettings;
    }
}
exports.Group = Group;
