"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChatSettingsPagePresenterImpl = void 0;
const chatSettings_1 = require("../subject/chatSettings");
class ChatSettingsPagePresenterImpl {
    settingsObserver;
    groupId;
    viewModel;
    constructor(viewModel, obs, group, permissions) {
        this.settingsObserver = obs;
        this.groupId = group.getOriginalId();
        this.viewModel = viewModel;
        this.viewModel.showUpgradeMessage = !permissions.hasChat;
        this.viewModel.settings = obs.getState().find(s => s.id === group.getOriginalId());
        this.settingsObserver.getEventListener().addEventListener(chatSettings_1.ChatSettingsEventTypes.ADD, (e) => this.handleSettingsAdd(e.detail.value));
        this.settingsObserver.getEventListener().addEventListener(chatSettings_1.ChatSettingsEventTypes.UPDATE, (e) => this.handleSettingsUpdate(e.detail.value));
    }
    settingsViewFromModel(settings) {
        return {
            blockStaffRoomCreation: settings.blockStaffRoomCreation,
        };
    }
    handleSettingsAdd(settings) {
        if (settings.id !== this.groupId) {
            return;
        }
        this.viewModel.settings = this.settingsViewFromModel(settings);
        this.viewModel.showSettings = true;
    }
    handleSettingsUpdate(settings) {
        if (settings.id !== this.groupId) {
            return;
        }
        this.viewModel.settings = this.settingsViewFromModel(settings);
    }
}
exports.ChatSettingsPagePresenterImpl = ChatSettingsPagePresenterImpl;
